<app-header></app-header>

<div class="containers">
  <div class="header-container">

    <div class="header">

      <h1 class="main-heading">{{user_name}}</h1>
      <h5 class="tag">{{user_email}}</h5>


    </div> <!-- END header -->
  </div>

  <div class="overlay-header"></div>

  <div class="body" *ngFor="let i of image" >
    <img [src]="i.images" alt="Avatar" class="body-image" />
    <input
      class="file-input"
      type="file"
      name="file"
      #fileInput
      (change)="onChange(fileInput.files[0])"
    />
    <div class="field" *ngIf="!infoMessage">
      <div class="control">
        <progress
          class="progress is-primary"
          [attr.value]="progress"
          max="100"
        ></progress>
      </div>
    </div>



    <div class="u-clearfix"></div>

    <i class="fa fa-upload"
      (click)="onUpload()"
      [attr.disabled]="isUploading ? '' : null">
      Upload
    </i>
    <div class="card u-clearfix">


      <ul class="card-list">
        <li routerLink="/myorders">My Orders</li>
<!--        <li>Change Password</li>-->
        <li (click)="logout()">Logout</li>
        <li routerLink="/change-password">Change Password</li>
      </ul>
    </div>
  </div>
</div>
<app-footer></app-footer>
