
<!DOCTYPE HTML>
<html>
<head>
  <title>DjPashupati:Admin Panel</title>
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  <meta name="keywords" content="DjPashupati Online shopping" />
  <script type="application/x-javascript"> addEventListener("load", function() { setTimeout(hideURLbar, 0); }, false); function hideURLbar(){ window.scrollTo(0,1); } </script>

  <link href='//fonts.googleapis.com/css?family=Carrois+Gothic' rel='stylesheet' type='text/css'>
  <link href='//fonts.googleapis.com/css?family=Work+Sans:400,500,600' rel='stylesheet' type='text/css'>

  <script src="//cdn.jsdelivr.net/modernizr/2.8.3/modernizr.min.js" type="text/javascript"></script>
  <script>window.modernizr || document.write('<script src="lib/modernizr/modernizr-custom.js"><\/script>')</script>


</head>
<body>
<div class="page-container">
  <div class="left-content">
    <div class="mother-grid-inner">
      <!--header start here-->
      <app-admin-header></app-admin-header>

      <!--heder end here-->
      <!-- script-for sticky-nav -->
      <script>
		$(document).ready(function() {
			 var navoffeset=$(".header-main").offset().top;
			 $(window).scroll(function(){
				var scrollpos=$(window).scrollTop();
				if(scrollpos >=navoffeset){
					$(".header-main").addClass("fixed");
				}else{
					$(".header-main").removeClass("fixed");
				}
			 });

		});
		</script>
      <!-- /script-for sticky-nav -->
      <!--inner block start here-->
      <div class="inner-block">
        <!--market updates updates-->


        <!--market updates end here-->
        <!--mainpage chit-chating-->

        <!--main page chit chating end here-->
        <!--main page chart start here-->
        <div class="main-page-charts">
          <div class="main-page-chart-layer1">
            <div class="col-md-5 chit-chat-layer1-left">
              <div class="work-progres">
                <div class="chit-chat-heading">
                  {{'admin.add-new-category'|translate}}
                </div>
                <div class="container">
                  <form [formGroup]="form"
                        name="form"
                        (ngSubmit)="onSubmit()" id="contact" action="" method="post">

                    <fieldset>
                      <input type="text" name="txtPhone" class="form-control" formControlName="category_name" placeholder="Category Name *"  autocomplete  [ngClass]="{ 'is-invalid': submitted && f.category_name.errors }" />
                      <div *ngIf="submitted && f.category_name.errors" class="invalid-feedback">
                        <div *ngIf="f.category_name.errors.required">Category Name is required</div>
                      </div>
                    </fieldset>

                    <fieldset>

                      <input placeholder="Choose Avatar" type="file" name="image_url" (change)="onFileChanged($event)" >

                    </fieldset>
                    <fieldset>
                      <button name="submit" type="submit" id="contact-submit" data-submit="...Sending">Submit</button>
                    </fieldset>
                  </form>


                </div>
              </div>
            </div>





            <div class="col-md-7 chit-chat-layer1-left">
              <div class="work-progres">
                <div class="chit-chat-heading">
                  {{'admin.categories'|translate}}
                </div>
                <input type="search"
                       class="form-control"
                       placeholder="Search Vendors.."
                       [(ngModel)]="cat">
                <div class="table-responsive">
                  <table class="table table-hover">
                    <thead>
                    <tr>
                      <th>{{'admin.sn'|translate}}</th>
                      <th>{{'admin.category-id'|translate}}</th>
                      <th>{{'admin.category-name'|translate}}</th>
                      <th>{{'admin.action'|translate}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let category of categories|filter:cat|paginate: { itemsPerPage: 10, currentPage: p }; let i = index">
                      <td>{{i+1}}</td>
                      <td>{{category.Category_id}}</td>
                      <td>{{category.Category_name}}</td>
                      <td> <span class="label label-danger" (click)="delete(category.Category_id)">Delete</span>
                      </td>
                      <td> <span class="label label-primary" (click)="edit(category.Category_id)">Edit</span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <pagination-controls (pageChange)="p = $event" class="row m-auto justify-content-center"></pagination-controls>

                </div>
              </div>
            </div>
          </div>
          <div class="clearfix"> </div>

        </div>

      </div>
      <!--inner block end here-->
      <!--copy rights start here-->
      <div class="copyrights">
        <p>© 2020 Lmaniya LLC. All Rights Reserved | <a href="http://lmaniya.org/" target="_blank">Visit</a> </p>
      </div>
      <!--COPY rights end here-->
    </div>
  </div>
  <!--slider menu-->

  <div class="sidebar-menu">
    <div class="logo"> <div  class="sidebar-icon"> <span class="fa fa-bars"></span> </div> <a > <span id="logo" ></span>
      <!--<img id="logo" src="" alt="Logo"/>-->
    </a> </div>
    <div class="menu">
      <ul id="menu" >
        <li id="menu-home" ><a routerLink="/admin/home"><i class="fa fa-tachometer"></i><span>{{'admin.dashboard'|translate}}</span></a></li>
        <li><a><i class="fa fa-book"></i><span>{{'admin.products'|translate}}</span><span class="fa fa-angle-right" style="float: right"></span></a>
          <ul>
            <li><a routerLink="/admin/home/create-category">{{'admin.add-new-category'|translate}}</a></li>
            <li><a routerLink="/admin/home/create-sub-category">{{'admin.add-new-sub-category'|translate}}</a></li>
            <li><a routerLink="/admin/home/create-product">{{'admin.add-new-product'|translate}}</a></li>

          </ul>
        </li>

        <li id="menu-comunicacao" ><a ><i class="fa fa-users nav_icon"></i><span>{{'admin.vendor'|translate}}</span><span class="fa fa-angle-right" style="float: right"></span></a>
          <ul id="menu-comunicacao-sub" >
            <!--          <li id="menu-mensagens" style="width: 120px" ><a href="buttons.html">Vendor List</a>-->
            <!--          </li>-->
            <li id="menu-arquivos" ><a routerLink="/admin/home/create-vendor">{{'admin.add-vendor'|translate}}</a></li>
          </ul>
        </li>


        <!--      <li id="menu-academico" ><a ><i class="fa fa-user"></i><span>Users</span><span class="fa fa-angle-right" style="float: right"></span></a>-->
        <!--        <ul  >-->
        <!--          <li ><a href="login.html">Manage Users</a></li>-->
        <!--        </ul>-->
        <!--      </li>-->

        <!--      <li><a ><i class="fa fa-envelope"></i><span>Orders</span><span class="fa fa-angle-right" style="float: right"></span></a>-->
        <!--        <ul >-->
        <!--          <li ><a href="inbox.html">New Order Requests</a></li>-->
        <!--          <li ><a href="inbox-details.html">Order List</a></li>-->
        <!--        </ul>-->
        <!--      </li>-->

        <li><a><i class="fa fa-cog"></i><span>{{'admin.system'|translate}}</span><span class="fa fa-angle-right" style="float: right"></span></a>
          <ul>
            <li><a (click)="logout()">{{'admin.logout'|translate}}</a></li>
            <li><a routerLink="/admin/home/change-password">{{'admin.change-password'|translate}}</a></li>
            <li><a routerLink="/admin/home/terms-conditions">{{'admin.terms-and-conditions'|translate}}</a></li>
            <li data-toggle="modal" data-target="#modalLoginForm"><a >{{'admin.about-us'|translate}}</a></li>
          </ul>
        </li>
        <li routerLink="admin/business/total"><a><i class="fa fa-cog"></i><span>Total Business</span></a></li>

        <!--      <li><a ><i class="fa fa-shopping-cart"></i><span>E-Commerce</span><span class="fa fa-angle-right" style="float: right"></span></a>-->
        <!--        <ul id="menu-academico-sub" >-->
        <!--          <li id="menu-academico-avaliacoes" ><a href="product.html">Product</a></li>-->
        <!--          <li id="menu-academico-boletim" ><a href="price.html">Price</a></li>-->
        <!--        </ul>-->
        <!--      </li>-->
      </ul>
    </div>
  </div>


  <div class="clearfix"> </div>
</div>

<!-- mother grid end here-->
</body>
</html>
