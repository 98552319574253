
  <link href='//fonts.googleapis.com/css?family=Carrois+Gothic' rel='stylesheet' type='text/css'>
  <link href='//fonts.googleapis.com/css?family=Work+Sans:400,500,600' rel='stylesheet' type='text/css'>
  <!--static chart-->
  <!--  <script src="js/Chart.min.js"></script>-->
  <!--//charts-->
  <!-- geo chart -->
  <script src="//cdn.jsdelivr.net/modernizr/2.8.3/modernizr.min.js" type="text/javascript"></script>
<app-header></app-header>
<div class="page-container">
  <div class="left-content">
    <div class="mother-grid-inner">

      <div >

        <div class="chit-chat-layer1">
          <div class="col-md-8 col-sm-8  chit-chat-layer1-left">

            <div class="work-progres">
              <div class="chit-chat-heading" >
                My Orders
              </div>
              <div class="table-responsive" >
                <table class="table table-hover">
                  <thead>
                  <tr>
                    <th>SN</th>
                    <th>Product Name</th>
                    <th>Product Quantity</th>
                    <th>Product Price</th>
                    <th>Payment Status</th>
                    <th>Order Status</th>
                    <th>Action</th>
                  </tr>
                  </thead>


                  <tbody>
                  <tr *ngFor="let order of orders|paginate: { itemsPerPage: 10, currentPage: p }; let i = index">
                    <td>{{i+1}}</td>
                    <td>{{order.Product_name}}</td>
                    <td>{{order.Product_quantity}}</td>
                    <td>{{order.Product_price}}</td>
                    <td>{{order.Payment_status}}</td>
                    <td>{{order.Order_status}}</td>
                    <td><span class="label label-danger" (click)="deleteO(order.Order_id)">Delete</span>
                    <td><span class="label label-danger" (click)="cancelOrder(order.Bill_number)">Cancel</span>
                    </td>
                  </tr>

                  </tbody>
                </table>
                <pagination-controls (pageChange)="p = $event" class="row m-auto justify-content-center"></pagination-controls>

              </div>
            </div>



          </div>



          <div class="clearfix"> </div>
        </div>

    </div>
  </div>
  <!--slider menu-->
  </div>
  <div class="clearfix"> </div>
</div>
<!-- mother grid end here-->

<app-footer></app-footer>
