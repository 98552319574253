import { Component, OnInit } from '@angular/core';
import {ActivatedRoute } from '@angular/router';
import {ProductService} from 'src/app/services/product.service';
import {Product} from 'src/app/common/product';
import {ProductCategory} from 'src/app/common/product-category';
import {ProductSubCategory} from 'src/app/common/product-sub-category';
import { FormBuilder, FormGroup,FormControl,Validators } from "@angular/forms";
import { HttpClient } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
@Component({
  selector: 'app-create-product',
  templateUrl: './create-product.component.html',
  styleUrls: ['./create-product.component.scss']
})
export class CreateProductComponent implements OnInit {
products: Product[];
categories: ProductCategory[];
subCategories: ProductSubCategory[];
form: FormGroup;
devices:any=[];
product:Product;
pr:string;

error:any=[];
p:number=1;
submitted=false;
loader=false;
  constructor(private _productService: ProductService,
                private _activatedRoute:ActivatedRoute,
                public fb:FormBuilder,
                private http:HttpClient,private router :Router,
                ) {




                }

                    get f() { return this.form.controls; }


  ngOnInit() {

    this._activatedRoute.paramMap.subscribe(params=>{
    const categoryId=+params.get('id');
    if(categoryId){
    this.getSubCat(categoryId);
    }
    });

         this.form=this.fb.group({
                  product_name:['',Validators.required],
                  product_description:['',Validators.required],
                  initial_price:['',Validators.required],
                  final_price:['',Validators.required],
                  category_id:[''],
                  sub_category_id:[''],
                  product_image_url:[''],
                  unit:['',Validators.required],
                  brand:['',Validators.required]

                  });

this.getDeviceId();
var toggle = true;
  let q= localStorage.getItem("isAdminLoggedin");
  if(q!='true'){
  this.router.navigateByUrl('/admin/index');
  }
$(".sidebar-icon").click(function() {
  if (toggle)
  {
    $(".page-container").addClass("sidebar-collapsed").removeClass("sidebar-collapsed-back");
    $("#menu span").css({"position":"absolute"});
  }
  else
  {
    $(".page-container").removeClass("sidebar-collapsed").addClass("sidebar-collapsed-back");
    setTimeout(function() {
      $("#menu span").css({"position":"relative"});
    }, 400);
  }
                toggle = !toggle;
            });


// list the products available in table
  this.listProducts();
this.listCategory();
this.listSubCategory();
  }

  listProducts(){
  this._productService.getProductAll().subscribe(data =>{this.products=data;
//   console.log(this.products);
//   console.log(data)
  },
  error=>{
  console.error(error);
  }
  );

  }


 listCategory(){
     this._productService.getCategoryAll().subscribe(data =>{this.categories=data;
//      console.log(this.categories);
     },
     error=>{
//      console.error(error);
     }
     );

     }



    listSubCategory(){
      this._productService.getSubCategoryAll().subscribe(data =>{this.subCategories=data;
//       console.log(this.subCategories);
      },
      error=>{
//       console.error(error);
      }
      );

      }


  onFileChanged(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form.get('product_image_url').setValue(file);
    }
  }

getSubCat(id: number){
this._productService.getProduct(id).subscribe(
(pro: Product)=>{this.editProduct(pro);
this.product=pro;
},
(err:any)=>console.log(err)
);

  }


editProduct(pro: Product){
console.log(pro);
this.listProducts();
this.listCategory();
this.listSubCategory();
this.form.patchValue({
product_name: pro[0].Product_name,
product_description:pro[0].Description,
      initial_price:pro[0].Initial_Price,
        final_price:pro[0].Final_price,
               unit:pro[0].Wt_unit,
              brand:pro[0].Brand_name,
});

}

MapFormValueToCategoryModel(){
this.product.Product_name=this.form.value.product_name;
this.product.Description=this.form.value.product_description;
this.product.Initial_price=this.form.value.initial_price;
this.product.Final_price=this.form.value.final_price;
this.product.Wt_unit=this.form.value.unit;
this.product.Brand=this.form.value.brand;
}




  onSubmit(){
   this.submitted = true;
   if (this.form.invalid) {
              return;
          }else{



          this._activatedRoute.paramMap.subscribe(params=>{
                             const categoryId=+params.get('id');
                                if(categoryId){
                                    this.MapFormValueToCategoryModel();
                                        if(this.product[0].Product_id){
                                            var formData:any=new FormData();
                                                   formData.append("Product_name",this.form.get('product_name').value);
                                                   formData.append("Description",this.form.get('product_description').value);
                                                   formData.append("Initial_price",this.form.get('initial_price').value);
                                                   formData.append("Final_price",this.form.get('final_price').value);
                                                    formData.append("Image",this.form.get('product_image_url').value);
                                                   formData.append("Category_id",this.product[0].Category_id);
                                                   formData.append("Subcategory_id",this.product[0].Subcategory_id);
                                                   formData.append("Wt_unit",this.form.get('unit').value);
                                                   formData.append("Product_id",this.product[0].Product_id);
                                                   formData.append("Brand",this.form.get('brand').value);
//                                                       console.log(...formData);
                                                      this.http.post<any>('http://djpashupati.com/djpashupati/api/product/update.php', formData).subscribe(()=>this.router.navigate(['/admin/home/create-product']),(error: any)=>this.router.navigate(['/admin/home/create-product']));
                                        }
                                }else{

                                var formData:any=new FormData();
                                formData.append("Product_name",this.form.get('product_name').value);
                                formData.append("Description",this.form.get('product_description').value);
                                formData.append("Initial_price",this.form.get('initial_price').value);
                                formData.append("Final_price",this.form.get('final_price').value);
                                formData.append("Image",this.form.get('product_image_url').value);
                                formData.append("Category_id",this.form.get('category_id').value);
                                formData.append("Subcategory_id",this.form.get('sub_category_id').value);
                                formData.append("Wt_unit",this.form.get('unit').value);
                                formData.append("Brand",this.form.get('brand').value);
                                this.sendMsg();
                                                          this.http.post<Product[]>('http://djpashupati.com/djpashupati/api/product/add-product.php',formData)
                                                           .subscribe(()=>{ this.submitted = false;
                                                                    this.listCategory();
                                                                    this.listSubCategory();
                                                                    this.form.reset();
                                                                       },
                                                                   (error: any)=>{
                                                                   this.submitted = false;
                                                                        this.listCategory();
                                                                        this.listSubCategory();
                                                                        this.listProducts();
                                                                        this.form.reset();
                                                                   });



                                }


                          });







}
  }

sendMsg(){
let dIds=JSON.stringify(this.devices);
console.log(dIds);
return this.http.post<any>('http://djpashupati.com/Djpashupati_Api/fcm.php',this.devices)
.subscribe(data => console.log(data)
)}

  delete(id){
  var detail={id: id};

 // console.log('remove', cartItem);
//  return this._productService.deleteCategory(id);
this.http.post('http://djpashupati.com/djpashupati/api/product/delete.php',detail)
.subscribe();
return this.ngOnInit();

  console.log(detail);
  }
logout(){
localStorage.setItem('isAdminLoggedin',"false");
this.router.navigateByUrl('/admin/index');

}


getDeviceId(){
return  this.http.get<any>('http://djpashupati.com/Djpashupati_Api/fetch_token.php').subscribe(data=>{this.devices=data;});


}


edit(subCategoryId:number){
this.router.navigate(['/admin/home/edit-product', subCategoryId]);


}

}
