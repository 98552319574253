<app-header></app-header>
<div class="container">
  <div class="col-md-12">
    <div class="row mt-2">
      <div class="col-md-8">
        <form [formGroup]="checkOutFormGroup" (ngSubmit)="onSubmit()">
          <fieldset class="form-border">
            <legend class="form-border">
              <h3>Customer</h3>
            </legend>
            <div formGroupName="customer">
              <div class="row form-group">
                <div class="col-md-3">
                  <label>Full Name</label>
                </div>
                <div class="col-md-9">
                  <input type="text" formControlName="Customer_name" class="form-control" disabled>
                </div>
              </div>

              <div class="row form-group">
                <div class="col-md-3">
                  <label>Email Address</label>
                </div>
                <div class="col-md-9">
                  <input type="email" formControlName="Customer_email" class="form-control" disabled>
                </div>
              </div>
              <div class="row form-group">
                <div class="col-md-3">
                  <label>Contact</label>
                </div>
                <div class="col-md-9">
                  <input type="text" formControlName="Customer_phone" class="form-control">
                </div>
              </div>

              <div class="row form-group">
                <div class="col-md-3">
                  <label>Full Address</label>
                </div>
                <div class="col-md-9">
                  <input type="text" formControlName="Customer_address" class="form-control" required="true">
                </div>
              </div>
            </div>
          </fieldset>



<!--          <fieldset class="form-border">-->
<!--            <legend class="form-border">-->
<!--              <h3>Shipping Address</h3>-->
<!--            </legend>-->
<!--            <div formGroupName="shippingAddress">-->
<!--              <div class="row form-group">-->
<!--                <div class="col-md-3">-->
<!--                  <label>City</label>-->
<!--                </div>-->
<!--                <div class="col-md-9">-->
<!--                  <input type="text" formControlName="city" class="form-control">-->
<!--                </div>-->
<!--              </div>-->

<!--              <div class="row form-group">-->
<!--                <div class="col-md-3">-->
<!--                  <label>Municipality/VDC</label>-->
<!--                </div>-->
<!--                <div class="col-md-9">-->
<!--                  <input type="text" formControlName="municipality" class="form-control">-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="row form-group">-->
<!--                <div class="col-md-3">-->
<!--                  <label>Tole/Village</label>-->
<!--                </div>-->
<!--                <div class="col-md-9">-->
<!--                  <input type="text" formControlName="tole" class="form-control">-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </fieldset>-->

<!--&lt;!&ndash;          checkbox for same address&ndash;&gt;-->
<!--          <div class="form-check">-->
<!--            <input type="checkbox" class="form-check-input" (change)="copyShippingAddress($event)"/>-->
<!--            <label>Shipping address is same as my billing address.</label>-->
<!--          </div>-->
<!--          <br/>-->
<!--          <fieldset class="form-border">-->
<!--            <legend class="form-border">-->
<!--              <h3>Billing Address</h3>-->
<!--            </legend>-->
<!--            <div formGroupName="billingAddress">-->
<!--              <div class="row form-group">-->
<!--                <div class="col-md-3">-->
<!--                  <label>City</label>-->
<!--                </div>-->
<!--                <div class="col-md-9">-->
<!--                  <input type="text" formControlName="city" class="form-control">-->
<!--                </div>-->
<!--              </div>-->

<!--              <div class="row form-group">-->
<!--                <div class="col-md-3">-->
<!--                  <label>Municipality/VDC</label>-->
<!--                </div>-->
<!--                <div class="col-md-9">-->
<!--                  <input type="text" formControlName="municipality" class="form-control">-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="row form-group">-->
<!--                <div class="col-md-3">-->
<!--                  <label>Tole/Village</label>-->
<!--                </div>-->
<!--                <div class="col-md-9">-->
<!--                  <input type="text" formControlName="tole" class="form-control">-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </fieldset>-->
          <div class="text-center float-right">
            <button type="submit" class="btn btn-primary mb-1">Place Order</button>
          </div>
        </form>
      </div>

<!--      Cart Items-->
      <div class="col-md-4">
        <div class="row">
          <div class="col-md-12 order-md-2 mb-4">
            <h4 class="d-flex justify-content-between align-items-center mb-3">
              <span class="text-muted">Your cart</span>
              <span class="badge badge-secondary badge-pill">{{totalQuantity}}</span>
            </h4>
            <ul class="list-group mb-3">
              <li *ngFor="let cartItem of cartItems" class="list-group-item d-flex justify-content-between lh-condensed">
                <div>
                  <h6 class="my-0">{{cartItem.Product_name}}</h6>
                  <small class="text-muted">Quantity (x{{cartItem.Product_quantity}})</small>
                </div>
                <span class="text-muted">{{cartItem.Product_quantity * cartItem.Product_price| currency: 'रू '}}</span>
              </li>

              <li class="list-group-item d-flex justify-content-between">
                <span>Total</span>
                <strong>{{total(cartItems)| currency: 'रू '}}</strong>
              </li>
            </ul>


          </div>
      </div>
    </div>
  </div>
</div>
</div>
<app-footer></app-footer>
