<!--Author: W3layouts
Author URL: http://w3layouts.com
License: Creative Commons Attribution 3.0 Unported
License URL: http://creativecommons.org/licenses/by/3.0/
-->
<!DOCTYPE HTML>
<html>
<head>
  <title>DjPashupati:Admin Panel</title>
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  <meta name="keywords" content="DjPashupati Online shopping" />
  <script src="http://ajax.googleapis.com/ajax/libs/angularjs/1.5.7/angular.min.js"></script>
  <script type="application/x-javascript"> addEventListener("load", function() { setTimeout(hideURLbar, 0); }, false); function hideURLbar(){ window.scrollTo(0,1); } </script>
  <!-- jQuery (necessary for Bootstrap's JavaScript plugins) -->
  <!--  <link href="css/bootstrap.css" rel="stylesheet" type="text/css" media="all">-->
  <!--  &lt;!&ndash; Custom Theme files &ndash;&gt;-->
  <!--  <link href="css/style.css" rel="stylesheet" type="text/css" media="all"/>-->
  <!--  &lt;!&ndash;js&ndash;&gt;-->
  <!--  <script src="js/jquery-2.1.1.min.js"></script>-->
  <!--  &lt;!&ndash;icons-css&ndash;&gt;-->
  <!--  <link href="src/assets/css/font-awesome.css" rel="stylesheet">-->
  <!--Google Fonts-->
  <link href='//fonts.googleapis.com/css?family=Carrois+Gothic' rel='stylesheet' type='text/css'>
  <link href='//fonts.googleapis.com/css?family=Work+Sans:400,500,600' rel='stylesheet' type='text/css'>
  <!--static chart-->
  <!--  <script src="js/Chart.min.js"></script>-->
  <!--//charts-->
  <!-- geo chart -->
  <script src="//cdn.jsdelivr.net/modernizr/2.8.3/modernizr.min.js" type="text/javascript"></script>
  <script>window.modernizr || document.write('<script src="lib/modernizr/modernizr-custom.js"><\/script>')</script>


</head>
<body>

<div class="page-container modal container modal" id="modalLoginForm" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
aria-hidden="true">
  <div class="left-content">
    <div class="mother-grid-inner">

      <div class="inner-block">
        <!--market updates updates-->


        <!--market updates end here-->
        <!--mainpage chit-chating-->

        <!--main page chit chating end here-->
        <!--main page chart start here-->
        <div class="main-page-charts">
          <div class="main-page-chart-layer1">
            <div class="col-md-12 chit-chat-layer1-left">
              <div class="work-progres">
                <div class="chit-chat-heading">
                  About Us
                </div>
                <div class="container contact-form">

                  <form   [formGroup]="form"
                          name="form"
                          (ngSubmit)="onSubmit()">
                    <div class="row">
                      <div class="col-md-9">
                        <div class="form-group">
                          <textarea name="txtMsg" class="form-control" formControlName="about_us" placeholder="Start here *" style="width: 100%; height: 150px;"></textarea>
                        </div>
                      </div>
                      <div class="form-group">
                        <input type="submit" name="btnSubmit"  class="btnContact" value="Submit" />
                      </div>

                    </div>
                  </form>
                </div>
              </div>
            </div>

          </div>



          <div class="clearfix"> </div>

        </div>

      </div>
      <!--inner block end here-->
      <!--copy rights start here-->

      <!--COPY rights end here-->
    </div>
  </div>
  <!--slider menu-->

</div>
<!-- mother grid end here-->
</body>

</html>
