import { Component, OnInit } from '@angular/core';
import { UploaderService } from "../services/uploader.service";
import { ActivatedRoute, Router } from '@angular/router';

import {
  HttpClient,
  HttpRequest,
  HttpEventType,
  HttpEvent
} from "@angular/common/http";
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  progress: number;
  infoMessage: any;
  base64textString:any='';
  user_id:string='';
  user_name:string='';
  user_email:string='';
user:any=[''];
  isUploading: boolean = false;
  file: File;
  image: any='';
   imageUrl: string | ArrayBuffer =
      "http://djpashupati.com/Djpashupati_Api/users_image/41.png";
    fileName: string = "No file selected";
  constructor(private uploader: UploaderService,
   private router: Router,
   private http:HttpClient) { }

  ngOnInit() {
 this.uploader.progressSource.subscribe(progress => {
      this.progress = progress;
    });

              this.user_id=localStorage.getItem('user_id');
              this.user_name=localStorage.getItem('user_name');
              this.user_email=localStorage.getItem('user_email');

    this.uploader.getImage(this.user_id).subscribe(data => {
//     let myObj = JSON.parse(progress);

      this.image = data;
//             console.log(this.image);

//       console.log(this.imageUrl);
    });


  }


   onChange(file: File) {
      if (file) {
        this.fileName = file.name;
        this.file = file;
  var reader = new FileReader();
       reader.onload =this.handleFile.bind(this);

               reader.readAsBinaryString(file);
      }
    }
     handleFile(event) {
         var binaryString = event.target.result;
                this.base64textString= btoa(binaryString);
                console.log(btoa(binaryString));
        }

    onUpload() {
      this.infoMessage = null;
      this.progress = 0;
      this.isUploading = true;

      this.uploader.upload(this.base64textString,this.user_id).subscribe(message => {
        this.isUploading = false;
        this.infoMessage = message;

             return this.router.navigate(['/image-upload-success']);

      });
    }
    logout(){
    localStorage.setItem('isLoggedIn',"false");
    return this.router.navigateByUrl('/user/login');

    }

}
