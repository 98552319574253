import { Component, OnInit } from '@angular/core';
// import { AuthService } from '../services/auth.service';
import { FormBuilder, FormGroup } from "@angular/forms";
import { HttpClient } from '@angular/common/http';
import { first } from 'rxjs/operators';
import{Router} from '@angular/router';
@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
form: FormGroup;
 msg: any='';
error: any=[];
isVendorLoggedin: boolean=false;
 constructor(
     public fb: FormBuilder,
     private http: HttpClient,
     private router: Router,
   ) {
     this.form = this.fb.group({
       admin_email: [''],
       admin_password: ['']
//        tag:['resend_code']
     })
   }
  ngOnInit() { }



  onSubmit() {
  let formObj = this.form.getRawValue(); // {name: '', description: ''}

let serializedForm = JSON.stringify(formObj);
// console.log(serializedForm);
        this.http.post('http://djpashupati.com/djpashupati/api/admin/admin-login.php', serializedForm).subscribe(
          (response) =>{
          this.error=response
          if(this.error.error==false){
//             console.log(this.error);
            this.isVendorLoggedin=true;
            localStorage.setItem("isAdminLoggedin", 'true');
            localStorage.setItem("admin", this.error.admin_name);
            localStorage.setItem("admin_email", this.error.admin_email);
            localStorage.setItem("admin_password", this.error.admin_password);

this.router.navigateByUrl('/admin/home');
          }else{
          this.msg=response
//           console.log(this.msg);
          }
           }

        );

        }
// end of onSubmit


}
