import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import {ActivatedRoute,Router } from '@angular/router';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {

  constructor(
        private location: Location,
          private _router:Router,


  ) { }

  ngOnInit() {

  }
gooo  (){
return this._router.navigate([localStorage.getItem("url")]);
}
}
