import { Component, OnInit } from '@angular/core';
import {ProductCategory} from 'src/app/common/product-category';
import {ProductSubCategory} from 'src/app/common/product-sub-category';
import { Validators, FormBuilder, FormGroup } from "@angular/forms";
import { HttpClient } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import {ActivatedRoute } from '@angular/router';
import {ProductService} from 'src/app/services/product.service';
import {Product} from 'src/app/common/product';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
form: FormGroup;
submitted:boolean;
  constructor(private _productService: ProductService,
                              private _activatedRoute:ActivatedRoute,
                              public fb:FormBuilder,
                              private router:Router,
                              private http:HttpClient,) {

    this.form=this.fb.group({
                   admin_password:['',[Validators.required, Validators.minLength(6)]],
                   confirmPassword:['',[Validators.required, Validators.minLength(6)]],
admin_email:[''],
                   }, this.pwdMatchValidator);

   }

  ngOnInit() {
    let q= localStorage.getItem("isAdminLoggedin");
    if(q!='true'){
    this.router.navigateByUrl('/admin/index');
    }
  }



  get f() { return this.form.controls; }

      onSubmit() {
          this.submitted = true;

          // stop here if form is invalid
          if (this.form.invalid) {
              return false;
          }else{
                   var formData:any=new FormData();
                    let admin_email=localStorage.getItem("admin_email");
                    this.form.get('admin_email').setValue(admin_email)
//                     formData.append("admin_password",this.form.get('password').value);
//                     formData.append("admin_email", admin_email);

 let formObj = this.form.getRawValue(); // {name: '', description: ''}

let serializedForm = JSON.stringify(formObj);
                    console.log(serializedForm);

          this.http.post<any>("http://djpashupati.com/djpashupati/api/admin/change-password.php", serializedForm).subscribe(data=>console.log(data));
            localStorage.setItem("isAdminLoggedin", 'false');
this.router.navigate(['/admin/index']);
          }


      }

      pwdMatchValidator(form: FormGroup) {
          return form.get('password').value === form.get('confirmPassword').value
             ? null : {'mismatch': true};
}
logout(){
localStorage.setItem('isAdminLoggedin',"false");
this.router.navigateByUrl('/admin/index');

}
}
