<style>
@media screen and (max-width: 600px) {
 input{
 width:100px;
 margin-left:-50px;

 }
 button{
 width:75px;
 }



}

@media screen and (min-width: 1000px) {
 .form-control{
 width:500px;

 }


}
</style>

<meta http-equiv="Content-Type" content="text/html;charset=UTF-8">
<nav class="navbar navbar-expand-lg navbar-expand-sm navbar-expand-md navbar-light" style="background-color:#66023c;"  >
  <img routerLink="/home" class="navbar-brand" src="./assets/images/djplogo.jpg" style="height:100px; margin-left:10%;" >
  <div class="navbar-collapse" id="navbarSupportedContent" >
    <div class="form-inline my-2 my-lg-0 col-xsm-2" style=" margin-left:15%;">
      <input #searchInput (keyup.enter)="searchProduct(searchInput.value)" class="form-control col-sm-7" style= "outline: none; border-top-left-radius: 10px; border-bottom-left-radius: 10px; height:29px; border-right:none;" type="search" placeholder="{{'navContent.search'|translate}}.." aria-label="Search">
      <button (click)="searchProduct(searchInput.value)" class="btn btn-outline-success my-2 my-sm-0"  style="z-index:1; border-top-right-radius: 10px;border-bottom-right-radius: 10px; border-width:2px; padding:0.5px;  border-top-left-radius: 0px;border-bottom-left-radius: 0px; border-left:none; margin-left:-4%; color:white; background-color:green;" type="submit"><span class="mdi mdi-magnify"></span> {{'navContent.search'|translate}}</button>
    </div>
    <ul class="navbar-nav "  style="text-align:center; ">

      <li class="nav-item oc" data-toggle="modal" data-target="#cartModal"><a class="nav-link" >
        <div class="col-xsm-1" style="color:white;">
          <span class="badge badge-light" *ngIf="totalQuantity>0 || totalQuantity==0"><i class="fa fa-upload"></i> </span>
          <span class="mdi mdi-cart mdi-24px"></span>
          <div >{{'navContent.cart' | translate}}</div>
        </div>
      </a>
      </li>
<app-cart></app-cart>
      <li class="nav-item"><a class="nav-link" >
        <div *ngIf="!isLoggedIn" class="col-xsm-1" style="color:white;" routerLink="/user/login">
          <span class="mdi mdi-account-circle mdi-24px"></span>
          <div>{{'navContent.login' | translate}}</div>
        </div>

        <div *ngIf="isLoggedIn" class="dropdown show">
          <span  style="color:white;" class="mdi mdi-account-circle mdi-24px"></span>
          <div class="col-xsm-1" style="color:white;" class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{'navContent.profile' | translate}}</div>

          <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <a class="dropdown-item" routerLink="/myorders">{{'sidebarContent.my-order'|translate}}</a>
            <a class="dropdown-item" routerLink="/user-profile">{{'navContent.profile'|translate}}</a>
            <a class="dropdown-item" (click)="logout()">{{'sidebarContent.logout'|translate}}</a>
<!--            <a class="dropdown-item" href="#">Something else here</a>-->
          </div>
        </div>

      </a>
      </li>


    </ul>
  </div>
</nav>


<!--side bar-->


<style>
  @keyframes swing {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(10deg);
  }
  30% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(5deg);
  }
  70% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes sonar {
  0% {
    transform: scale(0.9);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.page-wrapper .sidebar-wrapper,
.sidebar-wrapper .sidebar-brand > a,
.sidebar-wrapper .sidebar-dropdown > a:after,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before,
.sidebar-wrapper ul li a i,
.page-wrapper .page-content,
.sidebar-wrapper .sidebar-search input.search-menu,
.sidebar-wrapper .sidebar-search .input-group-text,
.sidebar-wrapper .sidebar-menu ul li a,
#show-sidebar,
#close-sidebar {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

/*----------------page-wrapper----------------*/

.page-wrapper {
  height: 100vh;

}

.page-wrapper .theme {
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 4px;
  margin: 2px;
}

.page-wrapper .theme.chiller-theme {
  background: #1e2229;
}

/*----------------toggeled sidebar----------------*/

.page-wrapper.toggled .sidebar-wrapper {
  left: 0px;
}

@media screen and (min-width: 768px) {
  .page-wrapper.toggled .page-content {
    padding-left: 300px;
  }
}
/*----------------show sidebar button----------------*/
#show-sidebar {
  position: fixed;
  left: 0;
  top: 10px;
  border-radius: 0 4px 4px 0px;
  width: 35px;
  transition-delay: 0.3s;
}
.page-wrapper.toggled #show-sidebar {
  left: -40px;
}
/*----------------sidebar-wrapper----------------*/

.sidebar-wrapper {
  width: 260px;
  height: 100%;
  max-height: 100%;
  position: fixed;
  top:0;
  left: -300px;
  z-index: 999;
}

.sidebar-wrapper ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar-wrapper a {
  text-decoration: none;
}

/*----------------sidebar-content----------------*/

.sidebar-content {
  max-height: calc(100% - 30px);
  height: calc(100% - 30px);
  overflow-y: auto;
  position: relative;
}

.sidebar-content.desktop {
  overflow-y: hidden;
}

/*--------------------sidebar-brand----------------------*/

.sidebar-wrapper .sidebar-brand {
  padding: 10px 20px;
  display: flex;
  align-items: center;
}

.sidebar-wrapper .sidebar-brand > a {
  text-transform: uppercase;
  font-weight: bold;
  flex-grow: 1;
}

.sidebar-wrapper .sidebar-brand #close-sidebar {
  cursor: pointer;
  font-size: 20px;
}


.sidebar-wrapper .sidebar-menu {
  padding-bottom: 10px;
}

.sidebar-wrapper .sidebar-menu .header-menu span {
  font-weight: bold;
  font-size: 14px;
  padding: 15px 20px 5px 20px;
  display: inline-block;
}

.sidebar-wrapper .sidebar-menu ul li a {
  display: inline-block;
  width: 100%;
  text-decoration: none;
  position: relative;
  padding: 8px 30px 8px 20px;
}

.sidebar-wrapper .sidebar-menu ul li a i {
  margin-right: 10px;
  font-size: 12px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 4px;
}

.sidebar-wrapper .sidebar-menu ul li a:hover > i::before {
  display: inline-block;
  animation: swing ease-in-out 0.5s 1 alternate;
}

/*--------------------------page-content-----------------------------*/

.page-wrapper .page-content {
  display: inline-block;
  width: 100%;
  padding-left: 0px;
  padding-top: 20px;
}

.page-wrapper .page-content > div {
  padding: 20px 40px;
}

.page-wrapper .page-content {
  overflow-x: hidden;
}

/*------scroll bar---------------------*/

::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #525965;
  border: 0px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: #525965;
}
::-webkit-scrollbar-thumb:active {
  background: #525965;
}
::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: transparent;
}
::-webkit-scrollbar-track:active {
  background: transparent;
}
::-webkit-scrollbar-corner {
  background: transparent;
}


/*-----------------------------chiller-theme-------------------------------------------------*/

.chiller-theme .sidebar-wrapper {
    background: #31353D;
}

.chiller-theme .sidebar-wrapper .sidebar-header,
.chiller-theme .sidebar-wrapper .sidebar-search,
.chiller-theme .sidebar-wrapper .sidebar-menu {
    border-top: 1px solid #3a3f48;
}

.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text {
    border-color: transparent;
    box-shadow: none;
}

.chiller-theme .sidebar-wrapper .sidebar-header .user-info .user-role,
.chiller-theme .sidebar-wrapper .sidebar-header .user-info .user-status,
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text,
.chiller-theme .sidebar-wrapper .sidebar-brand>a,
.chiller-theme .sidebar-wrapper .sidebar-menu ul li a,
.chiller-theme .sidebar-footer>a {
    color: #818896;
}

.chiller-theme .sidebar-wrapper .sidebar-menu ul li:hover>a,
.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active>a,
.chiller-theme .sidebar-wrapper .sidebar-header .user-info,
.chiller-theme .sidebar-wrapper .sidebar-brand>a:hover,
.chiller-theme .sidebar-footer>a:hover i {
    color: #b8bfce;
}

.page-wrapper.chiller-theme.toggled #close-sidebar {
    color: #bdbdbd;
}

.page-wrapper.chiller-theme.toggled #close-sidebar:hover {
    color: #ffffff;
}

.chiller-theme .sidebar-wrapper ul li:hover a i,
.chiller-theme .sidebar-wrapper .sidebar-dropdown .sidebar-submenu li a:hover:before,
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu:focus+span,
.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active a i {
    color: #16c7ff;
    text-shadow:0px 0px 10px rgba(22, 199, 255, 0.5);
}

.chiller-theme .sidebar-wrapper .sidebar-menu ul li a i,
.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown div,
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text {
    background: #3a3f48;
}

.chiller-theme .sidebar-wrapper .sidebar-menu .header-menu span {
    color: #6c7b88;
}

.chiller-theme .sidebar-footer {
    background: #3a3f48;
    box-shadow: 0px -1px 5px #282c33;
    border-top: 1px solid #464a52;
}

.chiller-theme .sidebar-footer>a:first-child {
    border-left: none;
}

.chiller-theme .sidebar-footer>a:last-child {
    border-right: none;
}
.page-wrapper {
height:0px;
}
</style>
<div class="page-wrapper chiller-theme" >
  <span id="show-sidebar" class="btn btn-sm" style=" margin-top:35px;">
    <i class="mdi mdi-view-headline mdi-24px" style="color:white;"></i>
  </span>
  <nav id="sidebar" class="sidebar-wrapper">
    <div class="sidebar-content">
      <div class="sidebar-brand">
        <a routerLink="/home" aria-disabled="true">DjPashupati</a>
        <div id="close-sidebar">
          <i class="mdi mdi-close"></i>
        </div>
      </div>

      <div class="sidebar-menu" >
        <ul>
          <li class="header-menu">
            <a routerLink="/search/offers">{{'sidebarContent.offers'|translate}}</a>
          </li>
          <li class="header-menu">
            <a (click)="newProduct('new')" >{{'sidebarContent.new-product'|translate}}</a>
          </li>
          <li class="header-menu">
            <a (click)="popularProduct('popular')">{{'sidebarContent.popular-product'|translate}}</a>
          </li>
          <li class="header-menu">
            <a routerLink="/myorders" *ngIf="isLoggedIn">{{'sidebarContent.my-order'|translate}}</a>
          </li>
        </ul>



        <hr class="solid">
        <ul>
          <div class="sidebar-brand">
            <a  aria-disabled="true">{{'sidebarContent.language'|translate}}</a >
            </div>
          <li class="header-menu" (click)="switchLang('en')">
            <a>{{'sidebarContent.english'|translate}}</a>
          </li>
          <li class="header-menu" (click)="switchLang('ne')">
            <a>{{'sidebarContent.nepali'|translate}}</a>
          </li>

        </ul>

        <hr class="solid">
        <ul>

          <li class="header-menu" (click)="logout()" *ngIf="isLoggedIn">
            <a>{{'sidebarContent.logout'|translate}}</a>
          </li>
<!--          <li class="header-menu" (click)="switchLang('ne')">-->
<!--            <span>{{'sidebarContent.nepali'|translate}}</span>-->
<!--          </li>-->
          <hr class="solid">
          <li class="nav-item oc wow" data-toggle="modal" data-target="#cartModal"><a class="nav-link" >
            <div class="col-xsm-1" style="color:white;">
              <span class="badge badge-light">{{totalQuantity}}</span>
              <span class="mdi mdi-cart mdi-24px"></span>
              <div >{{'navContent.cart' | translate}}</div>
            </div>
          </a>
          </li>

          <li class="nav-item again-wow"><a class="nav-link" >
            <div *ngIf="!isLoggedIn" class="col-xsm-1" style="color:white;" routerLink="/user/login">
              <span class="mdi mdi-account-circle mdi-24px"></span>
              <div>{{'navContent.login' | translate}}</div>
            </div>

            <div *ngIf="isLoggedIn" class="dropdown show" routerLink="/user-profile">
              <span  style="color:white;" class="mdi mdi-account-circle mdi-24px"></span>
              <div class="col-xsm-1" style="color:white;" >{{'navContent.profile' | translate}}</div>
            </div>

          </a>
          </li>
        </ul>

      </div>
      <!-- sidebar-menu  -->
    </div>
  </nav>
  <!--  close side bar-->
</div>
