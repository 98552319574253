
<link href='//fonts.googleapis.com/css?family=Carrois+Gothic' rel='stylesheet' type='text/css'>
<link href='//fonts.googleapis.com/css?family=Work+Sans:400,500,600' rel='stylesheet' type='text/css'>
<!--static chart-->
<!--  <script src="js/Chart.min.js"></script>-->
<!--//charts-->
<!-- geo chart -->
<script src="//cdn.jsdelivr.net/modernizr/2.8.3/modernizr.min.js" type="text/javascript"></script>
<app-header></app-header>

<div class="page-container">

  <div class="left-content">
    <div class="mother-grid-inner">

      <div>
        <header>
          <nav><i class="mdi mdi-chevron-double-left"></i><a (click)="goPrevious()">Go to My Orders</a></nav>
        </header>
        <div class="chit-chat-layer1">
          <div class="col-md-8 chit-chat-layer1-left">
            <div class="work-progres">
              <div class="chit-chat-heading">
                My Orders ({{order_id}})
              </div>
              <div class="table-responsive" >
                <table class="table table-hover">
                  <thead>
                  <tr>
                    <th>SN</th>
                    <th>Product Id</th>
                    <th>Product Name</th>
                    <th>Price</th>
                    <th>Quantity</th>
                    <th>Order Date</th>
                    <th>Status</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let order of orders|paginate: { itemsPerPage: 5, currentPage: p }; let i = index">
                    <td>{{i+1}}</td>
                    <td>{{order.product_id}}</td>
                    <td>{{order.name}}</td>
                    <td>{{order.amount}}</td>
                    <td>{{order.quantity}}</td>
                    <td>{{order.order_date}}</td>
                    <td><span class="label label-success" *ngIf="order.status=='shipped'">{{order.status}}</span>
                      <span class="label label-danger" *ngIf="order.status=='cancelled'">{{order.status}}</span>
                      <span class="label label-primary" *ngIf="order.status=='pending'">{{order.status}}</span>
                      <span class="label label-primary" *ngIf="order.status=='vpending'">Pending</span>
                      <span class="label label-primary" *ngIf="order.status=='vcancelled'">Pending</span>
                    </td>
                  </tr>

                  </tbody>
                </table>
                <pagination-controls (pageChange)="p = $event" class="row m-auto justify-content-center"></pagination-controls>

              </div>
            </div>
          </div>

          <div class="clearfix"> </div>
        </div>

      </div>
    </div>
    <!--slider menu-->
  </div>
  <div class="clearfix"> </div>
</div>
<!-- mother grid end here-->

<app-footer></app-footer>
