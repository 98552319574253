import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
public lang: string='';










constructor(public translate: TranslateService,private router: Router,){


translate.addLangs(['en','ne']);
translate.setDefaultLang('en');
const browserLang=this.lang;
}

switchNeLang(language: string){
this.lang=language;
this.gotoHome();
}

gotoHome() {
this.router.navigate(['/home']);

}


}

