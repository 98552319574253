import { Component, OnInit } from '@angular/core';
import {ActivatedRoute } from '@angular/router';
import {ProductService} from 'src/app/services/product.service';
import {Product} from 'src/app/common/product';
import {Vendor} from 'src/app/common/vendor';
import {VendorRequest} from 'src/app/common/vendor-request';

import { FormBuilder,FormArray, FormGroup } from "@angular/forms";
import { HttpClient } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
about_us: any=[''];
  constructor(private http:HttpClient) { }

  ngOnInit() {
  return this.http.get<any>("http://djpashupati.com/Djpashupati_Api/fetch_about_us.php").subscribe(data=>this.about_us=data);
  }

}
