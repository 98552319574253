import { Component, OnInit } from '@angular/core';
import {ActivatedRoute,Router } from '@angular/router';
import {ProductService} from '../services/product.service';
import {CartService} from '../services/cart.service';
import {Product} from '../common/product';
import { Location } from '@angular/common';
import {HttpClient} from '@angular/common/http';

import {Cart} from '../common/cart';
import {ProductCategory} from '../common/product-category';

@Component({
  selector: 'app-popular-product',
  templateUrl: './popular-product.component.html',
  styleUrls: ['./popular-product.component.scss']
})
export class PopularProductComponent implements OnInit {

product: Product[];
currentCategoryId:number;
categoryName: string;
sCat: string;
sCatId: number;
searchMode: boolean;
loader:boolean=true;
p:number=1;
newMode: boolean;
popMode: boolean;
isLoggedIn: boolean=false;
  constructor(private _productService: ProductService,
  private _activatedRoute:ActivatedRoute,
  private _router:Router,
  private http:HttpClient,
      private location: Location,
  private _cartService:CartService,
  ) { }
 goPrevious(){
  this.location.back();
  }
  ngOnInit(){
localStorage.setItem("url",this.location.path());

   if (localStorage.getItem('isLoggedIn') == "true") {
   this.isLoggedIn=true;
    }
    this._activatedRoute.paramMap.subscribe(()=>{
     this.handlePopularProduct();
     })
  }




handleListProduct(){

 const hasCategoryId: boolean=this._activatedRoute.snapshot.paramMap.has('id');
  this.sCatId=+this._activatedRoute.snapshot.paramMap.get('id');
  if(hasCategoryId){
  this.currentCategoryId=+this._activatedRoute.snapshot.paramMap.get('id');
    this.categoryName=this._activatedRoute.snapshot.paramMap.get('name');
    this.sCat=this._activatedRoute.snapshot.paramMap.get('sName');
  }else{
  this.currentCategoryId=1;
  }
  var formData2: any=new FormData();
    formData2.append("Subcategory_id", this.currentCategoryId);
this.http.post<any>('http://djpashupati.com/Djpashupati_Api/Fetch_product.php', formData2).subscribe(data =>
{this.product=data;
console.log(data);
this.loader=false;
console.log(data)
if(data==[]){
this._router.navigate(['/product-not-found']);
}
})
  }



handleListNewProduct(){
this.http.get<any>('http://djpashupati.com/Djpashupati_Api/new_product.php').subscribe(data=>{
console.log(data);
this.product=data;
this.loader=false;

console.log(data)
if(data===[]){
this._router.navigate(['/product not available']);
}
}
)
}


handleSearchProduct(){
const keyword: string=this._activatedRoute.snapshot.paramMap.get('keyword');
this._productService.searchProduct(keyword).subscribe(
data=>{
this.product=data
console.log(data)
this.loader=false;

if(data===[]){
this._router.navigate(['/product not available']);
}
}
)
}


// handle popular product
handlePopularProduct(){
this.http.get<any>('http://djpashupati.com/Djpashupati_Api/popular_product_fetching.php').subscribe(data=>{
console.log(data);
this.product=data;
this.loader=false;

console.log(data)
if(data===[]){
this._router.navigate(['/product not available']);
}
}
)
}

addToCart(product: Product){
console.log(product);
// console.log(`product name: ${product.productName}, and price: ${product.finalPrice}`)
const userId=localStorage.getItem("user_id");
const orderId=localStorage.getItem("bill_number");

const cartItem=new Cart(product, userId);

 var formData: any = new FormData();
        formData.append("Product_name", cartItem.Product_name);
        formData.append("Product_price", cartItem.Product_price);
        formData.append("Product_quantity", cartItem.Product_quantity);
        formData.append("users_id", userId);
        formData.append("Product_id", cartItem.Product_id);
        formData.append("Product_image", cartItem.Product_image);
       // formData.append("Total_price", cartItem.Product_price);
        formData.append("Wt_unit", cartItem.Wt_unit);

// console.log(formData);
let orderedItems = JSON.stringify(cartItem);
// console.log(orderedItems);
// console.log(localStorage.getItem("url"));

// let userId=localStorage.getItem("user_id");
this._cartService.addToCart(cartItem);
return this.http.post<any>('http://djpashupati.com/djpashupati/api/order/place-order.php', orderedItems).subscribe(()=>this._router.navigate(['/home/products/success/']),(error: any)=>this._router.navigate(['/home/products/success']));

}

}
