import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-image-success',
  templateUrl: './image-success.component.html',
  styleUrls: ['./image-success.component.scss']
})
export class ImageSuccessComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
