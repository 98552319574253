<div class="header-main">
  <div class="header-left">
    <div class="logo-name">
      <a routerLink="/admin/home"> <h1>Admin</h1>
        <!--<img id="logo" src="" alt="Logo"/>-->
      </a>
    </div>
    <!--search-box-->
    <!--          <div class="search-box">-->
    <!--            <form [formGroup]="form"-->
    <!--                  name="form"-->
    <!--                  (ngSubmit)="onSubmit()">-->
    <!--              <input type="text" placeholder="Search..." required="">-->
    <!--              <input type="submit" value="">-->
    <!--            </form>-->
    <!--          </div>&lt;!&ndash;//end-search-box&ndash;&gt;-->
    <div class="clearfix"> </div>
  </div>
  <div class="header-right">
    <div class="profile_details_left"><!--notifications of menu start -->

      <div class="clearfix"> </div>
    </div>
    <!--notification menu end -->
    <div class="profile_details">
      <ul>
        <li class="dropdown profile_details_drop">
          <a  class="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
            <div class="profile_img">
              <span class="prfil-img"><img src="images/p1.png" alt=""> </span>
              <div class="user-name">
                <p>{{admin}}</p>
                <span>Administrator</span>
              </div>
              <i class="fa fa-angle-down lnr"></i>
              <i class="fa fa-angle-up lnr"></i>
              <div class="clearfix"></div>
            </div>
          </a>
          <ul class="dropdown-menu drp-mnu">
            <li> <a (click)="switchLang('en')"> {{'sidebarContent.english'|translate}}</a> </li>
            <li> <a (click)="switchLang('ne')"> {{'sidebarContent.nepali'|translate}}</a> </li>
            <li> <a (click)="logout()"><i class="fa fa-sign-out"></i> Logout</a> </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="clearfix"> </div>
  </div>
  <div class="clearfix"> </div>
</div>
<script>
		$(document).ready(function() {
			 var navoffeset=$(".header-main").offset().top;
			 $(window).scroll(function(){
				var scrollpos=$(window).scrollTop();
				if(scrollpos >=navoffeset){
					$(".header-main").addClass("fixed");
				}else{
					$(".header-main").removeClass("fixed");
				}
			 });

		});
		</script>
