<!--Author: W3layouts
Author URL: http://w3layouts.com
License: Creative Commons Attribution 3.0 Unported
License URL: http://creativecommons.org/licenses/by/3.0/
-->
<!DOCTYPE HTML>
<html>
<head>
  <title>DjPashupati:Admin Panel</title>
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  <meta name="keywords" content="DjPashupati Online shopping" />
  <script src="http://ajax.googleapis.com/ajax/libs/angularjs/1.5.7/angular.min.js"></script>
  <script type="application/x-javascript"> addEventListener("load", function() { setTimeout(hideURLbar, 0); }, false); function hideURLbar(){ window.scrollTo(0,1); } </script>
  <!-- jQuery (necessary for Bootstrap's JavaScript plugins) -->
  <!--  <link href="css/bootstrap.css" rel="stylesheet" type="text/css" media="all">-->
  <!--  &lt;!&ndash; Custom Theme files &ndash;&gt;-->
  <!--  <link href="css/style.css" rel="stylesheet" type="text/css" media="all"/>-->
  <!--  &lt;!&ndash;js&ndash;&gt;-->
  <!--  <script src="js/jquery-2.1.1.min.js"></script>-->
  <!--  &lt;!&ndash;icons-css&ndash;&gt;-->
  <!--  <link href="src/assets/css/font-awesome.css" rel="stylesheet">-->
  <!--Google Fonts-->
  <link href='//fonts.googleapis.com/css?family=Carrois+Gothic' rel='stylesheet' type='text/css'>
  <link href='//fonts.googleapis.com/css?family=Work+Sans:400,500,600' rel='stylesheet' type='text/css'>
  <!--static chart-->
  <!--  <script src="js/Chart.min.js"></script>-->
  <!--//charts-->
  <!-- geo chart -->
  <script src="//cdn.jsdelivr.net/modernizr/2.8.3/modernizr.min.js" type="text/javascript"></script>
  <script>window.modernizr || document.write('<script src="lib/modernizr/modernizr-custom.js"><\/script>')</script>


</head>
<body>
<div class="page-container">
  <div class="left-content">
    <div class="mother-grid-inner">
      <!--header start here-->
      <app-admin-header></app-admin-header>

      <!--heder end here-->
      <!-- script-for sticky-nav -->
      <script>
		$(document).ready(function() {
			 var navoffeset=$(".header-main").offset().top;
			 $(window).scroll(function(){
				var scrollpos=$(window).scrollTop();
				if(scrollpos >=navoffeset){
					$(".header-main").addClass("fixed");
				}else{
					$(".header-main").removeClass("fixed");
				}
			 });

		});
		</script>
      <!-- /script-for sticky-nav -->
      <!--inner block start here-->
      <div class="inner-block">
        <!--market updates updates-->


        <!--market updates end here-->
        <!--mainpage chit-chating-->

        <!--main page chit chating end here-->
        <!--main page chart start here-->
        <div class="main-page-charts">
          <div class="main-page-chart-layer1">
            <div class="col-md-5 chit-chat-layer1-left">
              <div class="work-progres">
                <div class="chit-chat-heading">
                  Add New Request
                </div>
                <div class="container">
                  <form [formGroup]="form"
                        name="form"
                        (ngSubmit)="onSubmit()" id="contact" action="" method="post">

                    <div class="card col-md-12" >
                      <div class="card-body">




                        <div class="row">
                          <div class="col-sm-12" formArrayName="itemRows">
                            <table class="table">
                              <thead>
                              <tr>
                                <th>Title </th>
                                <th>Description</th>

                              </tr>
                              </thead>
                              <tbody>
                              <tr *ngFor="let itemrow of form.controls.itemRows['controls']; let i=index" [formGroupName]="i">


<!--                                <td> <input type="text" id="learn{{i}}" formControlName="quantity" class="form-control reset"></td>-->
<!--                                <td class="col-md-9"> <textarea type="text"  formControlName="unit" class="form-control reset"></textarea></td>-->
                                                                <td> <textarea formControlName="Title" class="form-control reset"></textarea></td>
                                                                <td> <textarea formControlName="Description" class="form-control reset"></textarea></td>
                                <td> <button *ngIf="form.controls.itemRows['controls'].length > 1" (click)="deleteRow(i)" class="btn btn-danger"><i class="fa fa-minus-circle" aria-hidden="true"></i></button></td>
                              </tr>
                              </tbody>
                              <tfoot>
                              <tr><td> <button type="button" (click)="addNewRow()" class="btn btn-primary"><i class="fa fa-plus-circle" aria-hidden="true"></i></button></td></tr>
                              </tfoot>
                            </table>
                          </div>

                        </div>


                      </div>
                      <div class="card-footer">
                        <button  >Submit</button>
                      </div>
                    </div>

                  </form>


                </div>
              </div>
            </div>

          </div>



          <div class="clearfix"> </div>
          <div class="col-md-12 chit-chat-layer1-left">
            <div class="work-progres">
              <div class="chit-chat-heading">
                Terms & Conditions
              </div>

              <div class="table-responsive">
                <table class="table table-hover">
                  <thead>
                  <tr>
                    <th>SN</th>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let term of terms|paginate: { itemsPerPage: 5, currentPage: p }; let i = index">
                    <td>{{i+1}}</td>
                    <td>{{term.Title}}</td>
                    <td>{{term.Description}}</td>

                  <td><span class="label label-danger" (click)="delete(term .id)">Delete</span></td>
                  </tr>



                  </tbody>

                </table>
                <pagination-controls (pageChange)="p = $event" class="row m-auto justify-content-center"></pagination-controls>

              </div>

            </div>
          </div>
        </div>
        <div class="chart-layer-2">






          <div class="clearfix"> </div>
        </div>
      </div>
      <!--inner block end here-->
      <!--copy rights start here-->
      <div class="copyrights">
        <p>© 2020 Lmaniya LLC. All Rights Reserved | <a href="http://lmaniya.org/" target="_blank">Visit</a> </p>
      </div>
      <!--COPY rights end here-->
    </div>
  </div>
  <!--slider menu-->

  <div class="sidebar-menu">
    <div class="logo"> <div  class="sidebar-icon"> <span class="fa fa-bars"></span> </div> <a > <span id="logo" ></span>
      <!--<img id="logo" src="" alt="Logo"/>-->
    </a> </div>
    <div class="menu">
      <ul id="menu" >
        <li id="menu-home" ><a routerLink="/admin/home"><i class="fa fa-tachometer"></i><span>{{'admin.dashboard'|translate}}</span></a></li>
        <li><a><i class="fa fa-book"></i><span>{{'admin.products'|translate}}</span><span class="fa fa-angle-right" style="float: right"></span></a>
          <ul>
            <li><a routerLink="/admin/home/create-category">{{'admin.add-new-category'|translate}}</a></li>
            <li><a routerLink="/admin/home/create-sub-category">{{'admin.add-new-sub-category'|translate}}</a></li>
            <li><a routerLink="/admin/home/create-product">{{'admin.add-new-product'|translate}}</a></li>

          </ul>
        </li>

        <li id="menu-comunicacao" ><a ><i class="fa fa-users nav_icon"></i><span>{{'admin.vendor'|translate}}</span><span class="fa fa-angle-right" style="float: right"></span></a>
          <ul id="menu-comunicacao-sub" >
            <!--          <li id="menu-mensagens" style="width: 120px" ><a href="buttons.html">Vendor List</a>-->
            <!--          </li>-->
            <li id="menu-arquivos" ><a routerLink="/admin/home/create-vendor">{{'admin.add-vendor'|translate}}</a></li>
          </ul>
        </li>


        <!--      <li id="menu-academico" ><a ><i class="fa fa-user"></i><span>Users</span><span class="fa fa-angle-right" style="float: right"></span></a>-->
        <!--        <ul  >-->
        <!--          <li ><a href="login.html">Manage Users</a></li>-->
        <!--        </ul>-->
        <!--      </li>-->

        <!--      <li><a ><i class="fa fa-envelope"></i><span>Orders</span><span class="fa fa-angle-right" style="float: right"></span></a>-->
        <!--        <ul >-->
        <!--          <li ><a href="inbox.html">New Order Requests</a></li>-->
        <!--          <li ><a href="inbox-details.html">Order List</a></li>-->
        <!--        </ul>-->
        <!--      </li>-->

        <li><a><i class="fa fa-cog"></i><span>{{'admin.system'|translate}}</span><span class="fa fa-angle-right" style="float: right"></span></a>
          <ul>
            <li><a (click)="logout()">{{'admin.logout'|translate}}</a></li>
            <li><a routerLink="/admin/home/change-password">{{'admin.change-password'|translate}}</a></li>
            <li><a routerLink="/admin/home/terms-conditions">{{'admin.terms-and-conditions'|translate}}</a></li>
            <li data-toggle="modal" data-target="#modalLoginForm"><a >{{'admin.about-us'|translate}}</a></li>
          </ul>
        </li>
        <li routerLink="admin/business/total"><a><i class="fa fa-cog"></i><span>Total Business</span></a></li>

        <!--      <li><a ><i class="fa fa-shopping-cart"></i><span>E-Commerce</span><span class="fa fa-angle-right" style="float: right"></span></a>-->
        <!--        <ul id="menu-academico-sub" >-->
        <!--          <li id="menu-academico-avaliacoes" ><a href="product.html">Product</a></li>-->
        <!--          <li id="menu-academico-boletim" ><a href="price.html">Price</a></li>-->
        <!--        </ul>-->
        <!--      </li>-->
      </ul>
    </div>
  </div>


  <div class="clearfix"> </div>
</div>
<!-- mother grid end here-->
</body>

</html>
