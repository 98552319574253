import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HttpHeaders} from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class SearchdataService {
  Url: string;

  constructor(private http : HttpClient) {
   }

  searhhdata(model : any){
//     debugger;
   return this.http.post<any>('http://djpashupati.com/djpashupati/api/objects/total_business.php',model);
  }
  showdata(){
//     debugger;
   return this.http.get<any>('http://localhost:1141/Api/Searchdata/showdata');
  }
}
