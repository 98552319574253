import { Component, OnInit } from '@angular/core';
import {ActivatedRoute } from '@angular/router';
import {ProductService} from '../services/product.service';
import {CartService} from '../services/cart.service';
import {Product} from '../common/product';
import {Cart} from '../common/cart';
import { Location } from '@angular/common';

import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent implements OnInit {
products: Product[];
loader:boolean=true;
  constructor(      private location: Location,
private http:HttpClient,private _cartService: CartService, private _productService: ProductService,private _activatedRoute:ActivatedRoute,) { }

  ngOnInit() {
   this._activatedRoute.paramMap.subscribe(()=>{
   this.getProductInfo();
   })

  }
 goPrevious(){
  this.location.back();
  }

  getProductInfo(){
  const id: number=+this._activatedRoute.snapshot.paramMap.get('id');
   var formData: any = new FormData();
          formData.append("Product_id", id);
return this.http.post<any>('http://djpashupati.com/Djpashupati_Api/Product_detail.php', formData).subscribe(data =>{this.products=data; this.loader=false;console.log(this.products);});
  }


addToCart(product: Product){
console.log(product);
// console.log(`product name: ${product.productName}, and price: ${product.finalPrice}`)
const userId=localStorage.getItem("user_id");
const orderId=localStorage.getItem("orderId");

const cartItem=new Cart(product, userId);

 var formData: any = new FormData();
        formData.append("Product_name", cartItem.Product_name);
        formData.append("Product_price", cartItem.Product_price);
        formData.append("Product_quantity", cartItem.Product_quantity);
        formData.append("users_id", userId);
        formData.append("Product_id", cartItem.Product_id);
        formData.append("Product_image", cartItem.Product_image);
       // formData.append("Total_price", cartItem.Product_price);
        formData.append("Wt_unit", cartItem.Wt_unit);

console.log(formData);
let orderedItems = JSON.stringify(cartItem);
console.log(orderedItems);

this.http.post<any>('http://djpashupati.com/djpashupati/api/order/place-order.php', orderedItems).subscribe(data =>console.log(data));

// let userId=localStorage.getItem("user_id");
this._cartService.addToCart(cartItem);
return this.ngOnInit();
}

}
