<app-admin-header></app-admin-header>
<div class="container contact-form" >
  <div class="contact-image">
    <img src="https://image.ibb.co/kUagtU/rocket_contact.png" alt="rocket_contact"/>
  </div>
  <form   [formGroup]="form"
          name="form"
          (ngSubmit)="onSubmit()">
    <h3>Drop Us a Message</h3>
    <div class="row">
      <div class="col-md-6">

        <div class="form-group">
          <select class="form-control" formControlName="email" autocomplete   [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
            <option value=''>Choose Customer</option>
            <option value='{{email.email}}'  *ngFor="let email of emails" >{{email.name}}</option>
          </select>

<!--          <input type="text" name="txtEmail" class="form-control" formControlName="email" placeholder="Destination Email *" value="" />-->
          <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors.required">Email is required</div>
            <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
          </div>
        </div>
        <div class="form-group">
          <input type="text" name="txtPhone" class="form-control" formControlName="subject" placeholder="Subject *" value="" autocomplete  [ngClass]="{ 'is-invalid': submitted && f.subject.errors }" />
          <div *ngIf="submitted && f.subject.errors" class="invalid-feedback">
            <div *ngIf="f.subject.errors.required">Subject is required</div>
          </div>
        </div>

        <div class="form-group">
          <input type="submit" name="btnSubmit"  class="btnContact" value="Send Message" />

        </div>
        <div *ngIf="msg!=''" class="alert alert-success" role="alert">
          {{msg}}
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">

          <textarea name="txtMsg" class="form-control" formControlName="message" placeholder="Your Message *" style="width: 100%; height: 150px;" autocomplete  [ngClass]="{ 'is-invalid': submitted && f.message.errors }"></textarea>
          <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
            <div *ngIf="f.message.errors.required">Message is required</div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
