
<!DOCTYPE HTML>
<html>
<head>
  <title>DjPashupati:Admin Panel</title>
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  <meta name="keywords" content="DjPashupati Online shopping" />
  <script type="application/x-javascript"> addEventListener("load", function() { setTimeout(hideURLbar, 0); }, false); function hideURLbar(){ window.scrollTo(0,1); } </script>

  <link href='//fonts.googleapis.com/css?family=Carrois+Gothic' rel='stylesheet' type='text/css'>
  <link href='//fonts.googleapis.com/css?family=Work+Sans:400,500,600' rel='stylesheet' type='text/css'>
  <!--static chart-->
<!--  <script src="js/Chart.min.js"></script>-->
  <!--//charts-->
  <!-- geo chart -->
  <script src="//cdn.jsdelivr.net/modernizr/2.8.3/modernizr.min.js" type="text/javascript"></script>
  <script>window.modernizr || document.write('<script src="lib/modernizr/modernizr-custom.js"><\/script>')</script>


</head>
<body>
<app-about-us-create></app-about-us-create>
<div class="page-container">
  <div class="left-content">
    <div class="mother-grid-inner">
      <!--header start here-->
      <app-admin-header></app-admin-header>
      <!--heder end here-->
      <!-- script-for sticky-nav -->
      <script>
		$(document).ready(function() {
			 var navoffeset=$(".header-main").offset().top;
			 $(window).scroll(function(){
				var scrollpos=$(window).scrollTop();
				if(scrollpos >=navoffeset){
					$(".header-main").addClass("fixed");
				}else{
					$(".header-main").removeClass("fixed");
				}
			 });

		});
		</script>
      <!-- /script-for sticky-nav -->
      <!--inner block start here-->
      <div class="inner-block">
        <!--market updates updates-->
        <div class="market-updates">

          <div class="col-md-4 market-update-gd">
            <div class="market-update-block clr-block-2">
              <div class="col-md-8 market-update-left">
                <h3>135</h3>
                <h4>Daily Users</h4>
              </div>
              <div class="col-md-4 market-update-right">
                <i class="fa fa-eye"> </i>
              </div>
              <div class="clearfix"> </div>
            </div>
          </div>
          <div class="col-md-4 market-update-gd">
            <div class="market-update-block clr-block-3" routerLink="/admin/email">
              <div class="col-md-8 market-update-left">

                <h4>{{'admin.send-mail'|translate}}</h4>
              </div>
              <div class="col-md-4 market-update-right">
                <i class="fa fa-envelope-o"> </i>
              </div>
              <div class="clearfix"> </div>
            </div>
          </div>
          <div class="col-md-4 market-update-gd">
            <div class="market-update-block clr-block-3" routerLink="/admin/orders/today">
              <div class="col-md-8 market-update-left">

                <h4>Today Orders</h4>
              </div>
              <div class="col-md-4 market-update-right">
                <i class="fa fa-envelope-o"> </i>
              </div>
              <div class="clearfix"> </div>
            </div>
          </div>
          <div class="clearfix"> </div>

        </div>
        <!--market updates end here-->
        <!--mainpage chit-chating-->
        <div class="chit-chat-layer1">
          <div class="col-md-8 chit-chat-layer1-left">
            <div class="work-progres">
              <div class="chit-chat-heading">
                {{'admin.recent-order'|translate}}
              </div>
              <input type="search"
                     class="form-control"
                     placeholder="Search Orders.."
                     [(ngModel)]="term">
              <div class="table-responsive">
                <table class="table table-hover">
                  <thead>
                  <tr>
                    <th>{{'admin.sn'|translate}}</th>
                    <th>{{'admin.bill-number'|translate}}</th>
                    <th>{{'admin.customer-name'|translate}}</th>
                    <th>{{'admin.product-name'|translate}}</th>
                    <th>{{'admin.customer-address'|translate}}</th>
                    <th>{{'admin.customer-contact'|translate}}</th>
                    <th>{{'admin.order-date'|translate}}</th>
                    <th>{{'admin.status'|translate}}</th>
                    <th>{{'admin.action'|translate}}</th>

                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let order of orders|filter:term|paginate: {id:'customerOrder', itemsPerPage: 10, currentPage: q }; let i = index">
                    <td>{{i+1}}</td>
                    <td>{{order.Bill_number}}</td>
                    <td>{{order.Customer_name}}</td>

                    <td>{{order.Product_name}}</td>
                    <td>{{order.Customer_address}}</td>
                    <td>{{order.Customer_phone}}</td>
                    <td>{{order.Checkout_date}}</td>
                    <td>{{order.Order_status}}</td>


                    <td><span class="label label-success" *ngIf="order.Order_status=='Pending'" (click)="shipOrder(order.Order_id)">Ship</span></td>

                    <td><span class="label label-danger" (click)="delete(order.Order_id)">Delete</span>
                    <td><span class="label label-danger" (click)="print(order.Bill_number)">Print</span>
                    </td>
                  </tr>

                  </tbody>
                </table>
                <pagination-controls id="customerOrder" (pageChange)="q = $event" class="row m-auto justify-content-center"></pagination-controls>

              </div>
            </div>
          </div>


          <div class="col-md-4 chit-chat-layer1-left">
            <div class="work-progres">
              <div class="chit-chat-heading">
                {{'admin.my-vendors'|translate}}
              </div>
              <input type="search"
                     class="form-control"
                     placeholder="Search Vendors.."
                     [(ngModel)]="vendor">
              <div class="table-responsive">
                <table class="table table-hover">
                  <thead>
                  <tr>
                    <th>{{'admin.sn'|translate}}</th>
                    <th>{{'admin.vendor-id'|translate}}</th>
                    <th>{{'admin.vendor-name'|translate}}</th>
                    <th>{{'admin.vendor-address'|translate}}</th>
                    <th>{{'admin.vendor-contact'|translate}}</th>

                    <th>{{'admin.status'|translate}}</th>
                    <th>{{'admin.action'|translate}}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let vendor of vendors|filter:vendor|paginate: {id:'vendors', itemsPerPage: 5, currentPage: r }; let i = index">
                    <td >{{i+1}}</td>
                    <td>{{vendor.id}}</td>
                    <td>{{vendor.vendor_name}}</td>
                    <td>{{vendor.vendor_address}}</td>
                    <td>{{vendor.vendor_contact}}</td>
                    <td><span class="label label-success">{{vendor.status}}</span>
                               <td><span class="label label-danger" (click)="deleteVendor(vendor .id)">Delete</span></td>
                               <td><span class="label label-success" routerLink="/admin/home/request-vendor"> Request Order</span></td>

                  </tr>


                  </tbody>
                </table>
                <pagination-controls id="vendors" (pageChange)="r = $event" class="row m-auto justify-content-center"></pagination-controls>

              </div>
            </div>
          </div>
          <div class="clearfix"> </div>
        </div>
        <!--main page chit chating end here-->
        <!--main page chart start here-->
        <div class="main-page-charts">
          <div class="main-page-chart-layer1">
            <div class="col-md-6 chit-chat-layer1-left">
              <div class="work-progres">
                <div class="chit-chat-heading">
                  {{'admin.vendor-order-status'|translate}}
                </div>
                <input type="search"
                       class="form-control"
                       placeholder="Search Vendor Order.."
                       [(ngModel)]="vorder">
                <div class="table-responsive">
                  <table class="table table-hover">
                    <thead>
                    <tr>
                      <th>{{'admin.sn'|translate}}</th>
                      <th>{{'admin.vendor-name'|translate}}</th>
                      <th>{{'admin.product-name'|translate}}</th>
                      <th>{{'admin.quantity'|translate}}</th>
                      <th>{{'admin.order-date'|translate}}</th>
                      <th>{{'admin.status'|translate}}</th>
                      <th>{{'admin.action'|translate}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let vOrder of vOrders|filter:vorder|paginate: {id:'vendorOrder', itemsPerPage: 5, currentPage: s }; let i = index">
                      <td>{{i+1}}</td>
                      <td>{{vOrder.vendor_name}}</td>
                      <td>{{vOrder.product_name}}</td>
                      <td>{{vOrder.quantity}}</td>
                      <td>{{vOrder.date}}</td>
                      <td>{{vOrder.status}}</td>

                      <td><span class="label label-danger" (click)="deleteV(vOrder.id)">Delete</span>
                      </td>
                    </tr>



                    </tbody>
                  </table>
                  <pagination-controls id="vendorOrder" (pageChange)="s = $event" class="row m-auto justify-content-center"></pagination-controls>

                </div>
              </div>
            </div>
            <div class="col-md-6 chit-chat-layer1-left">
              <div class="work-progres">

                <div class="chit-chat-heading">
                  {{'admin.customer'|translate}}
                </div>
                <input type="search"
                       class="form-control"
                       placeholder="Search Customer.."
                       [(ngModel)]="customer">
                <div class="table-responsive">
                  <table class="table table-hover">
                    <thead>
                    <tr>
                      <th>{{'admin.sn'|translate}}</th>
                      <th>{{'admin.customer-id'|translate}}</th>
                      <th>{{'admin.customer-name'|translate}}</th>
                      <th>{{'admin.email'|translate}}</th>
                      <th>{{'admin.joined-date'|translate}}</th>
                      <th>{{'admin.status'|translate}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let user of users|filter:customer|paginate: {id:'customer', itemsPerPage: 5, currentPage: p }; let i = index">
                      <td>{{i+1}}</td>
                      <td>{{user.unique_id}}</td>
                      <td>{{user.name}}</td>
                      <td>{{user.email}}</td>
                      <td>{{user.created_at}}</td>
                      <td *ngIf="user.verified==1"><span class="label label-success">Active</span>
                      </td>
                      <td *ngIf="user.verified==0"><span class="label label-danger">Inactive</span>
                      </td>
                    </tr>



                    </tbody>

                  </table>
                  <pagination-controls id="customer" (pageChange)="p = $event" class="row m-auto justify-content-center"></pagination-controls>

                </div>

              </div>
            </div>
          </div>
          <div class="clearfix"> </div>

        </div>
        <!--main page chart layer2-->
        <div class="chart-layer-2">
          <div class="clearfix"> </div>
        </div>

        <!--climate start here-->
        <div class="climate">

          <div class="col-md-4 climate-grids">


          </div>
          <div class="col-md-4 climate-grids">
            <div class="climate-grid3">
              <div class="popular-brand">
                <div class="col-md-6 popular-bran-left">
                  <h3>Most Popular</h3>
                  <h4>Product of this month</h4>
                  <p>Chappochatai</p>
                </div>
                <div class="col-md-6 popular-bran-right">
                  <h3>CCP</h3>
                </div>
                <div class="clearfix"> </div>
              </div>

            </div>
          </div>
          <div class="clearfix"> </div>
        </div>
        <!--climate end here-->
      </div>
      <!--inner block end here-->
      <!--copy rights start here-->
      <div class="copyrights">
        <p>© 2020 Lmaniya LLC. All Rights Reserved | <a href="http://lmaniya.org/" target="_blank">Visit</a> </p>
      </div>
      <!--COPY rights end here-->
    </div>
  </div>
  <!--slider menu-->

  <div class="sidebar-menu">
    <div class="logo"> <div  class="sidebar-icon"> <span class="fa fa-bars"></span> </div> <a > <span id="logo" ></span>
      <!--<img id="logo" src="" alt="Logo"/>-->
    </a> </div>
    <div class="menu">
      <ul id="menu" >
        <li id="menu-home" ><a routerLink="/admin/home"><i class="fa fa-tachometer"></i><span>{{'admin.dashboard'|translate}}</span></a></li>
        <li><a><i class="fa fa-book"></i><span>{{'admin.products'|translate}}</span><span class="fa fa-angle-right" style="float: right"></span></a>
          <ul>
            <li><a routerLink="/admin/home/create-category">{{'admin.add-new-category'|translate}}</a></li>
            <li><a routerLink="/admin/home/create-sub-category">{{'admin.add-new-sub-category'|translate}}</a></li>
            <li><a routerLink="/admin/home/create-product">{{'admin.add-new-product'|translate}}</a></li>

          </ul>
        </li>

        <li id="menu-comunicacao" ><a ><i class="fa fa-users nav_icon"></i><span>{{'admin.vendor'|translate}}</span><span class="fa fa-angle-right" style="float: right"></span></a>
          <ul id="menu-comunicacao-sub" >
            <!--          <li id="menu-mensagens" style="width: 120px" ><a href="buttons.html">Vendor List</a>-->
            <!--          </li>-->
            <li id="menu-arquivos" ><a routerLink="/admin/home/create-vendor">{{'admin.add-vendor'|translate}}</a></li>
          </ul>
        </li>


        <!--      <li id="menu-academico" ><a ><i class="fa fa-user"></i><span>Users</span><span class="fa fa-angle-right" style="float: right"></span></a>-->
        <!--        <ul  >-->
        <!--          <li ><a href="login.html">Manage Users</a></li>-->
        <!--        </ul>-->
        <!--      </li>-->

        <!--      <li><a ><i class="fa fa-envelope"></i><span>Orders</span><span class="fa fa-angle-right" style="float: right"></span></a>-->
        <!--        <ul >-->
        <!--          <li ><a href="inbox.html">New Order Requests</a></li>-->
        <!--          <li ><a href="inbox-details.html">Order List</a></li>-->
        <!--        </ul>-->
        <!--      </li>-->

        <li><a><i class="fa fa-cog"></i><span>{{'admin.system'|translate}}</span><span class="fa fa-angle-right" style="float: right"></span></a>
          <ul>
            <li><a (click)="logout()">{{'admin.logout'|translate}}</a></li>
                      <li><a routerLink="/admin/home/change-password">{{'admin.change-password'|translate}}</a></li>
                      <li><a routerLink="/admin/home/terms-conditions">{{'admin.terms-and-conditions'|translate}}</a></li>
                      <li data-toggle="modal" data-target="#modalLoginForm"><a >{{'admin.about-us'|translate}}</a></li>
          </ul>
        </li>
        <li routerLink="/admin/business/total"><a><i class="fa fa-cog"></i><span>Total Business</span></a></li>


      </ul>
    </div>
  </div>


  <!--  close slider menu-->

  <div class="clearfix"> </div>
</div>
<!-- mother grid end here-->
</body>
</html>
