import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router } from '@angular/router';
import {ProductService} from 'src/app/services/product.service';
import {Product} from 'src/app/common/product';
import {Order} from 'src/app/common/order';
import {Vendor} from 'src/app/common/vendor';
// import {MatDialogsModule} from '@angular-material-extensions/core';
// import {MdDialog} from "@angular/material";
// import {MdDialogRef} from "@angular/material";
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {

order_id: string='';
products: Product[];
orders:Order[];
vendors: Vendor[];
users:[];
  p: number = 1;

  constructor(private _productService: ProductService,
                private _activatedRoute:ActivatedRoute,
                 private http: HttpClient,
                   private router :Router,
) { }


  ngOnInit() {
  this.listVendorOrderDetails();
  }

  listVendorOrderDetails(){
    let order_id=this._activatedRoute.snapshot.paramMap.get('order_id');
    this.order_id=order_id;
    let vendor_id=1;
//     console.log(vendor_id);
//     console.log(order_id);
//     this._productService.getVendorOrderDetail(escape(order_id), vendor_id).subscribe(data=>this.orders=data);

  }
shipOrderByVendor(order_id: string){}
cancelOrderByVendor(order_id: string){}
}
