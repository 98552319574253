import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import {CartService} from '../services/cart.service';
import {Cart} from '../common/cart';
import {Order} from '../common/order';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-bill',
  templateUrl: './bill.component.html',
  styleUrls: ['./bill.component.scss']
})
export class BillComponent implements OnInit {
cartItems: Cart[]=[];
totalPrice: number=0;
orderId:string='';
totalQuantity: number=0;
user_name: string='';
user_email: string='';
currentDate = new Date();
  constructor(private _fb: FormBuilder,
                            private _cartService: CartService,
                             private http: HttpClient,
                             private router: Router,
                             private _activatedRoute:ActivatedRoute,
) { }

  ngOnInit() {
    this.user_name=localStorage.getItem("user_name");
    this.user_email=localStorage.getItem("user_email");

    this.cartDetails();

  }

 cartDetails(){


this._activatedRoute.paramMap.subscribe(params=>{
                   const categoryId=params.get('id');
                  this.orderId=categoryId;
                      if(categoryId){


                                  var formData:any=new FormData();

                                         formData.append("Bill_number",categoryId);

//                                             console.log(...formData);
                                            this.http.post<any>('http://djpashupati.com/Djpashupati_Api/bill.php', formData)
                                            .subscribe((data)=>{this.cartItems=data;
                                            console.log(data);
                                            this._cartService.calculateTotalPrice();
                                            },
                                            (error: any)=>console.log(error));
                              }

                });


//
// //   this.cartItems=this._cartService.cartItems;
//     var formData2: any=new FormData();
//      this.orderId = localStorage.getItem("bill_number");
//      console.log(this.orderId)
//         formData2.append("Bill_number", localStorage.getItem("bill_number"));
// this.http.post<any>('',formData2).subscribe(data=>this.cartItems=data);
//
// //   this._cartService.totalPrice.subscribe(
// //   data=> this.totalPrice=data);
//
//   this._cartService.totalQuantity.subscribe(
//   data=> this.totalQuantity=data);
//
//
//   this._cartService.calculateTotalPrice();
// //   return this.ngOnInit();

  }

total(bills) {
            var total = 0;
            bills.forEach(element => {
total = total + (element.Product_quantity*element.Product_price);
            });
            return total;        }

            print(cmpName) {
                 let printContents = document.getElementById(cmpName).innerHTML;
                 let originalContents = document.body.innerHTML;

                 document.body.innerHTML = printContents;

                 window.print();

                 document.body.innerHTML = originalContents;
            }

}
