import { Component, OnInit } from '@angular/core';
import {ProductCategory} from 'src/app/common/product-category';
import {ProductSubCategory} from 'src/app/common/product-sub-category';
import { FormBuilder, FormGroup,Validators } from "@angular/forms";
import { HttpClient } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import {ProductService} from 'src/app/services/product.service';
import {Product} from 'src/app/common/product';
@Component({
  selector: 'app-create-category',
  templateUrl: './create-category.component.html',
  styleUrls: ['./create-category.component.scss']
})
export class CreateCategoryComponent implements OnInit {
categories: ProductCategory[];
category: ProductCategory;
form: FormGroup;
  p: number = 1;
cat:string;
error:any=[];
selectedFile: File;
submitted=false;
loader=false;

  constructor(private _productService: ProductService,
                private _activatedRoute:ActivatedRoute,
                public fb:FormBuilder,
                private router:Router,
                private http:HttpClient,
                ) {




                }
    get f() { return this.form.controls; }

        ngOnInit() {


             this.form=this.fb.group({
                  category_name:['',Validators.required],
                  image_url:['']

                  });

  let q= localStorage.getItem("isAdminLoggedin");
  if(q!='true'){
  this.router.navigateByUrl('/admin/index');
  }

var toggle = true;

$(".sidebar-icon").click(function() {
  if (toggle)
  {
    $(".page-container").addClass("sidebar-collapsed").removeClass("sidebar-collapsed-back");
    $("#menu span").css({"position":"absolute"});
  }
  else
  {
    $(".page-container").removeClass("sidebar-collapsed").addClass("sidebar-collapsed-back");
    setTimeout(function() {
      $("#menu span").css({"position":"relative"});
    }, 400);
  }
                toggle = !toggle;
            });

console.log("refreshed");
// list the products available in table
//   this.listProducts();
this.listCategory();


this._activatedRoute.paramMap.subscribe(params=>{
const categoryId=+params.get('id');
if(categoryId){
this.getCat(categoryId);
}
});

  }

  getCat(id: number){
this._productService.getCategory(id).subscribe(
(cats: ProductCategory)=>{this.editCategory(cats);
this.category=cats;
},
(err:any)=>console.log(err)
);

  }


editCategory(cats: ProductCategory){
console.log(cats);

this.form.patchValue({
category_name: cats[0].Category_name,
});

}


  listCategory(){
    this._productService.getCategoryAll().subscribe(data =>{this.categories=data;
    console.log(this.categories);
    },
    error=>{
    console.error(error);
    }
    );

    }

 onFileChanged(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form.get('image_url').setValue(file);
    }
  }
MapFormValueToCategoryModel(){
this.category.Category_name=this.form.value.category_name;
}
  onSubmit(){

           this.submitted = true;
             if (this.form.invalid) {
                      return;
              }else{
                 this._activatedRoute.paramMap.subscribe(params=>{
                   const categoryId=+params.get('id');
                      if(categoryId){
                          this.MapFormValueToCategoryModel();
                              if(this.category[0].Category_id){

                                  var formData:any=new FormData();
                                        formData.append("Category_name",this.form.get('category_name').value);
                                         formData.append("Category_id",this.category[0].Category_id);
                                           formData.append("Image",this.form.get('image_url').value);
//                                             console.log(...formData);
                                            this.http.post<any>('http://djpashupati.com/djpashupati/api/category/update.php', formData).subscribe(()=>this.router.navigate(['/admin/home/create-category']),(error: any)=>this.router.navigate(['/admin/home/create-category']));
                              }
                      }
                });

            var formData:any=new FormData();
            formData.append("Category_name",this.form.get('category_name').value);

              formData.append("Image",this.form.get('image_url').value);


                  this.http.post<ProductCategory[]>('http://djpashupati.com/djpashupati/api/category/add-category.php',formData)
                      .subscribe(()=>{ this.submitted = false;
                                                                         this.listCategory();
                                                                         this.form.reset();
                                       },
                                   (error: any)=>{
                                   this.submitted = false;
                                   this.listCategory();
                                   this.form.reset();
                                   });


           }


  }



  delete(id){
  var detail={id: id};


this.http.post('http://djpashupati.com/djpashupati/api/category/delete.php',detail)
.subscribe();
this.listCategory();
return this.router.navigateByUrl('/admin/home/create-category');


  console.log(detail);
  }



logout(){
localStorage.setItem('isAdminLoggedin',"false");
this.router.navigateByUrl('/admin/index');

}


edit(categoryId:number){
this.router.navigate(['/admin/home/edit-category', categoryId]);


}


}
