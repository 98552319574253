<app-header></app-header>
<header>
  <nav><i class="mdi mdi-chevron-double-left"></i><a (click)="goPrevious()">Go Back</a></nav>
</header>
<div class="container">
  <div class="text-center">
    <circle-progress *ngIf="loader"
                     [percent]="100"
                     [radius]="100"
                     [subtitle]="'Loading...'"
                     [outerStrokeWidth]="16"
                     [innerStrokeWidth]="8"
                     [outerStrokeColor]="'#bc2624'"
                     [innerStrokeColor]="'#C7E596'"
                     [animation]="true"
                     [animationDuration]="10000"
    ></circle-progress>


  </div>
  <div class="card" *ngFor="let product of products">
    <div class="container-fliud">
      <div class="wrapper row">
        <div class="preview col-md-6">

          <div class="preview-pic tab-content">
            <div class="tab-pane active" id="pic-1"><img [src]="product.Image" /></div>

          </div>


        </div>
        <div class="details col-md-6">
          <h3 class="product-title">{{product.Product_name}}</h3>
          <h3 class="product-title">{{product.Description}}</h3>
<!--          <div class="rating">-->
<!--            <div class="stars">-->
<!--              <span class="fa fa-star checked"></span>-->
<!--              <span class="fa fa-star checked"></span>-->
<!--              <span class="fa fa-star checked"></span>-->
<!--              <span class="fa fa-star"></span>-->
<!--              <span class="fa fa-star"></span>-->
<!--            </div>-->
<!--            <span class="review-no">41 reviews</span>-->
<!--          </div>-->
<!--          <p class="product-description">{{product.product_description}}</p>-->
          <h4 class="price"> <span>{{product.Final_price| currency: 'NRs.'}}</span></h4>
          <h4 class="price"> <s>{{product.Initial_Price| currency: 'NRs.'}}</s></h4>
<!--          <p class="vote"><strong>91%</strong> of buyers enjoyed this product! <strong>(87 votes)</strong></p>-->


          <div class="action">
            <button class="add-to-cart btn btn-default" type="button" (click)="addToCart(product)">add to cart</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
