import { Component, OnInit } from '@angular/core';
import {ActivatedRoute } from '@angular/router';
import {ProductService} from 'src/app/services/product.service';
import {Product} from 'src/app/common/product';
import {Vendor} from 'src/app/common/vendor';
import {VendorRequest} from 'src/app/common/vendor-request';

import { FormBuilder,FormArray, FormGroup } from "@angular/forms";
import { HttpClient } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
@Component({
  selector: 'app-request-vendor',
  templateUrl: './request-vendor.component.html',
  styleUrls: ['./request-vendor.component.scss']
})
export class RequestVendorComponent implements OnInit {
  public addmore: FormGroup;

products: Product[];
vendors: Vendor[];
  p: number = 1;

vOrders: any=[];
vendor_name: string;
public form: FormGroup;
error:any=[];
  constructor(private _productService: ProductService,
                private _activatedRoute:ActivatedRoute,
                public fb:FormBuilder,
                private http:HttpClient,
                  private router:Router,

                ) {


                }

get formArr() {
    return this.form.get('itemRows') as FormArray;
  }
  ngOnInit() {

  let q= localStorage.getItem("isAdminLoggedin");
  if(q!='true'){
  this.router.navigateByUrl('/admin/index');
  }


this.form=this.fb.group({
    vendor_name:[''],
     itemRows: this.fb.array([this.initItemRows()])


                })
var toggle = true;

$(".sidebar-icon").click(function() {
  if (toggle)
  {
    $(".page-container").addClass("sidebar-collapsed").removeClass("sidebar-collapsed-back");
    $("#menu span").css({"position":"absolute"});
  }
  else
  {
    $(".page-container").removeClass("sidebar-collapsed").addClass("sidebar-collapsed-back");
    setTimeout(function() {
      $("#menu span").css({"position":"relative"});
    }, 400);
  }
                toggle = !toggle;
            });


// list the products available in table
  this.listProducts();
this.listVendors();
this.listVendorRequests();
  }

  listProducts(){
  this._productService.getProductAll().subscribe(data =>{this.products=data;
  console.log(this.products);
  },
  error=>{
  console.error(error);
  }
  );

  }

 initItemRows() {
//  let vendor_name=localStorage.getItem("vendor_name");
    return this.fb.group({
    product_name:[''],
    quantity:[''],
    vendor_name:this.vendor_name,
    unit:[''],

    });
  }
  addNewRow() {
    this.formArr.push(this.initItemRows());
  }

  deleteRow(index: number) {
    this.formArr.removeAt(index);
  }

  listVendors(){
    this._productService.getVendors().subscribe(data =>{this.vendors=data;
    console.log(this.vendors);
    },
    error=>{
    console.error(error);
    }
    );

    }



    listVendorRequests(){
      this._productService.getVendorRequests().subscribe(data =>{this.vOrders=data;
      console.log(data);
      },
      error=>{
      console.error(error);
      }
      );

      }


  onSubmit(){

//   console.log(this.form.value.itemRows);
// var formData:any=new FormData();
// formData.append("product_name",this.form.get('productName').value);
// formData.append("product_description",this.form.get('productDescription').value);
// formData.append("initial_price",this.form.get('initialPrice').value);
// formData.append("final_price",this.form.get('finalPrice').value);
// formData.append("product_image_url",this.form.get('imageUrl').value);
// formData.append("category_id",this.form.get('categoryId').value);
// formData.append("sub_category_id",this.form.get('subCategoryId').value);
//         let formObj = this.form.getRawValue(); // {name: '', description: ''}
//
// let serializedForm = JSON.stringify(formObj);
// // console.log(serializedForm);
 this.http.post<any>('http://djpashupati.com/djpashupati/api/vendor-request/vendor-request.php',this.form.value.itemRows).subscribe();
return this.ngOnInit();

  }
logout(){
localStorage.setItem('isAdminLoggedin',"false");
this.router.navigateByUrl('/admin/index');

}

changed(event){
this.vendor_name=event;
localStorage.setItem("vendor_name", event);
   console.log(event);
   this.ngOnInit();
}

delete(id){
console.log(id);
var formData:any=new FormData();
formData.append('id',id);


// console.log(...formData);
 this.http.post<any>('http://djpashupati.com/djpashupati/api/vendor-request/delete.php',formData).subscribe();
return this.ngOnInit();
}

}
