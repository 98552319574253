import { Component, OnInit } from '@angular/core';
import {ActivatedRoute } from '@angular/router';
import {ProductService} from '../services/product.service';
import {Product} from '../common/product';
import { Location } from '@angular/common';
import {HttpClient} from '@angular/common/http';

import {ProductSubCategory} from '../common/product-sub-category';
@Component({
  selector: 'app-sub-category',
  templateUrl: './sub-category.component.html',
  styleUrls: ['./sub-category.component.scss']
})
export class SubCategoryComponent implements OnInit {
productSubCategory: ProductSubCategory[];
currentCategoryId:number;
categoryName: string;
  p: number = 1;
loader:boolean=true;
  constructor(private _productService: ProductService,
   private location: Location,
   private http: HttpClient,
  private _activatedRoute:ActivatedRoute,
  ) { }

  ngOnInit() {
  this._activatedRoute.paramMap.subscribe(()=>{
  this.listProductSubCategory();
  })
  }

  goPrevious(){
  this.location.back();
  }
  listProductSubCategory(){
//
  const hasCategoryId: boolean=this._activatedRoute.snapshot.paramMap.has('id');
  if(hasCategoryId){
  this.currentCategoryId=+this._activatedRoute.snapshot.paramMap.get('id');
    this.categoryName=this._activatedRoute.snapshot.paramMap.get('name');

  }else{
  this.currentCategoryId=1;
  }
    var formData2: any=new FormData();
      formData2.append("Category_id", this.currentCategoryId);
this.http.post<any>('http://djpashupati.com/Djpashupati_Api/Subcategory.php', formData2).subscribe(data =>{this.productSubCategory=data;console.log(data); this.loader=false;})
  }

}
