<app-header></app-header>
<div id="" class="l_box">
  <span style="color:red;">{{error.message}}</span>
  <img class="logo " alt="logo" src="assets/images/djlogo.png" style="margin-left:42%;"/>
  <p class="signin" style="margin-left:32%; font-size:15px;">Forget Password</p>
  <!--  <p class="signin-text">Login with Twitter, Facebook,<br/> Google or:</p>-->
  <!--  <div class="signin-row">-->
  <!--    <i class="ic1 fa fa-twitter"></i>-->
  <!--    <i class="ic2 fa fa-facebook"></i>-->
  <!--    <i class="ic3 fas fa-google"></i>-->
  <!--  </div>-->
  <form action="" [formGroup]="form"  (ngSubmit)="onSubmit()" method="POST">

    <input name="email" id="user" autofocus="true" type="email" formControlName="email" placeholder="Enter Email" style="-webkit-appearance: none;
  -moz-appearance: none;
  background: none;
  border: 0;
  border-bottom: 2px solid #9da3a5;
  font: 500 0.9rem ;
  height: 2.15em;
  margin-top: 0.7em;
  transition: all 0.15s ease-out;
    margin-left:9%;
  -webkit-transition: all 0.15s ease-out;
  width: 80%;
">

    <br/>

    <button class="btn_sign" type="submit" style="margin-left: 23px;">Submit</button>

<!--    <p class="foot-txt" style="text-align: center;">{{'login.forget-password'|translate}}</p>-->
    <p  class="foot-txt"style="text-align: center;">{{'login.not-member'|translate}}<a routerLink="/register" class="bold" >{{'login.sign-up-now'|translate}}
    </a>
    </p>
  </form>
</div>
<app-footer></app-footer>
