import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-na',
  templateUrl: './na.component.html',
  styleUrls: ['./na.component.scss']
})
export class NaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
