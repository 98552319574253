

<!DOCTYPE HTML>
<html>
<head>
  <title>DjPashupati:Admin Panel</title>
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  <meta name="keywords" content="DjPashupati Online shopping" />
  <script type="application/x-javascript"> addEventListener("load", function() { setTimeout(hideURLbar, 0); }, false); function hideURLbar(){ window.scrollTo(0,1); } </script>

  <link href='//fonts.googleapis.com/css?family=Carrois+Gothic' rel='stylesheet' type='text/css'>
  <link href='//fonts.googleapis.com/css?family=Work+Sans:400,500,600' rel='stylesheet' type='text/css'>

  <script src="//cdn.jsdelivr.net/modernizr/2.8.3/modernizr.min.js" type="text/javascript"></script>
  <script>window.modernizr || document.write('<script src="lib/modernizr/modernizr-custom.js"><\/script>')</script>


</head>
<body>
<div class="page-container">
  <div class="left-content">
    <div class="mother-grid-inner">
      <!--header start here-->
      <app-admin-header></app-admin-header>

      <!--heder end here-->
      <!-- script-for sticky-nav -->
      <script>
		$(document).ready(function() {
			 var navoffeset=$(".header-main").offset().top;
			 $(window).scroll(function(){
				var scrollpos=$(window).scrollTop();
				if(scrollpos >=navoffeset){
					$(".header-main").addClass("fixed");
				}else{
					$(".header-main").removeClass("fixed");
				}
			 });

		});
		</script>
      <!-- /script-for sticky-nav -->
      <!--inner block start here-->
      <div class="inner-block">
        <!--market updates updates-->


        <!--market updates end here-->
        <!--mainpage chit-chating-->

        <!--main page chit chating end here-->
        <!--main page chart start here-->
        <div id="" class="l_box">
          <p class="signin" style="margin-left:32%;">Change Password</p>

          <form action="" [formGroup]="form"  (ngSubmit)="onSubmit()" method="POST">

            <input name="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" type="password" formControlName="admin_password" placeholder="Password" required  style="-webkit-appearance: none;
  -moz-appearance: none;
  background: none;
  border: 0;
  border-bottom: 2px solid #9da3a5;
  font: 500 0.9rem ;
  height: 2.15em;
  margin-top: 0.7em;
  margin-left:9%;
  transition: all 0.15s ease-out;
  -webkit-transition: all 0.15s ease-out;
  width: 80%;

">
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
              <div *ngIf="f.password.errors.required">Password is required</div>
              <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
            </div>

            <br/>
            <input name="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" type="password" formControlName="confirmPassword" placeholder="Confirm Password" required  style="-webkit-appearance: none;
  -moz-appearance: none;
  background: none;
  border: 0;
  border-bottom: 2px solid #9da3a5;
  font: 500 0.9rem ;
  height: 2.15em;
  margin-top: 0.7em;
  margin-left:9%;
  transition: all 0.15s ease-out;
  -webkit-transition: all 0.15s ease-out;
  width: 80%;

">
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
              <div *ngIf="f.confirmPassword.errors.required">Password is required</div>
              <div *ngIf="f.confirmPassword.errors.minlength">Password must be at least 6 characters</div>
            </div>

            <div *ngIf="(f.confirmPassword.invalid && (f.confirmPassword.dirty || f.confirmPassword.touched || submitted))" class="invalid-feedback">
              <div *ngIf="f.confirmPassword.errors">Password not matched.</div>
            </div>


            <button class="btn_sign" type="submit" style="margin-left: 23px;">Reset</button>


          </form>
        </div>

      </div>
      <!--inner block end here-->
      <!--copy rights start here-->
      <div class="copyrights">
        <p>© 2020 Lmaniya LLC. All Rights Reserved | <a href="http://lmaniya.org/" target="_blank">Visit</a> </p>
      </div>
      <!--COPY rights end here-->
    </div>
  </div>
  <!--slider menu-->

  <div class="sidebar-menu">
    <div class="logo"> <div  class="sidebar-icon"> <span class="fa fa-bars"></span> </div> <a > <span id="logo" ></span>
      <!--<img id="logo" src="" alt="Logo"/>-->
    </a> </div>
    <div class="menu">
      <ul id="menu" >
        <li id="menu-home" ><a routerLink="/admin/home"><i class="fa fa-tachometer"></i><span>{{'admin.dashboard'|translate}}</span></a></li>
        <li><a><i class="fa fa-book"></i><span>{{'admin.products'|translate}}</span><span class="fa fa-angle-right" style="float: right"></span></a>
          <ul>
            <li><a routerLink="/admin/home/create-category">{{'admin.add-new-category'|translate}}</a></li>
            <li><a routerLink="/admin/home/create-sub-category">{{'admin.add-new-sub-category'|translate}}</a></li>
            <li><a routerLink="/admin/home/create-product">{{'admin.add-new-product'|translate}}</a></li>

          </ul>
        </li>

        <li id="menu-comunicacao" ><a ><i class="fa fa-users nav_icon"></i><span>{{'admin.vendor'|translate}}</span><span class="fa fa-angle-right" style="float: right"></span></a>
          <ul id="menu-comunicacao-sub" >
            <!--          <li id="menu-mensagens" style="width: 120px" ><a href="buttons.html">Vendor List</a>-->
            <!--          </li>-->
            <li id="menu-arquivos" ><a routerLink="/admin/home/create-vendor">{{'admin.add-vendor'|translate}}</a></li>
          </ul>
        </li>


        <!--      <li id="menu-academico" ><a ><i class="fa fa-user"></i><span>Users</span><span class="fa fa-angle-right" style="float: right"></span></a>-->
        <!--        <ul  >-->
        <!--          <li ><a href="login.html">Manage Users</a></li>-->
        <!--        </ul>-->
        <!--      </li>-->

        <!--      <li><a ><i class="fa fa-envelope"></i><span>Orders</span><span class="fa fa-angle-right" style="float: right"></span></a>-->
        <!--        <ul >-->
        <!--          <li ><a href="inbox.html">New Order Requests</a></li>-->
        <!--          <li ><a href="inbox-details.html">Order List</a></li>-->
        <!--        </ul>-->
        <!--      </li>-->

        <li><a><i class="fa fa-cog"></i><span>{{'admin.system'|translate}}</span><span class="fa fa-angle-right" style="float: right"></span></a>
          <ul>
            <li><a (click)="logout()">{{'admin.logout'|translate}}</a></li>
            <li><a routerLink="/admin/home/change-password">{{'admin.change-password'|translate}}</a></li>
            <li><a routerLink="/admin/home/terms-conditions">{{'admin.terms-and-conditions'|translate}}</a></li>
            <li data-toggle="modal" data-target="#modalLoginForm"><a >{{'admin.about-us'|translate}}</a></li>
          </ul>
        </li>
        <li routerLink="admin/business/total"><a><i class="fa fa-cog"></i><span>Total Business</span></a></li>

        <!--      <li><a ><i class="fa fa-shopping-cart"></i><span>E-Commerce</span><span class="fa fa-angle-right" style="float: right"></span></a>-->
        <!--        <ul id="menu-academico-sub" >-->
        <!--          <li id="menu-academico-avaliacoes" ><a href="product.html">Product</a></li>-->
        <!--          <li id="menu-academico-boletim" ><a href="price.html">Price</a></li>-->
        <!--        </ul>-->
        <!--      </li>-->
      </ul>
    </div>
  </div>


  <div class="clearfix"> </div>
</div>

<!-- mother grid end here-->
</body>
</html>
