import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from 'src/app/home/home.component';
import {PopularProductComponent} from 'src/app/popular-product/popular-product.component';
import {BillComponent} from 'src/app/bill/bill.component';
import {UserProfileComponent} from 'src/app/user-profile/user-profile.component';
import {ContactUsComponent} from 'src/app/contact-us/contact-us.component';
import {AboutUsComponent} from 'src/app/about-us/about-us.component';
import {TermsOfServiceComponent} from 'src/app/terms-of-service/terms-of-service.component';
import {IndexComponent} from 'src/app/admin/index/index.component';
import {CreateProductComponent} from 'src/app/admin/create-product/create-product.component';
import {TodayOrdersComponent} from 'src/app/admin/today-orders/today-orders.component';
import {PrintBillComponent} from 'src/app/admin/print-bill/print-bill.component';
import {TermsConditionComponent} from 'src/app/admin/terms-condition/terms-condition.component';
import {CreateCategoryComponent} from 'src/app/admin/create-category/create-category.component';
import {OrderDetailsComponent} from 'src/app/vendor/order-details/order-details.component';
import {CreateVendorComponent} from 'src/app/admin/create-vendor/create-vendor.component';
// import {EditCategoryComponent} from 'src/app/admin/edit-category/edit-category.component';
import {RequestVendorComponent} from 'src/app/admin/request-vendor/request-vendor.component';
import {CreateSubCategoryComponent} from 'src/app/admin/create-sub-category/create-sub-category.component';
import {AdminHomeComponent} from 'src/app/admin/admin-home/admin-home.component';
import {TotalBusinessComponent} from 'src/app/admin/total-business/total-business.component';
import {EmailComponent} from 'src/app/admin/email/email.component';
import {ChangePasswordComponent} from 'src/app/admin/change-password/change-password.component';
import {VendorHomeComponent} from 'src/app/vendor/vendor-home/vendor-home.component';
import {VendorLoginComponent} from 'src/app/vendor/vendor-login/vendor-login.component';
import {NaComponent} from 'src/app/na/na.component';
import {SuccessComponent} from 'src/app/success/success.component';
import {CheckoutComponent} from 'src/app/checkout/checkout.component';
import {CartComponent} from 'src/app/cart/cart.component';
import {MyordersComponent} from 'src/app/myorders/myorders.component';
import {MyordersDetailsComponent} from 'src/app/myorders-details/myorders-details.component';
import {SuccessPageComponent} from 'src/app/success-page/success-page.component';
import {PageNotFoundComponent} from 'src/app/page-not-found/page-not-found.component';
import {ProductDetailComponent} from 'src/app/product-detail/product-detail.component';
import {WelcomePageComponent} from 'src/app/welcome-page/welcome-page.component';
import {SubCategoryComponent} from 'src/app/sub-category/sub-category.component';
import {ProductPageComponent} from 'src/app/product-page/product-page.component';
import {UserLoginComponent} from 'src/app/user-login/user-login.component';
import {ForgetPasswordComponent} from 'src/app/forget-password/forget-password.component';
import {UserRegisterComponent} from 'src/app/user-register/user-register.component';
import {EmailVerificationComponent} from 'src/app/email-verification/email-verification.component';
import {ForgetSuccessComponent} from 'src/app/forget-success/forget-success.component';
import {UserChangePasswordComponent} from 'src/app/user-change-password/user-change-password.component';
import {ImageSuccessComponent} from 'src/app/image-success/image-success.component';

const routes: Routes = [
{path:'', redirectTo:'home', pathMatch: 'full' },
{path:'home', component:HomeComponent},
{path:'contact-us', component:ContactUsComponent},
{path:'popular-product', component:PopularProductComponent},
{path:'about-us', component:AboutUsComponent},
{path:'terms-of-service', component:TermsOfServiceComponent},
{path:'admin/index', component:IndexComponent},
{path: 'admin/home', component:AdminHomeComponent},
{path: 'admin/orders/today', component:TodayOrdersComponent},
{path: 'admin/business/total', component:TotalBusinessComponent},
{path: 'admin/email', component:EmailComponent},
{path: 'vendor/home', component:VendorHomeComponent},
{path: 'vendor/login', component:VendorLoginComponent},
{path: 'vendor/home/:order_id', component:OrderDetailsComponent},
{path: 'admin/home/create-category', component:CreateCategoryComponent},
{path: 'admin/home/print-bill', component:PrintBillComponent},
{path: 'admin/home/change-password', component:ChangePasswordComponent},
{path: 'admin/home/create-vendor', component:CreateVendorComponent},
{path: 'admin/home/create-product', component:CreateProductComponent},
{path: 'admin/home/edit-product/:id', component:CreateProductComponent},
{path: 'admin/home/edit-category/:id', component:CreateCategoryComponent},
{path: 'admin/home/create-sub-category', component:CreateSubCategoryComponent},
{path: 'admin/home/edit-subcategory/:id', component:CreateSubCategoryComponent},
{path: 'admin/home/request-vendor', component:RequestVendorComponent},
{path: 'admin/home/terms-conditions', component:TermsConditionComponent},
{path: 'home/order-success', component:SuccessPageComponent},
{path: 'home/bill/:id', component:BillComponent},
{path: 'home/products/success', component:SuccessComponent},
{path: 'home/user/forget-password', component:ForgetPasswordComponent},
{path: 'change-password', component:UserChangePasswordComponent},
{path: 'image-upload-success', component:ImageSuccessComponent},
{path:'user-profile', component:UserProfileComponent},

{path:'product-not-found', component:NaComponent},
{path:'checkout', component:CheckoutComponent},
{path:'new password sent', component:ForgetSuccessComponent},
{path:'myorders', component:MyordersComponent},
{path:'myorders/:order_id', component:MyordersDetailsComponent},
{path:'product-detail', component:ProductDetailComponent},
{path:'category/sub-category/product', component:ProductPageComponent},
{path:'category/:name/:id', component:SubCategoryComponent},
{path:'search/:keyword', component:ProductPageComponent},
{path:'all-products/:newMode', component:ProductPageComponent},
{path:'all-products/:popMode', component:ProductPageComponent},
{path:'category/:name/:sName/:id', component:ProductPageComponent,
children:[
{path: 'product-detail/:id', component: HomeComponent},]
},
{path:'category/sub-category', component:SubCategoryComponent},
{path:'product-detail/:id', component:ProductDetailComponent},
{path:'user/login', component:UserLoginComponent},
{path:'register', component:UserRegisterComponent},
{path:'cart', component:CartComponent},
{path:'email-verification', component:EmailVerificationComponent},
{path:'**', component: PageNotFoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
