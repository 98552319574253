<app-header></app-header>
<!DOCTYPE html>
<head>
  <meta charset="utf-8">
</head> 
<header>
  <nav><i class="mdi mdi-chevron-double-left"></i><a (click)="goPrevious()">Go Back</a></nav>
</header>

<div class="text-center">
  <circle-progress *ngIf="loader"
                   [percent]="100"
                   [radius]="100"
                   [subtitle]="'Loading...'"
                   [outerStrokeWidth]="16"
                   [innerStrokeWidth]="8"
                   [outerStrokeColor]="'#bc2624'"
                   [innerStrokeColor]="'#C7E596'"
                   [animation]="true"
                   [animationDuration]="10000"
  ></circle-progress>


</div>
<figure class="snip1249" *ngFor="let p of product|paginate: { itemsPerPage: 12, currentPage: p }; let i = index" style="margin-top:40px;" >
  <div class="image">
    <img routerLink="/product-detail/{{p.Product_id}}" [src]="p.Image" alt="sample90" height="200" width="200"/><i class="ion-ios-basketball-outline"></i>
  </div>

  <figcaption>
    <h6>{{p.Product_name}}</h6>
    <div class="price" >
      <s>{{p.Initial_Price |currency: 'रू '}}</s>{{p.Final_price | currency: 'रू '}}
    </div>

    <a *ngIf="isLoggedIn" (click)="addToCart(p)" class="add-to-cart">{{'homeContent.add-to-cart'|translate}}</a>
    <a *ngIf="!isLoggedIn" routerLink="/user/login" class="add-to-cart">Login to Buy</a>
  </figcaption>
</figure>
<pagination-controls (pageChange)="p = $event" class="row m-auto justify-content-center"></pagination-controls>

<app-footer></app-footer>

