<app-header></app-header>
<div class="form_wrapper " >
  <div class="form_container" >
    <div class="title_container">
      <h2>{{'register.register-header'|translate}}</h2>
      <div *ngIf="error" class="badge badge-danger">{{error.message}}</div>
    </div>
    <div class="row clearfix">
      <div >
        <form  [formGroup]="form"
               name="form"
               (ngSubmit)="onSubmit()"
               >
          <div class="input_field"> <span><i aria-hidden="true" class="mdi mdi-email mdi-18px"></i></span>
            <input type="email" name="email" placeholder="Email" required formControlName="email" autocomplete  class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }"/>

          <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors.required">Email is required</div>
            <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
          </div>
          </div>

          <div class="input_field"> <span><i aria-hidden="true" class="mdi mdi-lock mdi-18px"></i></span>
            <input type="password" name="password" placeholder="Password" formControlName="password" minlength="6" class="form-control" autocomplete="true"  class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />

            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
              <div *ngIf="f.name.errors.required">Valid Name is required</div>
            </div>
          </div>




          <div class="row clearfix">
            <div>
              <div class="input_field"> <span><i aria-hidden="true" class="mdi mdi-account mdi-18px"  ></i></span>
                <input type="text" name="name" placeholder="Full Name" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.name.errors }"/>
                <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                  <div *ngIf="f.name.errors.required">Valid Name is required</div>
                </div>
              </div>

            </div>

          </div>


          <p>
            <input type="checkbox" id="cb1" (change)="check($event)" />
            <label for="cb1">{{'register.agree'|translate}}</label>
          </p>
          <div *ngIf="!checked && clicked" class="alert alert-warning" role="alert">
          Enable Check box!
          </div>

          <input class="button" type="submit" value="{{'register.proceed'|translate}}"/>
          <p  class="foot-txt"style="text-align: center;">Already member?<a routerLink="/user/login" class="bold" >{{'login.btn-login'|translate}}
          </a>
        </form>


      </div>
    </div>
  </div>
</div>
<div class="container" *ngIf="loader">
  <div class="row">
    <div id="loader">
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="loading"></div>
    </div>
  </div>
</div>
<app-footer></app-footer>
