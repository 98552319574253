import { Component, OnInit } from '@angular/core';
import { SearchdataService } from 'src/app/services/searchdata.service';
import {ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-total-business',
  templateUrl: './total-business.component.html',
  styleUrls: ['./total-business.component.scss']
})
export class TotalBusinessComponent implements OnInit {

  constructor(private searchdataService:SearchdataService,                   private router :Router,
) { }

orders:any=[];
admin: string='';
users:[];
term:string;
customer:string;
vorder:string;
vendor:string;
vOrders: any=[];
  p: number = 1;
  q: number = 1;
  r: number = 1;
  s: number = 1;
searches: string[] = [];
  model : any={};
  emp:any='';
  loader=false;
msg:string='';
  ngOnInit(): void {
    this.showdata();
  }
  showdata()
  {
    this.searchdataService.showdata().subscribe( (response) =>{

                                                                  this.emp=response;
                                                            if(this.emp.error==true){
                                                        // console.log(this.error.error);
                                                                       }
                                                                  else{

                                                                  }
                                                                  this.emp=response;
                                                                  console.log(response.message)
                                                                  });
  }
  searchdata() {
   this.msg='';
//    debugger;
  this.loader=true;

   let serializedForm = JSON.stringify(this.model);
                       console.log(serializedForm);
      this.searchdataService.searhhdata(this.model).subscribe((response) => {                           //Next callback
//                                                                         console.log('response received')
                                                                        this.emp = response;
                                                                                                                                                 this.loader = false;
                                                                       this.loader = false;

                                                                      },
                                                                      (error) => {                              //Error callback
//                                                                         console.error('Nothing to Show..')
                                                                        this.msg = "Nothing to show..";
                                                                        this.emp='';
                                                                        this.loader = false;

                                                                        //throw error;   //You can also throw the error to a global error handler
                                                                      }
      )
  }

total(bills) {
            var total = 0;
            bills.forEach(element => {
total = total + (element.Product_quantity*element.Product_price);
            });
            return total;
            }
  print(id){
  localStorage.setItem("abill_number",id);
  return this.router.navigateByUrl('/admin/home/print-bill');
  }
    logout(){
        localStorage.setItem('isAdminLoggedin',"false");
        this.router.navigateByUrl('/admin/index');

        }

}
