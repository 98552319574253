import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router } from '@angular/router';
import {ProductService} from 'src/app/services/product.service';
import {Product} from 'src/app/common/product';
import {Order} from 'src/app/common/order';
import {Vendor} from 'src/app/common/vendor';
// import {MatDialogsModule} from '@angular-material-extensions/core';
// import {MdDialog} from "@angular/material";
// import {MdDialogRef} from "@angular/material";
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-vendor-home',
  templateUrl: './vendor-home.component.html',
  styleUrls: ['./vendor-home.component.scss']
})
export class VendorHomeComponent implements OnInit {
isLoggedin: boolean=false;
products: Product[];
orders:Order[];
vendors: Vendor[];
users:[];
order:string;
  p: number = 1;

  constructor(private _productService: ProductService,
                private _activatedRoute:ActivatedRoute,
                 private http: HttpClient,
                   private router :Router,
) { }

  ngOnInit() {

this.getVendor();

if(localStorage.getItem('isVendorLoggedin')=='true'){
this.isLoggedin=true;
// console.log(this.isLoggedin);
}else{
this.router.navigateByUrl('/vendor/login');
}
var toggle = true;

$(".sidebar-icon").click(function() {
  if (toggle)
  {
    $(".page-container").addClass("sidebar-collapsed").removeClass("sidebar-collapsed-back");
    $("#menu span").css({"position":"absolute"});
  }
  else
  {
    $(".page-container").removeClass("sidebar-collapsed").addClass("sidebar-collapsed-back");
    setTimeout(function() {
      $("#menu span").css({"position":"relative"});
    }, 400);
  }
                toggle = !toggle;
            });


// list the products available in table
  this.listProducts();
  this.listOrders();
  this.listUsers();

  }

getVendor(){
let vid=localStorage.getItem('vendor_id');
// console.log(vid);
this.http.get<Vendor[]>(`http://djpashupati.com/djpashupati/api/vendor/read-one-vendor.php?id=${vid}`).subscribe(data=>this.vendors=data);
// console.log(this.vendors);
}

listUsers(){
this._productService.getUsers().subscribe(data=>this.users=data);
}

  listOrders(){

  let vendor_name=localStorage.getItem("vendor_name");
  var formData:any=new FormData();
  formData.append('vendor_name',vendor_name);
  console.log(...formData);
  this.http.post<any>("http://djpashupati.com/djpashupati/api/vendor-request/read-request-by-vendor.php",formData).subscribe(data=>this.orders=data);
  console.log(this.orders);
  }

  listProducts(){
  this._productService.getProductAll().subscribe(data =>{this.products=data;
//   console.log(this.products);
  },
  error=>{
  console.error(error);
  }
  );

  }

viewOrderDetail(){

}

shipOrderByVendor(order_id: string){
 var formData: any = new FormData();
        formData.append("id", order_id);
  this.http.post('http://djpashupati.com/djpashupati/api/vendor-request/ship-request-by-vendor.php', formData).subscribe(data=>console.log(data));
  this.listOrders();
  return this.ngOnInit();

}


cancelOrderByVendor(order_id:string){
  let vendor_type=localStorage.getItem("vendor_type");

  this.http.post('http://djpashupati.com/djpashupati/api/vendor-request/cancel-request-by-vendor.php', [{"id": order_id},{"vendor_type":vendor_type}]).subscribe();
  this.listOrders();
return this.ngOnInit();

}

logout(){
localStorage.setItem('isVendorLoggedin',"false");
this.router.navigateByUrl('/vendor/login');

}

}
