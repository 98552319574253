
<!--footer-->

<style>
  .section-footer a{
  color:white;

    text-decoration:none;
  }
  .section-footer a:hover{
  color:#f44336;
  }
  .section-footer h6{
  font-weight:bold;
  }
  .section-footer{
  background-color:#66023c;
  padding:0;
  }
</style>
<footer class="section-footer p-5">
  <div class="container">
    <section class="footer-top padding-y-lg text-white">
      <div class="row">
        <aside class="col-md col-6">
          <h6 class="title">{{'footer.company'|translate}}</h6>
          <ul class="list-unstyled">
            <li> <a routerLink="/about-us">{{'footer.about-us'|translate}}</a></li>
            <li> <a routerLink="/terms-of-service">{{'footer.t&c'|translate}}</a></li>
          </ul>
        </aside>
        <aside class="col-md col-6">
          <h6 class="title">{{'footer.help'|translate}}</h6>
          <ul class="list-unstyled">
            <li> <a routerLink="/contact-us">{{'footer.contact-us'|translate}}</a></li>
<!--            <li> <a href="#">{{'footer.qa'|translate}}</a></li>-->
          </ul>
        </aside>
        <aside class="col-md col-6">
          <h6 class="title">{{'footer.user-info'|translate}}</h6>
          <ul class="list-unstyled">
            <li> <a routerLink="/user/login"> {{'footer.user-login'|translate}} </a></li>
            <li> <a routerLink="/register"> {{'footer.user-register'|translate}} </a></li>
<!--            <li> <a href="#"> {{'footer.orders'|translate}} </a></li>-->
          </ul>
        </aside>
        <aside class="col-md">
          <h6 class="title">{{'footer.follow-us'|translate}}</h6>
          <ul class="list-unstyled ">
            <li><a class="text-white" href="#"> <i class="mdi mdi-facebook"></i> Facebook </a></li>
            <li><a class="text-white" href="#"> <i class="mdi mdi-twitter"></i> Twitter </a></li>
            <li><a class="text-white" href="#"> <i class="mdi mdi-instagram"></i> Instagram </a></li>
            <li><a class="text-white" href="#"> <i class="mdi mdi-youtube"></i> Youtube </a></li>
          </ul>
        </aside>
      </div> <!-- row.// -->
    </section>	<!-- footer-top.// -->
    <section class="footer-bottom text-center">
      <a href="#" class="text-white">{{'footer.privacy-policy'|translate}}</a>
      <p class="text-muted"> <i class=""></i> 2020 DjPashupati, All rights reserved </p>
      <br>
    </section>
  </div><!-- //container -->
</footer>

