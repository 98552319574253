
<div class="modal fade" id="cartModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content" *ngIf="isLoggedIn">
      <div class="modal-header border-bottom-0">
        <h5 class="modal-title" id="exampleModalLabel">
          Your Shopping Cart
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="cartItems.length>0">
        <table class="table table-image">
          <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col">Product</th>
            <th scope="col">Price</th>
            <th scope="col">Qty</th>
            <th scope="col">Total</th>
            <th scope="col">Actions</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let cartItem of cartItems">
            <td class="w-25">
              <img [src]="cartItem.Product_image" class="img-fluid img-thumbnail" alt="image">
            </td>
            <td>{{cartItem.Product_name}}</td>
            <td>{{cartItem.Product_price | currency:'रू '}}</td>
            <td class="qty">
              <!-- skin 2 -->
              <div class="num-block skin-2" >
                <div class="num-in">
                  <span class="minus dis pointer" (click)="decrementQuantity(cartItem)"></span>
                  <input type="text" class="in-num" [(ngModel)]="cartItem.Product_quantity"  min="1" (ngModelChange)="modelChanged($event)"/>
<!--                  <input type="text" class="in-num" [value]="cartItem.Product_quantity">-->
                  <span class="plus" (click)="incrementQuantity(cartItem)"></span>
                </div>
              </div></td>
            <td>{{cartItem.Product_quantity * cartItem.Product_price  | currency:'रू '}}</td>
            <td>
              <a  class="btn btn-danger btn-sm" (click)="remove(cartItem)">
                <i class="mdi mdi-trash"></i>
              </a>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="d-flex justify-content-end">
          <h5>Total: <span class="price text-success">{{total(cartItems)| currency: 'रू '}}</span></h5>
        </div>
      </div>
      <div class="alert alert-warning" role="alert" *ngIf="cartItems.length==0" >
      Your Cart is Empty
      </div>
      <div class="modal-footer border-top-0 d-flex justify-content-between" *ngIf="cartItems.length>0">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Continue Shopping</button>
        <button routerLink="/checkout" type="button" class="btn btn-success" data-dismiss="modal">Checkout</button>
      </div>

    </div>


    <div class="alert alert-warning" role="alert" *ngIf="!isLoggedIn" >
      Please Login First.
    </div>

  </div>
</div>
