<app-header></app-header>

<button printSectionId="bill" ngxPrint>print</button>
<div class="container invoice" id="bill" style="margin:auto;">
  <div class="invoice-header">
    <div class="row">
      <div class="col-xs-8">
        <h3 class="text-muted">{{orderId}}</h3>

        <h4 class="text-muted">{{currentDate | date:'yyyy-MM-dd'}}</h4>
      </div>
      <div class="col-xs-4">
        <div class="media">
          <div class="media-left">
            <img class="media-object logo" src="./assets/images/djplogo.jpg" />
          </div>
          <ul class="media-body list-unstyled">
            <li><strong>DJPashupati</strong></li>
            <li>Dhangadhi</li>
<!--            <li>info@djpashupati.com</li>-->
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="invoice-body">
    <div class="row">

      <div class="col-xs-7">
        <div class="panel panel-default">
          <div class="panel-heading">
            <h3 class="panel-title">{{'bill.customer detail'|translate}}</h3>
          </div>
          <div class="panel-body">
            <dl class="dl-horizontal">
              <dt>{{'bill.name'|translate}}</dt>
              <dd>{{user_name}}</dd>

              <dt>{{'bill.email'|translate}}</dt>
              <dd>{{user_email}}</dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
    <div class="panel panel-default">
      <div class="panel-heading">
        <h3 class="panel-title">{{'bill.services'|translate}}</h3>
      </div>
      <table class="table table-bordered table-condensed">
        <thead>
        <tr>
          <th>{{'bill.item'|translate}}</th>
          <th class="text-center colfix">{{'bill.cost'|translate}}</th>
          <th class="text-center colfix">{{'bill.quantity'|translate}}</th>


          <th class="text-center colfix">{{'bill.total'|translate}}</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let cartItem of cartItems">
          <td>
            {{cartItem.Product_name}}
            <br>
            <small class="text-muted">{{cartItem.Description}}</small>
          </td>
          <td class="text-right">
            <span class="mono">{{cartItem.Product_price| currency: 'रू '}}</span>
            <br>

          </td>
          <td class="text-right">
            <span class="mono">{{cartItem.Product_quantity}}</span>
            <br>
            <small class="text-muted">{{cartItem.unit}}</small>
          </td>
          <td class="text-right">
            <span class="mono">{{cartItem.Product_quantity * cartItem.Product_price| currency: 'रू '}}</span>
            <br>

          </td>

        </tr>


        </tbody>
      </table>
    </div>
    <div class="panel panel-default">
      <table class="table table-bordered table-condensed">
        <thead>
        <tr>

          <td class="text-center col-xs-1">{{'bill.final'|translate}}</td>
        </tr>
        </thead>
        <tbody>
        <tr>

          <th class="text-center rowtotal mono">{{total(cartItems)| currency: 'रू '}}</th>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="row">

      <div class="col-xs-5">
        <div class="panel panel-default">
          <div class="panel-heading">
            <h3 class="panel-title">{{'bill.payment'|translate}}</h3>
          </div>
          <div class="panel-body">
            <p>Cahs on delivery</p>
            <!--             <ul class="list-unstyled">
                          <li>Alpha Bank - <span class="mono">MO123456789456123</span></li>
                          <li>Beta Bank - <span class="mono">MO123456789456123</span></li>
                          <li>Gamma Bank - <span class="mono">MO123456789456123</span></li>
                        </ul> -->
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="invoice-footer">
    Thank you for choosing our services.
    <br/> We hope to see you again soon
    <br/>
    <strong>~DJPASHUPATI~</strong>
  </div>
</div>
<app-footer></app-footer>
