import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';import {Cart} from '../common/cart';
import {CartService} from '../services/cart.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
cartItems: Cart[]=[];
totalPrice: number=0;
totalQuantity: number=0;
isLoggedIn: boolean=false;
loader: boolean=true;

  constructor(private _cartService: CartService, private http: HttpClient) { }

  ngOnInit() {
     if (localStorage.getItem('isLoggedIn') == "true") {
     this.isLoggedIn=true;
  }
  this.cartDetails();
  }

  cartDetails(){
//   this.cartItems=this._cartService.cartItems;
    var formData2: any=new FormData();
    let uid=localStorage.getItem('user_id');
        formData2.append("users_id", uid);
this.http.post<any>('http://djpashupati.com/Djpashupati_Api/user_cart_view.php',formData2).subscribe(data=>this.cartItems=data);
  this._cartService.totalPrice.subscribe(
  data=> this.totalPrice=data);

  this._cartService.totalQuantity.subscribe(
  data=> this.totalQuantity=data);

  this._cartService.calculateTotalPrice();
//   return this.ngOnInit();

  }


@Input() cartItem: Cart;
  @Output() productRemoved = new EventEmitter();
  modelChanged(cartItem) {
     if (this.cartItem.Product_quantity === 0) {
      this.productRemoved.emit(this.cartItem)
     }
  }


incrementQuantity(cartItem: Cart){
cartItem.Product_quantity++;
this._cartService.addToCart(cartItem);
// console.log('increment', cartItem);
let cart=JSON.stringify(cartItem);

this.http.post<any>('http://djpashupati.com/djpashupati/api/order/update-cart.php',cart).subscribe(data=>console.log(data));
// return this.ngOnInit();

}

decrementQuantity(cartItem: Cart){
--cartItem.Product_quantity;
// console.log('decrement', cartItem);
let cart=JSON.stringify(cartItem);

this._cartService.decrementQuantity(cartItem);

this.http.post<any>('http://djpashupati.com/djpashupati/api/order/update-cart.php',cart).subscribe(data=>console.log(data));

// return this.ngOnInit();

}

remove(cartItem: Cart){
let cart=JSON.stringify(cartItem);
this.http.post<any>('http://djpashupati.com/djpashupati/api/order/remove-cart-item.php',cart).subscribe(data=>console.log(data));

// console.log('remove', cartItem);
this._cartService.remove(cartItem);
return this.ngOnInit();
}

total(bills) {
            var total = 0;
            bills.forEach(element => {
total = total + (element.Product_quantity*element.Product_price);
            });
            return total;        }

}
