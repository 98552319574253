
<script src="//code.jquery.com/jquery-1.11.1.min.js"></script>
<!------ Include the above in your HEAD tag ---------->
<app-header></app-header>
<div class="aboutus-area">
  <div class="container">
    <div class="row">
      <div class="col-xs-12">

        <div class="col-md-8 col-sm-6 col-xs-12">
          <div class="aboutus-content" >
            <h1>Terms and Conditions <span>DJPashupati</span></h1><br>
            <div *ngFor="let term of terms">
            <h4 >{{term.Title}}</h4>
            <p >{{term.Description}}</p>
            </div>


          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
