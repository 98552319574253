import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { HttpClient } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import{CartService} from 'src/app/services/cart.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
totalPrice: number=0 ;
totalQuantity: number=0 ;
isLoggedIn: boolean=false;

  constructor(private _cartService:CartService,
              public translate: TranslateService,
              private router: Router,
  ) {
  translate.setDefaultLang('en');
  }

  ngOnInit() {

   if (localStorage.getItem('isLoggedIn') == "true") {
   this.isLoggedIn=true;
}

this.updateCartStatus();


jQuery(function ($) {


$("#close-sidebar").click(function() {
  $(".page-wrapper").removeClass("toggled");
});

$("#show-sidebar").click(function() {
  $(".page-wrapper").addClass("toggled");
});

$('.container,.navbar').click(function(){
   $('.page-wrapper').removeClass('toggled');
});

});

  }

switchLang(language: string){
this.translate.use(language);

}

  updateCartStatus(){

  this._cartService.totalPrice.subscribe(
  data => this.totalPrice=data
  );
  this._cartService.totalQuantity.subscribe(
     data => this.totalQuantity=data
     );
  }

searchProduct(keyword: string){
// console.log('keyword', keyword);
this.router.navigateByUrl('/search/'+keyword);
}

newProduct(newMode: string){
// console.log('newMode', newMode);
this.router.navigateByUrl('/all-products/'+newMode);
}

popularProduct(popMode: string){
// console.log('popMode', popMode);
this.router.navigateByUrl('/popular-product');
}

logout(){
localStorage.setItem('isLoggedIn',"false");
return this.router.navigateByUrl('/user/login');

}

}
