import { Component, OnInit } from '@angular/core';
import {ActivatedRoute } from '@angular/router';
import {ProductService} from '../services/product.service';
import {Product} from '../common/product';
import {ProductCategory} from '../common/product-category';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
productCategory: ProductCategory[];
currentCategoryId:number;
  p: number = 1;
  loader: boolean=true;

  constructor(private _productService: ProductService,
  private _activatedRoute:ActivatedRoute,
  ) { }

  ngOnInit() {

//   this._activatedRoute.paramMap.subscribe(()=>{
  this.listCategory();
//   })
  }

//   listProductCategory(){
// //
// //   const hasCategoryId: boolean=this._activatedRoute.snapshot.paramMap.has('id');
// //   if(hasCategoryId){
// //   this.currentCategoryId=+this._activatedRoute.snapshot.paramMap.get('id');
// //   }else{
// //   this.currentCategoryId=1;
// //   }
//
// this._productService.getProductCategory().subscribe(data =>this.productCategory=data)
// console.log(this.productCategory);
//   }

 listCategory(){
    this._productService.getCategoryAll().subscribe(data =>{this.productCategory=data;
    this.loader=false;
//     console.log(this.productCategory);
    },
    error=>{
//     console.error(error);
    }
    );

    }


}
