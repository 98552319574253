import { Component, OnInit } from '@angular/core';
// import { AuthService } from '../services/auth.service';
import { FormBuilder, FormGroup,Validators } from "@angular/forms";
import { HttpClient } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import {ProductService} from 'src/app/services/product.service';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit {
 form: FormGroup;
msg:string='';
submitted=false;
loader=false;
emails:any='';

  constructor( public fb: FormBuilder,private _productService: ProductService,
                   private http: HttpClient, private router:Router,) {



                    }

  ngOnInit() {
  this.listEmail();
    this.form = this.fb.group({
                             subject: ['',Validators.required],
                             email: ['',[Validators.required, Validators.email]],
                             message: ['',Validators.required],
                           })
  }
    get f() { return this.form.controls; }

onSubmit(){
        this.submitted = true;
 if (this.form.invalid) {
            return;
        }else{
                   this.loader=true;
        let formObj = this.form.getRawValue(); // {name: '', description: ''}
        let serializedForm = JSON.stringify(formObj);

this.form.reset();
this.msg='Success!';
this.http.post<any>("http://djpashupati.com/Djpashupati_Api/email_messaging.php",serializedForm).subscribe();
// console.log(serializedForm);
return this.router.navigateByUrl('/admin/home')
}

}

 listEmail(){
      this._productService.getEmail().subscribe(data =>{this.emails=data;
      console.log(this.emails);
      },
      error=>{
//       console.error(error);
      }
      );

      }


}
