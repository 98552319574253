import { Component, OnInit } from '@angular/core';
import {ActivatedRoute } from '@angular/router';
import {ProductService} from 'src/app/services/product.service';
import {Product} from 'src/app/common/product';
import {ProductCategory} from 'src/app/common/product-category';
import {ProductSubCategory} from 'src/app/common/product-sub-category';
import { FormBuilder, FormGroup } from "@angular/forms";
import { HttpClient } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
@Component({
  selector: 'app-create-vendor',
  templateUrl: './create-vendor.component.html',
  styleUrls: ['./create-vendor.component.scss']
})
export class CreateVendorComponent implements OnInit {


categories: ProductCategory[];
subCategories: ProductSubCategory[];
form: FormGroup;
error:any=[];
msg:string;
vendors: any=[];
p:number=1;
errorMessage: string = "";

  constructor(private _productService: ProductService,
                private _activatedRoute:ActivatedRoute,
                public fb:FormBuilder,
                private http:HttpClient,private router :Router,
                ) {
                this.form=this.fb.group({
                vendor_type:[''],

                vendor_name:[''],
                vendor_email:[''],
                vendor_password:[''],

                vendor_address:[''],
                vendor_contact:[''],
                vendor_status:['']
                })



                }

  ngOnInit() {
this.listVendor();
var toggle = true;

  let q= localStorage.getItem("isAdminLoggedin");
  if(q!='true'){
  this.router.navigateByUrl('/admin/index');
  }

$(".sidebar-icon").click(function() {
  if (toggle)
  {
    $(".page-container").addClass("sidebar-collapsed").removeClass("sidebar-collapsed-back");
    $("#menu span").css({"position":"absolute"});
  }
  else
  {
    $(".page-container").removeClass("sidebar-collapsed").addClass("sidebar-collapsed-back");
    setTimeout(function() {
      $("#menu span").css({"position":"relative"});
    }, 400);
  }
                toggle = !toggle;
            });


// list the products available in table
//   this.listProducts();
this.listCategory();
  }

//   listProducts(){
//   this._productService.getProductAll().subscribe(data =>{this.products=data;
//   console.log(this.products);
//   },
//   error=>{
//   console.error(error);
//   }
//   );
//
//   }


  listCategory(){
    this._productService.getCategoryAll().subscribe(data =>{this.categories=data;
    console.log(this.categories);
    },
    error=>{
    console.error(error);
    }
    );

    }



  onSubmit(){
var formData:any=new FormData();
formData.append("Vendor_name",this.form.get('vendor_name').value);
formData.append("Vendor_password",this.form.get('vendor_password').value);
formData.append("Vendor_contact",this.form.get('vendor_contact').value);
formData.append("Vendor_address",this.form.get('vendor_address').value);
formData.append("Vendor_type",this.form.get('vendor_type').value);
formData.append("Vendor_email",this.form.get('vendor_email').value);
formData.append("Status",this.form.get('vendor_status').value);
//         let formObj = this.form.getRawValue(); // {name: '', description: ''}
//
// let serializedForm = JSON.stringify(formObj);
// console.log(serializedForm);

 this.http.post<ProductSubCategory[]>('http://djpashupati.com/djpashupati/api/vendor/create.php',formData)
.subscribe(
        (response) => {                           //Next callback
          console.log(response)
        },
        (error) => {                               //Error callback

          this.errorMessage = error;
//           this.loading = false;
// console.error(this.errorMessage.error.message)
          //throw error;   //You can also throw the error to a global error handler
        });
        this.msg="Success";
        this.form.reset();
        return this.ngOnInit();



  }

logout(){
localStorage.setItem('isAdminLoggedin',"false");
this.router.navigateByUrl('/admin/index');

}


  listVendor(){
      this._productService.getVendors().subscribe(data =>{this.vendors=data;
      console.log(data);
      },
      error=>{
      console.error(error);
      }
      );

      }
}
