import { Component, OnInit } from '@angular/core';
import {ActivatedRoute } from '@angular/router';
import {ProductService} from 'src/app/services/product.service';
import {Product} from 'src/app/common/product';
import {ProductCategory} from 'src/app/common/product-category';
import {ProductSubCategory} from 'src/app/common/product-sub-category';
import { FormBuilder, FormGroup,FormArray } from "@angular/forms";
import { HttpClient } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
@Component({
  selector: 'app-terms-condition',
  templateUrl: './terms-condition.component.html',
  styleUrls: ['./terms-condition.component.scss']
})
export class TermsConditionComponent implements OnInit {
  public addmore: FormGroup;


  p: number = 1;
terms:any=[''];

public form: FormGroup;
error:any=[];
  constructor(private _productService: ProductService,
                private _activatedRoute:ActivatedRoute,
                public fb:FormBuilder,
                private http:HttpClient,
                  private router:Router,

                ) {


                }

get formArr() {
    return this.form.get('itemRows') as FormArray;
  }
  ngOnInit() {
//
//   let q= localStorage.getItem("isAdminLoggedin");
//   if(q!='true'){
//   this.router.navigateByUrl('/admin/index');
//   }

this.getTerms();
this.form=this.fb.group({
    vendor_name:[''],
     itemRows: this.fb.array([this.initItemRows()])


                })
var toggle = true;

$(".sidebar-icon").click(function() {
  if (toggle)
  {
    $(".page-container").addClass("sidebar-collapsed").removeClass("sidebar-collapsed-back");
    $("#menu span").css({"position":"absolute"});
  }
  else
  {
    $(".page-container").removeClass("sidebar-collapsed").addClass("sidebar-collapsed-back");
    setTimeout(function() {
      $("#menu span").css({"position":"relative"});
    }, 400);
  }
                toggle = !toggle;
            });


  }


 initItemRows() {
//  let vendor_name=localStorage.getItem("vendor_name");
    return this.fb.group({
    Title:[''],
    Description:[''],


    });
  }
  addNewRow() {
    this.formArr.push(this.initItemRows());
  }

  deleteRow(index: number) {
    this.formArr.removeAt(index);
  }




  onSubmit(){

  console.log(this.form.value.itemRows);



 this.http.post<any>('http://djpashupati.com/djpashupati/api/admin/terms.php',this.form.value.itemRows).subscribe();
this.form.reset();
return this.ngOnInit();

  }
logout(){
localStorage.setItem('isAdminLoggedin',"false");
this.router.navigateByUrl('/admin/index');

}

getTerms(){
 return this.http.get<any>('http://djpashupati.com/Djpashupati_Api/terms_and_condition.php').subscribe(data=>{this.terms=data; console.log(data)});

}

delete(id){
console.log(id);
var formData:any=new FormData();
formData.append('id',id);


console.log(...formData);
 this.http.post<any>('http://djpashupati.com/djpashupati/api/admin/delete-terms.php',formData).subscribe();
return this.ngOnInit();
}

}
