import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";

import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-print-bill',
  templateUrl: './print-bill.component.html',
  styleUrls: ['./print-bill.component.scss']
})
export class PrintBillComponent implements OnInit {

cartItems: any=[];
totalPrice: number=0;
orderId:string='';
totalQuantity: number=0;
 dateToday: number = Date.now();
  constructor(private _fb: FormBuilder,
                             private http: HttpClient,private router: Router,
) { }

  ngOnInit() {
     this.orderId = localStorage.getItem("abill_number");
     console.log(this.orderId);
     this.cartDetails();
  }

 cartDetails(){
//   this.cartItems=this._cartService.cartItems;
    var formData2: any=new FormData();
     this.orderId = localStorage.getItem("abill_number");
     console.log(this.orderId)
        formData2.append("Bill_number", localStorage.getItem("abill_number"));
// var bill={"Bill_number": localStorage.getItem("abill_number")}
// let b=JSON.stringify(bill);
// console.log(b);
this.http.post<any>('http://djpashupati.com/Djpashupati_Api/bill.php',formData2).subscribe(data=>this.cartItems=data);

//   this._cartService.totalPrice.subscribe(
//   data=> this.totalPrice=data);


//   return this.ngOnInit();

  }

total(bills) {
            var total = 0;
            bills.forEach(element => {
total = total + (element.Product_quantity*element.Product_price);
            });
            return total;

              }

            print(cmpName) {
                 let printContents = document.getElementById(cmpName).innerHTML;
                 let originalContents = document.body.innerHTML;

                 document.body.innerHTML = printContents;

                 window.print();

                 document.body.innerHTML = originalContents;
            }

            name(cartItems) {
                        var name = '';
                        cartItems.forEach(element => {
            name = element.Customer_name;
                        });
                        return name;        }


 address(cartItems) {
                        var address = '';
                        cartItems.forEach(element => {
            address = element.Customer_address;
                        });
                        return address;        }
                        logout(){
                        localStorage.setItem('isAdminLoggedin',"false");
                        this.router.navigateByUrl('/admin/index');

                        }


}
