
<!DOCTYPE HTML>
<html>
<head>
  <title>DjPashupati:Admin Panel</title>
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  <meta name="keywords" content="DjPashupati Online shopping" />
  <script type="application/x-javascript"> addEventListener("load", function() { setTimeout(hideURLbar, 0); }, false); function hideURLbar(){ window.scrollTo(0,1); } </script>

  <link href='//fonts.googleapis.com/css?family=Carrois+Gothic' rel='stylesheet' type='text/css'>
  <link href='//fonts.googleapis.com/css?family=Work+Sans:400,500,600' rel='stylesheet' type='text/css'>

  <script src="//cdn.jsdelivr.net/modernizr/2.8.3/modernizr.min.js" type="text/javascript"></script>
  <script>window.modernizr || document.write('<script src="lib/modernizr/modernizr-custom.js"><\/script>')</script>


</head>
<body>
<div class="page-container">
  <div class="left-content">
    <div class="mother-grid-inner">
      <!--header start here-->
      <div class="header-main">
        <div class="header-left">
          <div class="logo-name">
            <a routerLink="/vendor/home"> <h1>Vendor</h1>
              <!--<img id="logo" src="" alt="Logo"/>-->
            </a>
          </div>
          <!--search-box-->

          <div class="clearfix"> </div>
        </div>

        <div class="clearfix"> </div>
      </div>
      <!--heder end here-->
      <!-- script-for sticky-nav -->
      <script>
		$(document).ready(function() {
			 var navoffeset=$(".header-main").offset().top;
			 $(window).scroll(function(){
				var scrollpos=$(window).scrollTop();
				if(scrollpos >=navoffeset){
					$(".header-main").addClass("fixed");
				}else{
					$(".header-main").removeClass("fixed");
				}
			 });

		});
		</script>
      <!-- /script-for sticky-nav -->
      <!--inner block start here-->
      <div class="inner-block">
        <!--market updates updates-->
        <div class="market-updates">

          <div class="col-md-4 market-update-gd">
            <div class="market-update-block clr-block-3">
              <div class="col-md-8 market-update-left">
                <h3>23</h3>
                <h4>New Order Requests</h4>
              </div>
              <div class="col-md-4 market-update-right">
                <i class="fa fa-envelope-o"> </i>
              </div>
              <div class="clearfix"> </div>
            </div>
          </div>
          <div class="clearfix"> </div>
        </div>
        <!--market updates end here-->
        <!--mainpage chit-chating-->
        <div class="chit-chat-layer1">
          <div class="col-md-12 chit-chat-layer1-left">
            <div class="work-progres">
              <div class="chit-chat-heading">
                Recent Orders
              </div>
              <div class="table-responsive">
                <table class="table table-hover">
                  <thead>
                  <tr>
                    <th>SN</th>
                    <th>Product Id</th>
                    <th>Product Name</th>
                    <th>Quantity</th>
                    <th>Status</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let order of orders; let i=index">
                    <td>{{i+1}}</td>
                    <td >{{order.product_id}}</td>

                    <td>{{order.name}}</td>
                    <td>{{order.quantity}}</td>
                    <td>{{order.status}}</td>

                    <td><span class="label label-primary" *ngIf="order.status=='vpending'">Pending</span>
                    </td>
                    <td><span class="label label-success" *ngIf="order.status=='vpending'" (click)="shipOrderByVendor(order.order_id)">Ship</span></td>

                    <td><span class="label label-danger" *ngIf="order.status=='vpending'" (click)="cancelOrderByVendor(order.order_id)">Cancel</span>
                    </td>
                  </tr>

                  </tbody>
                </table>
              </div>
            </div>
          </div>



          <div class="clearfix"> </div>
        </div>
        <!--main page chit chating end here-->
        <!--main page chart start here-->

        <!--main page chart layer2-->


        <!--climate start here-->

        <!--climate end here-->
      </div>
      <!--inner block end here-->
      <!--copy rights start here-->
      <div class="copyrights">
        <p>© 2020 Lmaniya LLC. All Rights Reserved | <a href="http://lmaniya.org/" target="_blank">Visit</a> </p>
      </div>
      <!--COPY rights end here-->
    </div>
  </div>
  <!--slider menu-->
  <div class="sidebar-menu">
    <div class="logo"> <div  class="sidebar-icon"> <span class="fa fa-bars"></span> </div> <a href="#"> <span id="logo" ></span>
      <!--<img id="logo" http://localhost/djpashupati/api/product/read-product.php="" alt="Logo"/>-->
    </a> </div>
    <div class="menu">
      <ul id="menu" >
        <li id="menu-home" ><a routerLink="/vendor/home"><i class="fa fa-tachometer"></i><span>Dashboard</span></a></li>
        <li><a href="#"><i class="fa fa-cog"></i><span>System</span><span class="fa fa-angle-right" style="float: right"></span></a>
          <ul>
            <li><a href="404.html">Logout</a></li>
            <li><a href="blank.html">Visit Website</a></li>
          </ul>
        </li>


      </ul>
    </div>
  </div>
  <div class="clearfix"> </div>
</div>
<!-- mother grid end here-->
</body>
</html>
