import { Component, OnInit } from '@angular/core';
// import { AuthService } from '../services/auth.service';
import { FormBuilder, FormGroup,Validators } from "@angular/forms";
import { HttpClient } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';

import { CommonModule } from '@angular/common';


@Component({
  selector: 'app-user-change-password',
  templateUrl: './user-change-password.component.html',
  styleUrls: ['./user-change-password.component.scss']
})
export class UserChangePasswordComponent implements OnInit {

 form: FormGroup;
error: any=[];
submitted=false;
loader=false;
pLength:boolean=false;
nameR:boolean=false;
clicked:boolean=false;
checked:boolean=false;
 constructor(
 private router: Router,
     public fb: FormBuilder,
     private http: HttpClient
   ) {

   }
  ngOnInit() {

this.form = this.fb.group({
              email: [''],
              password: [''],
              new_password:[''],
              tag:['change_pass']
     });

  }
  get f() { return this.form.controls; }

  onSubmit(){
  var formData: any = new FormData();

                  formData.append("email", this.form.get('email').value);
                  formData.append("old_password", this.form.get('password').value);
                  formData.append("password", this.form.get('new_password').value);
                  formData.append("tag", this.form.get('tag').value);
                return  this.http.post('http://djpashupati.com/Djpashupati_Api/Customer_forgetpassword.php', formData).subscribe(
                    (response) =>{
//   this.loader=false;
                    this.router.navigateByUrl('/user/login');

                    this.error=response;
              if(this.error.error==true){
                                  this.router.navigateByUrl('/user/login');

          // console.log(this.error.error);
                         }
                    else{
//                     localStorage.setItem("email", this.form.get('email').value);
          //           console.log(localStorage.getItem("email"));
                    this.router.navigateByUrl('/user/login');

                    }
          //           this.error=response;
          //           console.log(response.message)
                    }
                  );
  }
}
