import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router } from '@angular/router';
import {ProductService} from 'src/app/services/product.service';
import {Product} from 'src/app/common/product';
import {Order} from 'src/app/common/order';
import {Vendor} from 'src/app/common/vendor';
// import {MatDialogsModule} from '@angular-material-extensions/core';
// import {MdDialog} from "@angular/material";
// import {MdDialogRef} from "@angular/material";
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup,FormControl } from "@angular/forms";

@Component({
  selector: 'app-today-orders',
  templateUrl: './today-orders.component.html',
  styleUrls: ['./today-orders.component.scss']
})
export class TodayOrdersComponent implements OnInit {

orders:any=[];
admin: string='';
users:[];
term:string;
customer:string;
vorder:string;
vendor:string;
vOrders: any=[];
  p: number = 1;
  q: number = 1;
  r: number = 1;
  s: number = 1;
searches: string[] = [];
  constructor(private _productService: ProductService,
                private _activatedRoute:ActivatedRoute,
                 private http: HttpClient,
                   private router :Router,
) { }

  ngOnInit() {

    let q= localStorage.getItem("isAdminLoggedin");
    this.admin=localStorage.getItem("admin");
    if(q!='true'){
    this.router.navigateByUrl('/admin/index');
    }



var toggle = true;
$(".sidebar-icon").click(function() {
  if (toggle)
  {
    $(".page-container").addClass("sidebar-collapsed").removeClass("sidebar-collapsed-back");
    $("#menu span").css({"position":"absolute"});
  }
  else
  {
    $(".page-container").removeClass("sidebar-collapsed").addClass("sidebar-collapsed-back");
    setTimeout(function() {
      $("#menu span").css({"position":"relative"});
    }, 400);
  }
                toggle = !toggle;
            });
  this.listOrders();

  }

    listOrders(){
    return this.http.get("http://djpashupati.com/djpashupati/api/order/read-today.php").subscribe(data=>{this.orders=data; console.log(data)});
  //   console.log(this.orders);
    }
      print(id){
      localStorage.setItem("abill_number",id);
      return this.router.navigateByUrl('/admin/home/print-bill');
      }
      logout(){
      localStorage.setItem('isAdminLoggedin',"false");
      this.router.navigateByUrl('/admin/index');

      }

}
