import { Component, OnInit } from '@angular/core';
import {ActivatedRoute } from '@angular/router';
import {ProductService} from 'src/app/services/product.service';
import {Product} from 'src/app/common/product';
import {ProductCategory} from 'src/app/common/product-category';
import {ProductSubCategory} from 'src/app/common/product-sub-category';
import { FormBuilder, FormGroup,FormArray } from "@angular/forms";
import { HttpClient } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
@Component({
  selector: 'app-terms-of-service',
  templateUrl: './terms-of-service.component.html',
  styleUrls: ['./terms-of-service.component.scss']
})
export class TermsOfServiceComponent implements OnInit {
terms:any=[''];
  constructor(private _productService: ProductService,
                              private _activatedRoute:ActivatedRoute,
                              public fb:FormBuilder,
                              private http:HttpClient,
                                private router:Router,) { }

  ngOnInit() {
  this.getTerms();

  }
  getTerms(){
   return this.http.get<any>('http://djpashupati.com/Djpashupati_Api/terms_and_condition.php').subscribe(data=>{this.terms=data; });

  }

}
