import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import{Observable} from 'rxjs';
import{map} from 'rxjs/operators';

import{Product} from '../common/product';
import{Order} from '../common/order';
import{Vendor} from '../common/vendor';
import{VendorRequest} from '../common/vendor-request';
import{ProductCategory} from '../common/product-category';
import{ProductSubCategory} from '../common/product-sub-category';
@Injectable({
  providedIn: 'root'
})
export class ProductService {
private baseUrl="http://djpashupati.com:8080/api/v1";

  constructor(private http:HttpClient) { }

  getProductCategory():Observable<ProductCategory[]>{
//   const searchUrl=`${this.baseUrl}/search/categoryid?id=${theCategoryId}`;
return this.http.get<GetResponseProductCategory>(this.baseUrl+'/product-category').pipe(
map(response => response._embedded.productCategory)
);
  }

    listNewProduct():Observable<Product[]>{
  //   const searchUrl=`${this.baseUrl}/search/categoryid?id=${theCategoryId}`;
  return this.http.get<GetResponseProductAll>("http://djpashupati.com/djpashupati/api/product/read-product.php").pipe(
  map(response => response.products)
  );
    }

// http://djpashupati.com:8080/api/v1/product-category/1/subCategory
   getProductSubCategory(id: number):Observable<ProductSubCategory[]>{
    const searchUrl=`http://djpashupati.com/djpashupati/api/sub-category/read-by-category.php/?id=${id}`;
  return this.http.get<GetResponseProductSubCategory>(`${searchUrl}`).pipe(
                                      map(response => response.subCategories)
                                      );
                                        }






// http://djpashupati.com:8080/api/v1/product-category/1/subCategory
   getProductOne(id: number):Observable<any>{
    const searchUrl=`http://djpashupati.com/djpashupati/api/product/read-one-product.php/?id=${id}`;
  return this.http.get<any>(`${searchUrl}`);
                                        }

// http://djpashupati.com:8080/api/v1/product-category/1/subCategory
   searchProduct(keyword: string):Observable<Product[]>{
    const searchUrl=`http://djpashupati.com/djpashupati/api/product/search.php?s=${keyword}`;
  return this.http.get<GetResponseProduct>(`${searchUrl}`).pipe(
                                      map(response => response.records)
                                      );
                                        }



// http://djpashupati.com:8080/api/v1/product-category/1/subCategory
   getOrderDetail(keyword: string):Observable<Order[]>{
   console.log(keyword);
    const searchUrl=`http://djpashupati.com/djpashupati/api/order/read-order-details-by-orderid.php?order_id=${keyword}`;
  return this.http.get<GetResponseOrderDetails>(`${searchUrl}`).pipe(
                                      map(response => response.user_orders)
                                      );
                                        }



                      getMyOrders(keyword: string):Observable<Order[]>{
                         console.log(keyword);
                          const searchUrl=`http://djpashupati.com/djpashupati/api/order/read-one-order.php?user_id=${keyword}`;
                        return this.http.get<GetResponseOrderDetails>(`${searchUrl}`).pipe(
                                                            map(response => response.user_orders)
                                                            );
                                                              }






// http://djpashupati.com:8080/api/v1/product-category/1/subCategory
   getProductAll():Observable<Product[]>{
  return this.http.get<GetResponseProductAll>("http://djpashupati.com/djpashupati/api/product/read-product.php")
  .pipe(map(response => response.products)
    );
      }


// http://djpashupati.com:8080/api/v1/product-category/1/subCategory
   getVendors():Observable<Vendor[]>{
  return this.http.get<GetResponseVendor>("http://djpashupati.com/djpashupati/api/vendor/read.php")
  .pipe(map(response => response.vendors)
    );
      }


//       // http://djpashupati.com:8080/api/v1/product-category/1/subCategory
//          getVendor():Observable<Vendor[]>{
//         return this.http.get<GetResponseVendor>("http://djpashupati.com/djpashupati/api/vendor/read.php")
//         .pipe(map(response => response.vendors)
//           );
//             }

getUsers():Observable<any>{
 return this.http.get<any>("http://djpashupati.com/Djpashupati_Api/fetch_user.php");
 }
            deleteCategory(id: number){
            const d_url="http://djpashupati.com/djpashupati/api/category/delete.php";
            return this.http.delete(`${d_url}/id=${id}`)
             .pipe(map(data => {})).subscribe(result => {
                   console.log(result);
                 });

             }

      // http://djpashupati.com:8080/api/v1/product-category/1/subCategory
         getVendorRequests():Observable<VendorRequest[]>{
        return this.http.get<any>("http://djpashupati.com/djpashupati/api/vendor-request/read.php");
            }



             // http://djpashupati.com:8080/api/v1/product-category/1/subCategory
         getVendorOrders():Observable<Order[]>{
        return this.http.get<GetResponseVendorOrders>("http://djpashupati.com/djpashupati/api/order/read-vendor-product.php")
        .pipe(map(response => response.orders)
          );
            }

                    // http://djpashupati.com:8080/api/v1/product-category/1/subCategory
                       getVendorOrderDetail(vendor_id: string):Observable<Order[]>{
                    return this.http.get<GetResponseOrderDetails>(`http://djpashupati.com/djpashupati/api/order/vendor-order-detail.php?vendor_type=${vendor_id}`)
                    .pipe(map(response => response.user_orders)
                      );
                        }

      // http://djpashupati.com:8080/api/v1/product-category/1/subCategory
         getCategory(id: number):Observable<ProductCategory>{
        return this.http.get<ProductCategory>(`http://djpashupati.com/djpashupati/api/category/read-one.php?id=${id}`);
            }

// http://djpashupati.com:8080/api/v1/product-category/1/subCategory
   getProduct(id: number):Observable<Product>{
    const searchUrl=`http://djpashupati.com/djpashupati/api/product/read-one-product.php?id=${id}`;
  return this.http.get<Product>(`${searchUrl}`);
                                        }
//             get subcategory

 // http://djpashupati.com:8080/api/v1/product-category/1/subCategory
         getSubCategory(id: number):Observable<ProductSubCategory>{
        return this.http.get<ProductSubCategory>(`http://djpashupati.com/djpashupati/api/sub-category/read-by-category.php?id=${id}`);
            }


getEmail():Observable<any>{
return this.http.get<any>("http://djpashupati.com/Djpashupati_Api/fetch_email.php");
}

updateCategory(category: ProductCategory):Observable<void>{

return this.http.put<void>(`http://djpashupati.com/djpashupati/api/category/update.php?id=${category[0].Category_id}`,category, {
headers: new HttpHeaders({
'Content-Type':'application/json'
})
})
}
             // http://djpashupati.com:8080/api/v1/product-category/1/subCategory
                     getCategoryAll():Observable<ProductCategory[]>{
                    return this.http.get<any>("http://djpashupati.com/Djpashupati_Api/main_categories.php");
                        }



            // http://djpashupati.com:8080/api/v1/product-category/1/subCategory
               getSubCategoryAll():Observable<ProductSubCategory[]>{
              return this.http.get<GetResponseSubCategoryAll>("http://djpashupati.com/djpashupati/api/sub-category/read.php")
              .pipe(map(response => response.subCategories)
                );
                  }

// get all orders
getOrders():Observable<Order[]>{
return this.http.get<GetResponseOrderAll>("http://djpashupati.com/djpashupati/api/order/read.php") .pipe(map(response => response.orders)
                                                                                                            );
}




}





interface GetResponseProductCategory{
_embedded:{
productCategory:ProductCategory[];
}
}

interface GetResponseProductSubCategory{
subCategories:ProductSubCategory[];
}
interface GetResponseOrderDetails{
user_orders:Order[];
}


interface GetResponseProduct{
records:Product[];
}

interface GetResponseProductAll{
products:Product[];
}

interface GetResponseOrderAll{
orders:Order[];
}

interface GetResponseCategoryAll{
categories:ProductCategory[];
}

interface GetResponseSubCategoryAll{
subCategories:ProductSubCategory[];
}

interface GetResponseVendor{
vendors:Vendor[];
}

interface GetResponseVendorRequest{
vendorRequests:VendorRequest[];
}

interface GetResponseVendorOrders{
orders:Order[];
}
