import { Component, OnInit } from '@angular/core';
// import { AuthService } from '../services/auth.service';
import { FormBuilder, FormGroup } from "@angular/forms";
import { HttpClient } from '@angular/common/http';
import { first } from 'rxjs/operators';
import{Router} from '@angular/router';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {
form: FormGroup;
 msg: string='';
error: any=[];
 constructor(
     public fb: FormBuilder,
     private http: HttpClient,
     private router: Router,
   ) {
     this.form = this.fb.group({
       email: [''],
       password: [''],
       tag:['forgot_pass']
     })
   }
  ngOnInit() {

  }

  onSubmit(){
  var formData: any = new FormData();

                  formData.append("email", this.form.get('email').value);
                  formData.append("tag", this.form.get('tag').value);
                return  this.http.post('http://djpashupati.com/Djpashupati_Api/Customer_forgetpassword.php', formData).subscribe(
                    (response) =>{
//   this.loader=false;
                    this.error=response;
              if(this.error.error==true){
          // console.log(this.error.error);
                         }
                    else{
//                     localStorage.setItem("email", this.form.get('email').value);
          //           console.log(localStorage.getItem("email"));
                    this.router.navigateByUrl('/new password sent');

                    }
          //           this.error=response;
          //           console.log(response.message)
                    }
                  );
  }

}
