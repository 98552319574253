import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { HomeComponent } from './home/home.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import{ProductService} from './services/product.service';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SubCategoryComponent } from './sub-category/sub-category.component';
import { ProductPageComponent } from './product-page/product-page.component';
import { WelcomePageComponent } from './welcome-page/welcome-page.component';
import { HeaderComponent } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { UserLoginComponent } from './user-login/user-login.component';
import { UserRegisterComponent } from './user-register/user-register.component';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import { CartComponent } from './cart/cart.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { NaComponent } from './na/na.component';
import { IndexComponent } from './admin/index/index.component';
import {NgxPaginationModule} from 'ngx-pagination';
import {NgxPrintModule} from 'ngx-print';

import { AdminHomeComponent } from './admin/admin-home/admin-home.component';
import { CreateProductComponent } from './admin/create-product/create-product.component';
import { CreateCategoryComponent } from './admin/create-category/create-category.component';
import { CreateSubCategoryComponent } from './admin/create-sub-category/create-sub-category.component';
import { RequestVendorComponent } from './admin/request-vendor/request-vendor.component';
// import { EditCategoryComponent } from './admin/edit-category/edit-category.component';
import { SuccessPageComponent } from './success-page/success-page.component';
import { CreateVendorComponent } from './admin/create-vendor/create-vendor.component';
import { MyordersComponent } from './myorders/myorders.component';
import { MyordersDetailsComponent } from './myorders-details/myorders-details.component';
import { VendorHomeComponent } from './vendor/vendor-home/vendor-home.component';
import { VendorLoginComponent } from './vendor/vendor-login/vendor-login.component';
import { OrderDetailsComponent } from './vendor/order-details/order-details.component';
import { SliderComponent } from './admin/slider/slider.component';
import { NgCircleProgressModule } from 'ng-circle-progress';

import { AdminHeaderComponent } from './admin/admin-header/admin-header.component';
import { ChangePasswordComponent } from './admin/change-password/change-password.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { TermsConditionComponent } from './admin/terms-condition/terms-condition.component';
import { AboutUsCreateComponent } from './admin/about-us-create/about-us-create.component';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';
import { EmailComponent } from './admin/email/email.component';
import { BillComponent } from './bill/bill.component';
import { PrintBillComponent } from './admin/print-bill/print-bill.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { SuccessComponent } from './success/success.component';
import { ForgetSuccessComponent } from './forget-success/forget-success.component';
import { UserChangePasswordComponent } from './user-change-password/user-change-password.component';
import { ImageSuccessComponent } from './image-success/image-success.component';
import { PopularProductComponent } from './popular-product/popular-product.component';
import { TodayOrdersComponent } from './admin/today-orders/today-orders.component';
import { TotalBusinessComponent } from './admin/total-business/total-business.component';
export function HttpLoaderFactory(http: HttpClient){
return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AdminHomeComponent,
    ProductDetailComponent,
    PageNotFoundComponent,
    SubCategoryComponent,
    ProductPageComponent,
    WelcomePageComponent,
    HeaderComponent,
    FooterComponent,
    UserLoginComponent,
    UserRegisterComponent,
    EmailVerificationComponent,
    CartComponent,
    CheckoutComponent,
    NaComponent,
    IndexComponent,
    CreateProductComponent,
    CreateCategoryComponent,
    CreateSubCategoryComponent,
    RequestVendorComponent,
//     EditCategoryComponent,
    SuccessPageComponent,
    CreateVendorComponent,
    MyordersComponent,
    MyordersDetailsComponent,
    VendorHomeComponent,
    VendorLoginComponent,
    OrderDetailsComponent,
    SliderComponent,
    AdminHeaderComponent,
    ChangePasswordComponent,
    ContactUsComponent,
    AboutUsComponent,
    UserProfileComponent,
    TermsConditionComponent,
    AboutUsCreateComponent,
    TermsOfServiceComponent,
    EmailComponent,
    BillComponent,
    PrintBillComponent,
    ForgetPasswordComponent,
    SuccessComponent,
    ForgetSuccessComponent,
    UserChangePasswordComponent,
    ImageSuccessComponent,
    PopularProductComponent,
    TodayOrdersComponent,
    TotalBusinessComponent
      ],
  imports: [

BrowserModule,
	    NgxPrintModule,
    ReactiveFormsModule,
    NgxPaginationModule,
Ng2SearchPipeModule,
FormsModule,
    AppRoutingModule,
    HttpClientModule,
        TranslateModule.forRoot({
              loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
              }
            }),
            NgCircleProgressModule.forRoot({
                  // set defaults here
                 radius: 100,
                       outerStrokeWidth: 16,
                       innerStrokeWidth: 8,
                       showTitle: false,
                       showUnits: false,
                       showSubtitle: true,
                       showInnerStroke: false,
                       outerStrokeColor: "#bc2624",
                       innerStrokeColor: "#C7E596",
                       animationDuration: 300,
                })
  ],
  providers: [
  ProductService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
