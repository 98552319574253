import { Component, OnInit } from '@angular/core';
import {ActivatedRoute } from '@angular/router';
import {ProductService} from 'src/app/services/product.service';
import {Product} from 'src/app/common/product';
import {Vendor} from 'src/app/common/vendor';
import {VendorRequest} from 'src/app/common/vendor-request';

import { FormBuilder,FormArray, FormGroup } from "@angular/forms";
import { HttpClient } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
@Component({
  selector: 'app-about-us-create',
  templateUrl: './about-us-create.component.html',
  styleUrls: ['./about-us-create.component.scss']
})
export class AboutUsCreateComponent implements OnInit {
public form: FormGroup;

  constructor(private _productService: ProductService,
                              private _activatedRoute:ActivatedRoute,
                              public fb:FormBuilder,
                              private http:HttpClient,
                                private router:Router,) {



this.form=this.fb.group({
    about_us:[''],


                })}

  ngOnInit() {
  }

  onSubmit(){
     let formObj = this.form.getRawValue(); // {name: '', description: ''}

  let serializedForm = JSON.stringify(formObj);
  console.log(serializedForm);
  this.http.post<any>("http://djpashupati.com/djpashupati/api/admin/about-us.php", serializedForm).subscribe(data=>console.log(data));
//   request goes here
  }

}
