import { Component, OnInit } from '@angular/core';
// import { AuthService } from '../services/auth.service';
import { FormBuilder, FormGroup } from "@angular/forms";
import { HttpClient } from '@angular/common/http';
import { first } from 'rxjs/operators';
import{Router} from '@angular/router';

@Component({
  selector: 'app-vendor-login',
  templateUrl: './vendor-login.component.html',
  styleUrls: ['./vendor-login.component.scss']
})
export class VendorLoginComponent implements OnInit {
form: FormGroup;
 msg: any='';
error: any=[];
isVendorLoggedin: boolean=false;
 constructor(
     public fb: FormBuilder,
     private http: HttpClient,
     private router: Router,
   ) {
     this.form = this.fb.group({
       vendor_email: [''],
       vendor_password: ['']
//        tag:['resend_code']
     })
   }
  ngOnInit() { }



  onSubmit() {
  let formObj = this.form.getRawValue(); // {name: '', description: ''}

let serializedForm = JSON.stringify(formObj);
console.log(serializedForm);
        this.http.post('http://djpashupati.com/djpashupati/api/vendor/vendor-login.php', serializedForm).subscribe(
          (response) =>{
          this.error=response
          if(this.error.error==false){
//             console.log(this.error)
            this.isVendorLoggedin=true;
            localStorage.setItem("isVendorLoggedin", 'true');
            localStorage.setItem("vendor_id", this.error.id);
            localStorage.setItem("vendor_name", this.error.vendor_name);

this.router.navigateByUrl('/vendor/home');
          }else{
          this.msg=response
          console.log(this.msg);
          }
           }

        );

        }
// end of onSubmit


}
