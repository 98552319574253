import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router } from '@angular/router';
import {ProductService} from 'src/app/services/product.service';
import {Product} from 'src/app/common/product';
import {Order} from 'src/app/common/order';
import {Vendor} from 'src/app/common/vendor';
// import {MatDialogsModule} from '@angular-material-extensions/core';
// import {MdDialog} from "@angular/material";
// import {MdDialogRef} from "@angular/material";
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.scss']
})
export class AdminHeaderComponent implements OnInit {
admin: string;
  constructor(  public translate: TranslateService,
                   private http: HttpClient,
                   private router: Router,) {
                      translate.setDefaultLang('en');

                    }

  ngOnInit() {
        this.admin=localStorage.getItem("admin");

  }
logout(){
localStorage.setItem('isAdminLoggedin',"false");
this.router.navigateByUrl('/admin/index');

}

switchLang(language: string){
this.translate.use(language);

}
}
