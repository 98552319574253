
<!DOCTYPE HTML>
<html>
<head>
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  Smartphone Compatible web template, free webdesigns for Nokia, Samsung, LG, SonyEricsson, Motorola web design" />
  <script type="application/x-javascript"> addEventListener("load", function() { setTimeout(hideURLbar, 0); }, false); function hideURLbar(){ window.scrollTo(0,1); } </script>

  <link href='//fonts.googleapis.com/css?family=Carrois+Gothic' rel='stylesheet' type='text/css'>
  <link href='//fonts.googleapis.com/css?family=Work+Sans:400,500,600' rel='stylesheet' type='text/css'>
  <!--static chart-->
</head>
<body>
<div class="login-page">
  <div class="login-main">
    <div class="login-head">
      <h1>Login</h1>
    </div>

    <div class="login-block">
      <form [formGroup]="form"
            name="form"
            (ngSubmit)="onSubmit()">
        <input type="text" name="email" placeholder="Email" formControlName="vendor_email" required="">
        <input type="password" name="password" class="lock" formControlName="vendor_password" placeholder="Password">
        <div class="forgot-top-grids">
          <div class="forgot-grid">
            <ul>
              <li>
                <input type="checkbox" id="brand1" value="">
                <label for="brand1"><span></span>Remember me</label>
              </li>
            </ul>
          </div>
          <div class="forgot">
            <a href="#">Forgot password?</a>
          </div>
          <div class="clearfix"> </div>
        </div>
        <input type="submit" name="Sign In" value="Login">


      </form>
      <div class="span m-auto" style="color:red;">
        {{msg.message}}
      </div>
    </div>
  </div>
</div>
<!--inner block end here-->
<!--copy rights start here-->
<div class="copyrights">
  <p>© 2020 Lmaniya LLC Pvt. Ltd. All Rights Reserved </p>
</div>
<!--COPY rights end here-->

<!--scrolling js-->

<!-- mother grid end here-->
</body>
</html>



