import { Injectable } from '@angular/core';
import{Subject} from 'rxjs';
import {Cart} from 'src/app/common/cart';
@Injectable({
  providedIn: 'root'
})
export class CartService {

cartItems: Cart[] = [];
totalPrice: Subject<number>=new Subject<number>();
totalQuantity: Subject<number>=new Subject<number>();
cartProduct: Subject<string>=new Subject<string>();

  constructor() { }


  addToCart(theCartItem: Cart){
  console.log(theCartItem);

//   Check for cartItems
let alreadyExistInCart: boolean=false;
let existingCartItem: Cart = undefined;
if(this.cartItems.length>0){
// find the item
existingCartItem=this.cartItems.find(tempCart => tempCart.Product_id === theCartItem.Product_id);
alreadyExistInCart=(existingCartItem!=undefined);
console.log(theCartItem.Product_id);
}

if(alreadyExistInCart){
// increment the item number
console.log(theCartItem.Product_id);
// existingCartItem.Product_quantity;
}

else{
this.cartItems.push(theCartItem);
}

this.calculateTotalPrice();
  }

  calculateTotalPrice(){
  let totalPriceValue: number=0;
  let totalQuantityValue: number=0;
  for(let currentCartItem of this.cartItems){
  totalPriceValue+=currentCartItem.Product_quantity * currentCartItem.Product_price;
  totalQuantityValue+=currentCartItem.Product_quantity;
  }

//   console.log(`total price: ${totalPriceValue}, total quantity: ${totalQuantityValue}`);
  this.totalPrice.next(totalPriceValue);
  this.totalQuantity.next(totalQuantityValue);

  }

  decrementQuantity(cartItem: Cart){
//   cartItem.Product_quantity--;
  if(cartItem.Product_quantity===0){

  this.remove(cartItem);
  }else{
  this.calculateTotalPrice();
  }
  }

  remove(cartItem: Cart){
  const itemIndex=this.cartItems.findIndex((tempCart)=>tempCart.Product_id===cartItem.Product_id);
  if(itemIndex> -1){
  this.cartItems.splice(itemIndex,1);
  this.calculateTotalPrice();
  }
  }
}
