import { Component, OnInit } from '@angular/core';
import {ActivatedRoute } from '@angular/router';
import {ProductService} from 'src/app/services/product.service';
import {Product} from 'src/app/common/product';
import {ProductCategory} from 'src/app/common/product-category';
import {ProductSubCategory} from 'src/app/common/product-sub-category';
import { FormBuilder, FormGroup,Validators } from "@angular/forms";
import { HttpClient } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
@Component({
  selector: 'app-create-sub-category',
  templateUrl: './create-sub-category.component.html',
  styleUrls: ['./create-sub-category.component.scss']
})
export class CreateSubCategoryComponent implements OnInit {
categories: ProductCategory[];
subCategories: ProductSubCategory[];
form: FormGroup;

subcategory: ProductSubCategory;
error:any=[];
subcat:string;
p:number=1;
submitted=false;
loader=false;

  constructor(private _productService: ProductService,
                private _activatedRoute:ActivatedRoute,
                public fb:FormBuilder,
                private http:HttpClient,private router :Router,
                ) {




                }
    get f() { return this.form.controls; }

  ngOnInit() {



  this._activatedRoute.paramMap.subscribe(params=>{
  const categoryId=+params.get('id');
  if(categoryId){
  this.getSubCat(categoryId);
  }
  });



    this.form=this.fb.group({
                  subcategory_name:['',Validators.required],

                  category_id:[''],
               image_url:['']

                  });


  let q= localStorage.getItem("isAdminLoggedin");
  if(q!='true'){
  this.router.navigateByUrl('/admin/index');
  }
var toggle = true;

$(".sidebar-icon").click(function() {
  if (toggle)
  {
    $(".page-container").addClass("sidebar-collapsed").removeClass("sidebar-collapsed-back");
    $("#menu span").css({"position":"absolute"});
  }
  else
  {
    $(".page-container").removeClass("sidebar-collapsed").addClass("sidebar-collapsed-back");
    setTimeout(function() {
      $("#menu span").css({"position":"relative"});
    }, 400);
  }
                toggle = !toggle;
            });


// list the products available in table
//   this.listProducts();
this.listCategory();
this.listSubCategory();
  }

//   listProducts(){
//   this._productService.getProductAll().subscribe(data =>{this.products=data;
//   console.log(this.products);
//   },
//   error=>{
//   console.error(error);
//   }
//   );
//
//   }


  listCategory(){
    this._productService.getCategoryAll().subscribe(data =>{this.categories=data;
    console.log(this.categories);
    },
    error=>{
    console.error(error);
    }
    );

    }

getSubCat(id: number){
this._productService.getSubCategory(id).subscribe(
(subcats: ProductSubCategory)=>{this.editsubCategory(subcats);
this.subcategory=subcats;
},
(err:any)=>console.log(err)
);

  }


editsubCategory(subcats: ProductSubCategory){
console.log(subcats);

this.form.patchValue({
subcategory_name: subcats[0].Subcategory_name,
});

}




    listSubCategory(){
      this._productService.getSubCategoryAll().subscribe(data =>{this.subCategories=data;
      console.log(this.subCategories);
      },
      error=>{
      console.error(error);
      }
      );

      }
 onFileChanged(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form.get('image_url').setValue(file);
    }
  }


logout(){
localStorage.setItem('isLoggedIn',"false");
this.router.navigateByUrl('/admin/index');

}
MapFormValueToCategoryModel(){
this.subcategory.Subcategory_name=this.form.value.subcategory_name;
}


  onSubmit(){

           this.submitted = true;
             if (this.form.invalid) {
                      return;
              }else{
                 this._activatedRoute.paramMap.subscribe(params=>{
                   const categoryId=+params.get('id');
                      if(categoryId){
                          this.MapFormValueToCategoryModel();
                              if(this.subcategory[0].Subcategory_id){

                                  var formData:any=new FormData();
                                        formData.append("Subcategory_name",this.form.get('subcategory_name').value);
                                         formData.append("Subcategory_id",this.subcategory[0].Subcategory_id);
                                           formData.append("Image",this.form.get('image_url').value);
//                                            formData.append("Category_id",this.form.get('category_id').value);
//                                             console.log(...formData);
                                            this.http.post<any>('http://djpashupati.com/djpashupati/api/sub-category/update.php', formData).subscribe(()=>this.router.navigate(['/admin/home/create-sub-category']),(error: any)=>this.router.navigate(['/admin/home/create-sub-category']));
                              }
                      }else{

                      var formData:any=new FormData();
                                              formData.append("Subcategory_name",this.form.get('subcategory_name').value);
                                                 formData.append("Image",this.form.get('image_url').value);
                                                 formData.append("Category_id",this.form.get('category_id').value);
                                                this.http.post<ProductSubCategory[]>('http://djpashupati.com/djpashupati/api/sub-category/add-sub-category.php',formData)
                                                 .subscribe(()=>{ this.submitted = false;
                                                          this.listCategory();
                                                          this.listSubCategory();
                                                          this.form.reset();
                                                             },
                                                         (error: any)=>{
                                                         this.submitted = false;
                                                              this.listCategory();
                                                              this.listSubCategory();
                                                              this.form.reset();
                                                         });



                      }


                });


           }

}


    delete(id){
    var detail={id: id};

   // console.log('remove', cartItem);
  //  return this._productService.deleteCategory(id);
  this.http.post('http://djpashupati.com/djpashupati/api/sub-category/delete.php',detail)
  .subscribe();
  return this.ngOnInit();

    console.log(detail);
    }


edit(subCategoryId:number){
this.router.navigate(['/admin/home/edit-subcategory', subCategoryId]);


}

}
