import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import {CartService} from '../services/cart.service';
import {Cart} from '../common/cart';
import {Order} from '../common/order';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

// import {randomString} from 'randomstring';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
checkOutFormGroup: FormGroup;
cartItems: Cart[]=[];
orderItems: Order[]=[];
totalPrice: number=0;
totalQuantity: number=0;
  constructor(private _fb: FormBuilder,
              private _cartService: CartService, private http: HttpClient,private router: Router,

              ) { }

  ngOnInit() {

 let uid=localStorage.getItem("user_id");
  let user_name=localStorage.getItem("user_name");
  let user_email=localStorage.getItem("user_email");
  let orderId = localStorage.getItem("bill_number");
  if(orderId==''){
  this.makeid();
  }

//     console.log(orderId);
//     console.log(user_name);
//     console.log(user_email);
  this.cartDetails();
  this.checkOutFormGroup = this._fb.group({
  customer: this._fb.group({
  Customer_name:[user_name],
  Customer_email:[user_email],
  Customer_phone:[''],
  Customer_address:[''],
  customer_id:[uid],
  Bill_number:[orderId],

  Checkout_status:'checkout',
  Payment_status:'Cash on Delivery',
  Order_status:'Pending',
  })

//    shippingAddress: this._fb.group({
//     city:[''],
//     municipality:[''],
//     tole:[''],
//     houseNumber:[''],
//     }),
//
//
//      billingAddress: this._fb.group({
//         city:[''],
//         municipality:[''],
//         tole:[''],
//         houseNumber:[''],
//         })

  });
  }


fillUser(){



}





 makeid() {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < 8; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
      if(localStorage.getItem("bill_number")==''){
        localStorage.setItem("bill_number",text);
//         console.log(text);
      }else{
//       console.log(localStorage.getItem("orderId"));
      }

//     return text;
  }
onSubmit(){
// this.orderItems=this.cartItems;
let orderedItems = JSON.stringify(this.cartItems);
// console.log(orderedItems);
let order=JSON.stringify(this.checkOutFormGroup.get('customer').value);

// console.log(order);
this.http.post<any>("http://djpashupati.com/djpashupati/api/order/place-order-detail.php",order).subscribe(()=>
{
this._cartService.cartItems=[];
const orderId=localStorage.getItem("bill_number");
localStorage.setItem("ordered",'true');
  localStorage.setItem("bill_number",'');
  this.makeid();
this.router.navigate(['/home/bill',orderId]);

},
(error)=>{
this._cartService.cartItems=[];
const orderId=localStorage.getItem("bill_number");
localStorage.setItem("ordered",'true');
  localStorage.setItem("bill_number",'');
  this.makeid();
this.router.navigate(['/home/bill',orderId]);
}
);
// // this.http.post<any>("http://djpashupati.com/Djpashupati_Api/add_cart.php",orderedItems).subscribe(data=>console.log(JSON.stringify(data)));





}


// copyShippingAddress(event){
// if(event.target.checked){
// this.checkOutFormGroup.controls.billingAddress
//     .setValue(this.checkOutFormGroup.controls.shippingAddress.value);
// }else{
// this.checkOutFormGroup.controls.billingAddress.reset();
// }
// }

 cartDetails(){
//   this.cartItems=this._cartService.cartItems;
    var formData2: any=new FormData();
    let uid=localStorage.getItem('user_id');
        formData2.append("users_id", uid);
this.http.post<any>('http://djpashupati.com/Djpashupati_Api/user_cart_view.php',formData2).subscribe(data=>this.cartItems=data);
  this._cartService.totalPrice.subscribe(
  data=> this.totalPrice=data);

  this._cartService.totalQuantity.subscribe(
  data=> this.totalQuantity=data);


  this._cartService.calculateTotalPrice();
//   return this.ngOnInit();

  }

total(bills) {
            var total = 0;
            bills.forEach(element => {
total = total + (element.Product_quantity*element.Product_price);
            });
            return total;
            }
}
