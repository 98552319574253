import {Product} from './product';
export class Cart {
Product_id: string;
Product_name: string;
description: string;
Product_price: number;
Product_image: string;
Product_quantity: number;
users_id: string;
Wt_unit: string;

constructor(product: Product, uid: string){
this.Product_id=product.Product_id;
this.Product_name=product.Product_name;
this.description=product.Description;
this.Product_price=product.Final_price;
this.Product_image=product.Image;
this.Product_quantity=1;
this.users_id=uid;
this.Wt_unit=product.Wt_unit;

}

}
