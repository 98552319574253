import { Component, OnInit } from '@angular/core';
// import { AuthService } from '../services/auth.service';
import { FormBuilder, FormGroup,Validators } from "@angular/forms";
import { HttpClient } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';


@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
 form: FormGroup;
msg:string='';
submitted=false;
loader=false;
  constructor( public fb: FormBuilder,
                   private http: HttpClient, private router:Router,) {



                    }

  ngOnInit() {
   this.form = this.fb.group({
                             name: ['',Validators.required],
                             email: ['',Validators.required],
                             phone: ['',Validators.required],
                             message: ['',Validators.required],
                           });
  }
      get f() { return this.form.controls; }


onSubmit(){
 this.submitted = true;
 if (this.form.invalid) {
            return;
        }else{
        let formObj = this.form.getRawValue(); // {name: '', description: ''}
        let serializedForm = JSON.stringify(formObj);

this.form.reset();
this.msg='Success!';
this.http.post<any>("http://djpashupati.com/Djpashupati_Api/contact_to_dj.php",serializedForm).subscribe();
console.log(serializedForm);

}

}


}
