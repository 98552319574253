<app-header></app-header>

<div class="form_wrapper " >
  <div class="form_container" >
    <div class="title_container">
      <h2>Verify</h2>
      <h4 style="text-align:center;ite">Enter the code below sent to your mail.</h4>
      <div *ngIf="error" class="badge badge-danger">{{error.message}}</div>
    </div>
    <div class="row clearfix">
      <div >
        <form  [formGroup]="form"
               name="form"
               (ngSubmit)="onSubmit()"
        >
          <div class="input_field">
            <input type="number" name="otp" placeholder="Code" required formControlName="otp" maxlength="6"/>

          </div>











          <input class="button" type="submit" value="Register" />
        </form>
<!--        <div class="alert alert-success" *ngIf="isSuccessful">-->
<!--          Your registration is successful!-->
<!--        </div>-->

      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>

