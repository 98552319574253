import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router } from '@angular/router';
import {ProductService} from 'src/app/services/product.service';
import {Product} from 'src/app/common/product';
import {Order} from 'src/app/common/order';
import {Vendor} from 'src/app/common/vendor';
// import {MatDialogsModule} from '@angular-material-extensions/core';
// import {MdDialog} from "@angular/material";
// import {MdDialogRef} from "@angular/material";
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup,FormControl } from "@angular/forms";

@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.scss']
})
export class AdminHomeComponent implements OnInit {
form: FormGroup;
 msg: any='';
error: any=[];
isVendorLoggedin: boolean=false;
products: Product[];
orders:any=[];
vendors: Vendor[];
admin: string='';
users:[];
term:string;
customer:string;
vorder:string;
vendor:string;
vOrders: any=[];
  p: number = 1;
  q: number = 1;
  r: number = 1;
  s: number = 1;
searchField: FormControl;
searches: string[] = [];
  constructor(private _productService: ProductService,
                private _activatedRoute:ActivatedRoute,
                 private http: HttpClient,
                   private router :Router,
) { }

  ngOnInit() {



  this.searchField = new FormControl();
      this.searchField.valueChanges
          .subscribe(term => {
            this.searches.push(term);
          });

// console.log(this.searchField.value);


  let a= localStorage.getItem("admin_email");
  let p= localStorage.getItem("admin_password");
  var formData:any=new FormData();
  formData.append('email',a);
  formData.append('password',p);
// console.log(...formData);
   this.http.post('http://djpashupati.com/djpashupati/api/admin/admin-check.php', formData).subscribe(
           (response) => {                           //Next callback
                                                                                   console.log('response received')

           this.router.navigateByUrl('/admin/home');

                                                                                 },
                                                                                 (error) => {                              //Error callback
           //                                                                         console.error('Nothing to Show..')
                                                                                  this.router.navigateByUrl('/admin/index');


                                                                                   //throw error;   //You can also throw the error to a global error handler
                                                                                 }

          );
//
//   let q= localStorage.getItem("isAdminLoggedin");
//   this.admin=localStorage.getItem("admin");
//   if(q!='true'){
//   this.router.navigateByUrl('/admin/index');
//   }

var toggle = true;
$(".sidebar-icon").click(function() {
  if (toggle)
  {
    $(".page-container").addClass("sidebar-collapsed").removeClass("sidebar-collapsed-back");
    $("#menu span").css({"position":"absolute"});
  }
  else
  {
    $(".page-container").removeClass("sidebar-collapsed").addClass("sidebar-collapsed-back");
    setTimeout(function() {
      $("#menu span").css({"position":"relative"});
    }, 400);
  }
                toggle = !toggle;
            });


// list the products available in table
  this.listProducts();
  this.listOrders();
  this.listVendors();
  this.listUsers();
this.listVendorRequests();
  }

listVendors(){
this._productService.getVendors().subscribe(data=>this.vendors=data);
// console.log(this.vendors);
}

   listVendorRequests(){
      this._productService.getVendorRequests().subscribe(data =>{this.vOrders=data;
      console.log(data);
      },
      error=>{
      console.error(error);
      }
      );

      }

listUsers(){
this._productService.getUsers().subscribe(data=>this.users=data);
}

  listOrders(){
  return this.http.get("http://djpashupati.com/djpashupati/api/order/read.php").subscribe(data=>{this.orders=data; console.log(data)});
//   console.log(this.orders);
  }

  listProducts(){
  this._productService.getProductAll().subscribe(data =>{this.products=data;
//   console.log(this.products);
  },
  error=>{
  console.error(error);
  }
  );

  }

//   ship order to customer
shipOrder(order_id: string){

  this.http.post('http://djpashupati.com/djpashupati/api/order/ship-order.php', {"order_id": order_id}).subscribe();
  this.listOrders();
return this.ngOnInit();

}
// request to vendor for products
orderVendor(order_id: string){
  this.http.post('http://djpashupati.com/djpashupati/api/order/order-vendor.php', {"order_id": order_id}).subscribe();
  this.listOrders();
  return this.router.navigateByUrl('/admin/home');
}


logout(){
localStorage.setItem('isAdminLoggedin',"false");
this.router.navigateByUrl('/admin/index');

}

delete(id){
// console.log(id);
var formData:any=new FormData();
formData.append('id',id);


// console.log(...formData);
 this.http.post<any>('http://djpashupati.com/djpashupati/api/order/delete.php',formData).subscribe();
return this.ngOnInit();
}

print(id){
localStorage.setItem("abill_number",id);
return this.router.navigateByUrl('/admin/home/print-bill');
}
deleteV(id){
// console.log(id);
var formData:any=new FormData();
formData.append('id',id);


// console.log(...formData);
 this.http.post<any>('http://djpashupati.com/djpashupati/api/vendor-request/delete.php',formData).subscribe();
return this.ngOnInit();
}

search(keyword: string){
// var formData:any=new FormData();
// formData.append('search_query',keyword);
// console.log(...formData);

this.http.post<any>("http://djpashupati.com/Djpashupati_Api/search_user.php",{'search_query':keyword}).subscribe(data=>console.log(data));

}
deleteVendor(id){
// console.log(id);
var formData:any=new FormData();
formData.append('id',id);


// console.log(...formData);
 this.http.post<any>('http://djpashupati.com/djpashupati/api/vendor/delete.php',formData).subscribe();
return this.ngOnInit();
}

}
