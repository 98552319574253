import { Component, OnInit } from '@angular/core';
// import { AuthService } from '../services/auth.service';
import { FormBuilder, FormGroup } from "@angular/forms";
import { HttpClient } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';



@Component({
  selector: 'app-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss']
})
export class EmailVerificationComponent implements OnInit {
 form: FormGroup;
error: any=[];
email:string;
 constructor(
 private router: Router,
     public fb: FormBuilder,
     private http: HttpClient
   ) {
     this.form = this.fb.group({
       otp: [''],
       tag:['verify_code'],
       email:[''],

     })
   }
  ngOnInit() {

  this.email=localStorage.getItem("email");
//   console.log(this.email);

  }



  onSubmit() {
    var formData: any = new FormData();
        formData.append("tag", this.form.get('tag').value);
        formData.append("otp", this.form.get('otp').value);
        formData.append("email", this.email);
//         console.log('the email: '+this.form.get('email').value);
//         console.log('the code: '+this.form.get('otp').value);
        this.http.post<any>('http://djpashupati.com/Djpashupati_Api/Customer_verification.php', formData).subscribe(
          (response) =>{
this.error=response
// console.log(response)
if(this.error.error==false){
localStorage.setItem('isLoggedIn', "true")
          localStorage.setItem('user_id', this.error.id)
            localStorage.setItem('user_id', this.error.user.uid)
          localStorage.setItem('user_name', this.error.user.name)
          localStorage.setItem('user_email', this.error.user.email)

          this.router.navigate(['/home'])

}
          }
        );
  }

}
