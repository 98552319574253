import { Component, OnInit } from '@angular/core';
import {Order} from 'src/app/common/order';
import {ActivatedRoute, Router } from '@angular/router';
import {ProductService} from 'src/app/services/product.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-myorders',
  templateUrl: './myorders.component.html',
  styleUrls: ['./myorders.component.scss']
})
export class MyordersComponent implements OnInit {
orders:Order[]=[];
p:number=1;
loader:boolean=true;
orderAvailable: boolean=false;
  constructor(private _productService: ProductService,
  private router :Router,
                              private _activatedRoute:ActivatedRoute,
                                   private http: HttpClient,
) { }

  ngOnInit() {
    this.listOrders();

  }

  listOrders(){
  let uid=localStorage.getItem("user_id");
 var formData: any = new FormData();
        formData.append("customer_id", uid);
        console.log(...formData);
  this.http.post<any>('http://djpashupati.com/Djpashupati_Api/Customer_my_order.php', formData).subscribe(data=>{this.orders=data; console.log(data)}

  );
  this.loader=false;


//   console.log(this.orders);
//   this.http.post('http://djpashupati.com/djpashupati/api/order/read-one-order.php?user_id='+uid).subscribe(data=>this.orders=data.user_orders);
//   console.log(this.orders);
  }

cancelOrder(order_id:string){
// let orderid= JSON.stringify(order_id);
// console.log(order_id);
var formData: any = new FormData();
        formData.append("id", order_id);
  this.http.post('http://djpashupati.com/djpashupati/api/order/cancel.php', formData).subscribe();
  this.listOrders();
  return this.ngOnInit();




}

deleteO(order_id){
var formData: any = new FormData();
        formData.append("id", order_id);
  this.http.post('http://djpashupati.com/djpashupati/api/order/delete.php', formData).subscribe(data=>console.log(data));
  this.listOrders();
return this.ngOnInit();
}


//   gotoDetails(orderId: number){
//   this.router.navigate([''])
//   }
}
