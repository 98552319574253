import { Component, OnInit } from '@angular/core';
// import { AuthService } from '../services/auth.service';
import { FormBuilder, FormGroup,Validators } from "@angular/forms";
import { HttpClient } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';

import { CommonModule } from '@angular/common';



@Component({
  selector: 'app-register',
  templateUrl: './user-register.component.html',
  styleUrls: ['./user-register.component.scss']
})
export class UserRegisterComponent implements OnInit {
 form: FormGroup;
error: any=[];
submitted=false;
loader=false;
pLength:boolean=false;
nameR:boolean=false;
clicked:boolean=false;
checked:boolean=false;
 constructor(
 private router: Router,
     public fb: FormBuilder,
     private http: HttpClient
   ) {

   }
  ngOnInit() {

this.form = this.fb.group({
       name: ['',Validators.required],
       email: ['',[Validators.required, Validators.email]],
       password: ['',[Validators.required, Validators.minLength(6)]],
     });

  }

    get f() { return this.form.controls; }


  onSubmit() {
  this.clicked=true;
if(this.checked){
        this.submitted = true;

 if (this.form.invalid) {
            return;
        }else{
                   this.loader=true;

          var formData: any = new FormData();
                formData.append("name", this.form.get('name').value);
                formData.append("password", this.form.get('password').value);
                formData.append("email", this.form.get('email').value);
              return  this.http.post('http://djpashupati.com/Djpashupati_Api/Customer_register.php', formData).subscribe(
                  (response) =>{
this.loader=false;
                  this.error=response;
            if(this.error.error==true){
        // console.log(this.error.error);
                       }
                  else{
                  localStorage.setItem("email", this.form.get('email').value);
        //           console.log(localStorage.getItem("email"));
                  this.router.navigateByUrl('/email-verification');

                  }
        //           this.error=response;
        //           console.log(response.message)
                  }
                );

        }


        }


  }

  check(event){
  if(event.target.checked){
  this.checked=true;
  }else{
    this.checked=false;


  }

  }

}
