import { Component, OnInit } from '@angular/core';
// import { AuthService } from '../services/auth.service';
import { FormBuilder, FormGroup } from "@angular/forms";
import { HttpClient } from '@angular/common/http';
import { first } from 'rxjs/operators';
import{Router} from '@angular/router';


@Component({
  selector: 'app-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.scss']
})

export class UserLoginComponent implements OnInit {
 form: FormGroup;
 msg: string='';
error: any=[];
 constructor(
     public fb: FormBuilder,
     private http: HttpClient,
     private router: Router,
   ) {
     this.form = this.fb.group({
       email: [''],
       password: [''],
       tag:['resend_code']
     })
   }
  ngOnInit() {


  this.makeid();

  }

 makeid() {
    var text = "#";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < 8; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
      if(localStorage.getItem("bill_number")==''){
        localStorage.setItem("bill_number",text);
        console.log(text);
      }else{
//       console.log(localStorage.getItem("orderId"));
      }

//     return text;
  }

  onSubmit() {
          localStorage.setItem("email", this.form.get('email').value);
// console.log(localStorage.getItem("email"));
    var formData: any = new FormData();
        formData.append("password", this.form.get('password').value);
        formData.append("email", this.form.get('email').value);
        this.http.post('http://djpashupati.com/Djpashupati_Api/Customer_login.php', formData).subscribe(
          (response) =>{
          this.error=response
//           console.log(this.error.message)
          if(this.error.user.verified==0){
          this.next();
          }else if(this.error.user.verified==1){
          localStorage.setItem('isLoggedIn', "true")
          localStorage.setItem('user_id', this.error.user.uid)
          localStorage.setItem('user_name', this.error.user.name)
          localStorage.setItem('user_email', this.error.user.email)

          this.router.navigate(['/home']);
          }
           }
        );}
// end of onSubmit

next(){
var formData2: any=new FormData();
  formData2.append("email", this.form.get('email').value);
  formData2.append("tag", this.form.get('tag').value);
 this.http.post('http://djpashupati.com/Djpashupati_Api/Customer_verification.php', formData2).subscribe(
          (response) =>{
          this.error=response;
//           console.log(this.error.message);
          this.msg=this.error.message;
          this.router.navigate(['/email-verification']);
           }
        );


}

}


