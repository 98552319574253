import { Component, OnInit } from '@angular/core';
import {Order} from 'src/app/common/order';
import {ActivatedRoute, Router } from '@angular/router';
import {ProductService} from 'src/app/services/product.service';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';

@Component({
  selector: 'app-myorders-details',
  templateUrl: './myorders-details.component.html',
  styleUrls: ['./myorders-details.component.scss']
})
export class MyordersDetailsComponent implements OnInit {
orders:Order[]=[];
order_id: string;
p:number=1;

  constructor(private _productService: ProductService,
                private router :Router,  private location: Location,
                                            private _activatedRoute:ActivatedRoute,
                                                 private http: HttpClient,) { }

  ngOnInit() {
  this.getDetail();
  }


  goPrevious(){
    this.location.back();
    }

getDetail(){
  this.order_id=this._activatedRoute.snapshot.paramMap.get('order_id');
  this._productService.getOrderDetail(escape(this.order_id)).subscribe(
  data=>this.orders=data
  );
//   console.log(order_id);
//   let url="http://djpashupati.com/djpashupati/api/order/read-order-details-by-orderid.php?order_id="+order_id+"hello";
// //  console.log("order"+order_id);
//     this.http.post(url).subscribe(data=>console.log(data));

}
}
