<app-header></app-header>
<header>
  <nav><i class="mdi mdi-chevron-double-left"></i><a (click)="goPrevious()">Go Back</a> </nav>
</header>
<style>
/* ================= lists ================= */
[class*="list-"] li:after {
  visibility: hidden;
  display: block;
  content: "";
  overflow: hidden;
  height: 0;
  clear: both; }

.list-icon {
  list-style: none;
  padding-left: 0; }
  .list-icon li {
    margin-bottom: 7px;
    position: relative;
    padding-left: 30px; }
  .list-icon .icon {
    width: 22px;
    margin-right: 15px;
    vertical-align: middle;
    text-align: center;
    color: #12225b;
    position: absolute;
    top: 3px;
    left: 0; }
  .list-icon span {
    vertical-align: middle; }

.list-bullet {
  list-style: none;
  padding-left: 0; }

.list-bullet li {
  margin-bottom: 5px;
  position: relative;
  padding-left: 15px; }

.list-bullet li::before {
  content: " ";
  position: absolute;
  top: 8px;
  left: 0;
  width: 7px;
  border-radius: 100%;
  height: 7px;
  background: #ced4da; }

.list-check {
  padding-left: 0;
  list-style: none; }
  .list-check > li {
    position: relative;
    padding-left: 24px;
    margin-bottom: 7px; }
  .list-check > li::before {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    font-size: 13px;
    color: #00b517;
    position: absolute;
    top: 3px;
    left: 0;
    content: "\f00c"; }

.list-normal {
  list-style: none;
  padding-left: 0; }
  .list-normal li {
    padding-left: 1em;
    margin-bottom: 7px; }
  .list-normal li:before {
    content: "\2022";
    color: #ff6a00;
    display: inline-block;
    width: 1em;
    margin-left: -1em; }

.list-menu {
  list-style: none;
  margin: 0;
  padding-left: 0; }
  .list-menu li {
    margin-bottom: 5px; }
  .list-menu a {
    color: #212529; }
    .list-menu a:hover {
      color: #ff6a00; }

.cols-two {
  -webkit-column-count: 2;
          column-count: 2; }

.cols-three {
  -webkit-column-count: 3;
          column-count: 3; }

.cols-four {
  -webkit-column-count: 4;
          column-count: 4; }

/* IF CARD IS LINKED */
a[class*='card'] {
  color: initial; }
  a[class*='card']:hover .title {
    color: #ff6a00; }

/* BASE STYLE FOR PRODUCT ITEM */
[class*='card-product'] a.title {
  color: #212529;
  display: block; }
  [class*='card-product'] a.title:hover {
    color: #ff6a00; }
[class*='card-product'] .img-wrap .badge {
  top: 10px;
  left: 10px;
  position: absolute; }
[class*='card-product'] p {
  margin-bottom: 0; }
[class*='card-product'] .img-wrap {
  overflow: hidden;
  position: relative; }
  [class*='card-product'] .img-wrap img {
    height: 100%;
    max-width: 100%;
    width: auto;
    display: inline-block;
    -o-object-fit: cover;
       object-fit: cover; }


.home-category-banner {
  position: relative;
  padding: 30px;
  height: 100%;
  overflow: hidden; }
  .home-category-banner .img-bg {
    position: absolute;
    right: -50px;
    bottom: -50px;
    mix-blend-mode: multiply;
    height: 260px; }

.card-home-category .item .card-body::after {
  content: '';
  display: table;
  clear: both; }
.card-home-category .item .title {
  min-height: 40px;
  font-weight: 500; }
.card-home-category .item:hover img {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
  -webkit-transition: .5s;
  transition: .5s; }


.thumbnail
{
    margin-bottom: 20px;
    padding: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
}

.item
{
    float: none;
    margin-bottom: 10px;
    border-radius:10px;
}


.item .list-group-image
{
    border-radius:10px;
    width:150px;
    margin-right: 10px;
}
.item .thumbnail
{
    margin-bottom: 0px;
}
.item .caption
{
    padding: 9px 9px 0px 9px;
}
.item :nth-of-type(odd)
{
    background: #eeeeee;
    border-radius:10px;
}

.item :before, .item :after
{
    display: table;
    content: "";
}

.item img
{
    float: left;
    height:150px;
}
.item :after
{
    clear: both;
}
.list-group-item-text
{
    margin: 0 0 11px;
}
.list-group-item-heading{
font-weight:bold;

text-align:center;
}
.category-list-heading {
margin-top:1%;

  margin-left:40%;
}


.image-wrapper {
  margin:auto;
  margin-top:1%;
  width:600px;
  max-width: 100%;
  max-height:300px;
  background: #fff;
  padding: 10px;
  box-shadow: 2px 2px 8px #aaa;
  position: relative;
}
.image-wrapper:before,
.image-wrapper:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}
.carousel-item{
max-height:300px;
}


.carousel-item{
margin-left:-5%;
}

.category-heading h3{
font-weight:bold;

}
.category-heading{
background-color:black;
}
@media screen and (max-width: 600px){
.category-list-heading{
margin-left:30%;}
}
a{
text-decoration:none;
}
a h4{
color:#000;
}
</style>



<!--main category -->
<div class="category-list-heading bg-light" style="margin-top:40px; width:210px; border-radius:5px; font-family:sans;"><h3> {{categoryName}}</h3></div>
<div class="container" >
  <!--          Loader-->
  <div class="text-center">
    <circle-progress *ngIf="loader"
                     [percent]="100"
                     [radius]="100"
                     [subtitle]="'Loading...'"
                     [outerStrokeWidth]="16"
                     [innerStrokeWidth]="8"
                     [outerStrokeColor]="'#bc2624'"
                     [innerStrokeColor]="'#C7E596'"
                     [animation]="true"
                     [animationDuration]="10000"
    ></circle-progress>


  </div>
  <div id="products" class="row">
    <div *ngFor="let p of productSubCategory|paginate: { itemsPerPage: 12, currentPage: p }; let i = index"  routerLink="/category/{{categoryName}}/{{p.Subcategory_name}}/{{p.Subcategory_id}}" class="item col-xs-2 col-lg-2 col-sm-4" style="border-radius:5px;" >
      <div class="thumbnail">
        <img class="group list-group-image" [src]="p.Subcategory_image" alt="" />
        <div class="caption">
          <h4 class="list-group-item-heading">
            {{p.Subcategory_name}}</h4>
        </div>
      </div>
    </div>

  </div>
</div>

<!--&lt;!&ndash; Popular products&ndash;&gt;-->
<!--<div class="category-list-heading"><h3><span class="mdi mdi-more" data-toggle="collapse" href="#popular-products" role="button" aria-expanded="false" aria-controls="popular-products"></span> {{'homeContent.top-product'|translate}}</h3></div>-->
<!--<div class="container" id="popular-products" >-->
<!--  <style>-->
<!--ul.row, ul.row-sm {-->
<!--  list-style: none;-->
<!--  padding: 0; }-->
<!--  a {-->
<!--  text-decoration:none;-->
<!--  }-->
<!--  a h6{-->
<!--    font-size:20px;-->

<!--  }-->
<!--</style>-->
<!--  &lt;!&ndash;section 3 for clothing &ndash;&gt;-->
<!--  <div class="card card-home-category mt-1" style="border-style:none;">-->
<!--    <div class="row no-gutters">-->
<!--      <div class="col-md-9 p-1" >-->
<!--        <ul class="row no-gutters bordered-cols" >-->

<!--          <li class="col-6 col-lg-3 col-md-4 m-1" *ngFor="let product of products">-->
<!--            <a href="#" class="item">-->
<!--              <div class="card-body">-->
<!--                <h6 class="title">{{product.productName}}</h6>-->
<!--                <img class="img-sm float-right" src="./assets/images/djlogo.png">-->
<!--                <p class="text-muted"><i class="fa fa-map-marker-alt"></i> Rs. 1,19,499</p>-->

<!--              </div>-->

<!--            </a>-->
<!--            <div class="row m-auto justify-content-center" style=" background-color:#DD6392 ">-->
<!--              <span style="z-index:9;" class="btn mdi mdi-plus mdi-26px" title="Quick View"></span><span class="btn mdi mdi-heart" title="Add to Wishlist"></span><span class="btn mdi mdi-cart" title="Add to Cart"></span>-->
<!--            </div>-->

<!--          </li>-->
<!--        </ul>-->



<!--      </div>-->


<!--      &lt;!&ndash; col.// &ndash;&gt;-->
<!--    </div> &lt;!&ndash; row.// &ndash;&gt;-->
<!--  </div> &lt;!&ndash; card.// &ndash;&gt;-->




<!--</div>-->

<!--&lt;!&ndash;new products&ndash;&gt;-->
<!--<div class="category-list-heading"><h3><span class="mdi mdi-more" data-toggle="collapse" href="#new-products" role="button" aria-expanded="false" aria-controls="new-products"></span> {{'homeContent.new-product'|translate}}</h3></div>-->
<!--<div class="container" id="new-products" >-->
<!--<style>-->
<!--ul.row, ul.row-sm {-->
<!--  list-style: none;-->
<!--  padding: 0; }-->
<!--</style>-->
<!--  &lt;!&ndash;section 3 for clothing &ndash;&gt;-->
<!--    <div class="card card-home-category mt-1" style="border-style:none;">-->
<!--      <div class="row no-gutters">-->
<!--        <div class="col-md-9 p-1" >-->
<!--          <ul class="row no-gutters bordered-cols">-->
<!--            <li class="col-6 col-lg-3 col-md-4">-->
<!--              <a href="#" class="item">-->
<!--                <div class="card-body">-->
<!--                  <h6 class="title">Samsung Galexy S-20</h6>-->
<!--                  <img class="img-sm float-right" src="./assets/images/djlogo.png">-->
<!--                  <p class="text-muted"><i class="fa fa-map-marker-alt"></i> Rs. 1,19,499</p>-->

<!--                </div>-->

<!--              </a>-->
<!--              <div class="row m-auto justify-content-center" style=" background-color:#DD6392 ">-->
<!--              <span style="z-index:9;" class="btn mdi mdi-plus mdi-26px" title="Quick View"></span><span class="btn mdi mdi-heart" title="Add to Wishlist"></span><span class="btn mdi mdi-cart" title="Add to Cart"></span>-->
<!--              </div>-->
<!--            </li>-->
<!--          </ul>-->



<!--        </div>-->


<!--        &lt;!&ndash; col.// &ndash;&gt;-->
<!--      </div> &lt;!&ndash; row.// &ndash;&gt;-->
<!--    </div> &lt;!&ndash; card.// &ndash;&gt;-->




<!--</div>-->
<pagination-controls (pageChange)="p = $event" class="row m-auto justify-content-center"></pagination-controls>

<app-footer></app-footer>
